import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export default function SearchCompany(props) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [textValue, setTextValue] = React.useState(null);

  function isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  const oninput = (event, value, reason) => {
    if (reason === "clear") {
      setOptions([])
    }

    if ((isNumeric(value) && value.length > 1) || (!isNumeric(value) && value.length > 0)) {
      const first2 = value.slice(0, 5);
      fetch("/ocb/v1/stock2/cb/searchcom?key=" + first2)
        .then(res => res.json())
        .then(
          result => {
            setOptions(result);
          });
    }
  }

  const onclose = (event, reason) => {
    setOpen(false);
  }

  const onchange = (event, value, reason) => {
    setTextValue(value)

    if (value != null) {
      if (props.setSID != null) props.setSID(value.value)
      if (props.searchChange != null) props.searchChange(value.value)
    }
  }


  return (
    <Autocomplete
      id="stock input"
      open={open}
      onInputChange={oninput}
      onOpen={() => {
        setOpen(true);
      }}
      freeSolo
      autoHighlight
      onChange={onchange}
      onClose={onclose}
      getOptionLabel={option => option.label}
      value={textValue}
      options={options}
      noOptionsText=""
      renderInput={params => (
        <TextField
          {...params}
          fullWidth
          variant={props.tp != null ? "standard" : "outlined"}
          label="可轉債代號或名稱"
          InputProps={{
            ...params.InputProps,
          }}

        />
      )}
    />
  );
}

