import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LineChart, CandlestickChart, ScatterChart } from 'echarts/charts';
import {
    GridComponent,
    TooltipComponent,
    TitleComponent,
    TimelineComponent,
    MarkPointComponent,
    MarkLineComponent,
    MarkAreaComponent,
    LegendComponent,
    DataZoomComponent,
    DataZoomInsideComponent,
    DataZoomSliderComponent,
} from 'echarts/components';
import {
    CanvasRenderer,
} from 'echarts/renderers';

import Button from '@mui/material/Button';
import { getMobileTooltip, getComputerTooltip, getAxisPointer, getMobileDataZoom, getComputerDataZoom } from '../util/GridA'
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

echarts.use(
    [ScatterChart, TitleComponent, TooltipComponent, GridComponent, LineChart, CandlestickChart, CanvasRenderer, DataZoomComponent,
        DataZoomInsideComponent, DataZoomSliderComponent, MarkPointComponent, MarkLineComponent, TimelineComponent,
        LegendComponent, MarkAreaComponent]
);



export default function AllPrice(props) {
    const theme = useTheme();

    const [option, setOption] = React.useState(null);
    const [result, setResult] = React.useState(null);
    const [cPrice, setCPrice] = React.useState(null);

    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const getStart = () => {
        if (result != null) {
            //    console.log("result.length:" + result.length)
            let k = result.length / 4
            if (k >= 100) return 75
            else return k
        } else return 0
    }

    function genoption1() {
        let cprice = 0.0
        let cdate = ""

        if (props.row != null && props.row.cb2 != null) {
            cprice = props.row.cb2.cprice
            cdate = props.row.cb2.cdate.replaceAll("/", "")
        }


        let k = {
            animation: false,
            grid: [{
                top: 30,
                left: 0,
                right: 11,
                height: 250,
                containLabel: true
            }, {
                top: 310,
                left: 0,
                right: 11,
                height: 150,
                containLabel: true
            }, {
                top: 530,
                left: 0,
                right: 11,
                height: 250,
                containLabel: true
            }, {
                top: 830,
                left: 0,
                right: 11,
                height: 150,
                containLabel: true
            }],
            title: [
                {
                    left: 'left',
                    text: '股票'
                }, {
                    top: '280',
                    left: 'left',
                    text: ''
                }, {
                    top: '500',
                    left: 'left',
                    text: '可轉債'
                }, {
                    top: '800',
                    left: 'left',
                    text: ''
                }
            ],
            legend: {
                show: false
            },
            yAxis: [
                {
                    scale: true,
                    type: 'value',
                    position: 'left',
                    show: true,
                    splitLine: {
                        show: true,
                        lineStyle: {
                            opacity: 0.2
                        }
                    },
                    axisLabel: {
                        inside: true,
                        margin: 0
                    },
                }, {
                    scale: true,
                    type: 'value',
                    position: 'left',
                    show: true,
                    gridIndex: 1,
                    axisLabel: {
                        inside: true,
                        margin: 0
                    },
                }, {
                    scale: true,
                    type: 'value',
                    position: 'left',
                    show: true,
                    splitLine: {
                        show: true,
                        lineStyle: {
                            opacity: 0.2
                        }
                    },
                    gridIndex: 2,
                    axisLabel: {
                        inside: true,
                        margin: 0
                    },
                }, {
                    scale: true,
                    type: 'value',
                    position: 'left',
                    show: true,
                    gridIndex: 3,
                    axisLabel: {
                        inside: true,
                        margin: 0
                    },
                }],
            series: [
                {
                    name: '股票',
                    type: 'candlestick',
                    data: result.map(v => { return v.y }),
                    selectedMode: 'single',
                    markLine: {
                        symbol: "none",
                        label: {
                            show: true,
                            position: "middle",
                            formatter: '{b} {c}',
                            fontWeight: "bold"
                        },
                        data: [
                            {
                                name: '轉換價',
                                yAxis: cprice,
                                lineStyle: {
                                    width: 1,
                                }
                            }, {
                                name: '贖回參考線',
                                yAxis: cprice * 1.3,
                                lineStyle: {
                                    width: 1,
                                }
                            },
                            {
                                name: '最新轉換生效日',
                                xAxis: cdate,
                                lineStyle: {
                                    width: 5,
                                },
                                label: {
                                    show: true,
                                    position: "middle",
                                    formatter: '{b}',
                                    fontWeight: "bold"
                                },
                            }
                        ]
                    },
                }, {
                    name: '股票成交量',
                    type: 'bar',
                    data: result.map(v => { return v.pv }),
                    selectedMode: 'single',
                    yAxisIndex: 1,
                    xAxisIndex: 1,
                }, {
                    name: '可轉債',
                    type: 'candlestick',
                    data: result.map(v => { return v.y1 }),
                    selectedMode: 'single',
                    yAxisIndex: 2,
                    xAxisIndex: 2,
                }, {
                    name: '可轉債成交量',
                    type: 'bar',
                    data: result.map(v => { return v.cv }),
                    selectedMode: 'single',
                    yAxisIndex: 3,
                    xAxisIndex: 3,
                }
            ]
        };

        let vp = {
            type: 'line',
            label: { show: false },
            triggerTooltip: true,
            handle: {
                show: true,
                size: 22
            }
        }

        let x1 = {
            type: 'category',
            boundaryGap: true,
            data: result.map(v => v.x)
        }
        let x2 = {
            type: 'category',
            boundaryGap: true,
            data: result.map(v => v.x),
            gridIndex: 1,
        }

        let x3 = {
            type: 'category',
            boundaryGap: true,
            data: result.map(v => v.x),
            gridIndex: 2,
        }

        let x4 = {
            type: 'category',
            boundaryGap: true,
            data: result.map(v => v.x),
            gridIndex: 3,
        }

        if (fullScreen) {
            k['tooltip'] = getMobileTooltip()
            k['axisPointer'] = getAxisPointer()
            k['dataZoom'] = getMobileDataZoom(getStart(), 100)

            x2['axisPointer'] = vp
            // x2['axisPointer'] = vp
            x4['axisPointer'] = vp
        } else {
            k['tooltip'] = getComputerTooltip()
            k['axisPointer'] = getAxisPointer()
            k['dataZoom'] = getComputerDataZoom(getStart(), 100, 470)
        }

        k['xAxis'] = [x1, x2, x3, x4]
        return k;
    }

    const fetchData = () => {
        let param = {
            id: props.menu,
            sid: props.row.cb2.cid,
            bid: props.row.cb2.bid
        }

        fetch("/ocb/v1/stock3/cb/detaila", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result != null) {
                        setResult(result.data)
                        setCPrice(result.cp)
                    }
                }
            );
    }

    useEffect(() => {
        if (result != null) setOption(genoption1)
    }, [result]);

    useEffect(() => {
        // console.log(props.row)
        if (props.row != null) {
            fetchData()
        }
    }, [props.row]);

    const getReasonDesc = (tps) => {
        if (tps == 0) return "掛牌"
        else if (tps == 1) return "反稀釋"
        else if (tps == 2) return "重設"
        else if (tps == 3) return "不重設"
        else if (tps == 4) return "特別重設"
        else if (tps == 5) return "不特別重設"
        else return "未知"
    }

    const getReasonColor = (tps) => {
        if (tps == 0) return "error"
        else if (tps == 1) return "inherit"
        else if (tps == 2) return "info"
        else if (tps == 3) return "info"
        else if (tps == 4) return "info"
        else if (tps == 5) return "info"
        else return "inherit"
    }

    return (
        <div style={{ marginTop: 10 }}>
            {cPrice != null && <>
                <Typography sx={{ml: 0.5}} variant="h6" fontWeight={"bold"}>轉換價異動紀錄</Typography>
                <TableContainer component={Paper} sx={{ width:"100%" }} >
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>原因</TableCell>
                                <TableCell>生效日期</TableCell>
                                <TableCell align="right" sx={{whiteSpace:"nowrap"}}>轉換價</TableCell>
                                <TableCell align="right" sx={{whiteSpace:"nowrap"}} >轉換股數</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {cPrice.map((row) => (
                                <TableRow
                                    key={row.ymd}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        <Button color={getReasonColor(row.tp)} disableElevation sx={{ width: 90 }} size="small" variant="contained">{getReasonDesc(row.tp)}</Button>
                                    </TableCell>
                                    <TableCell >{row.ymd}</TableCell>
                                    <TableCell align="right">{row.p}元</TableCell>
                                    <TableCell align="right">{row.s}股</TableCell>

                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer></>}


            {option != null ?
                <ReactEChartsCore
                    theme={theme.palette.mode}
                    echarts={echarts}
                    option={option}
                    notMerge={true}
                    lazyUpdate={false}
                    style={{ height: 1080, marginTop: 20 }}
                />
                : null}
        </div>
    );
}

