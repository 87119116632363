import React, { useEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';


export default function RecBase(props) {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);


    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openSetting, setOpenSetting] = React.useState(false);

    const openMenu = Boolean(anchorEl);

    const getOvrate = () => {
        return (((props.row.cb2.bprice / props.row.cb2.gprice) - 1) * 100).toFixed(2)
    }

    const clickDetail = () => {
        if(props.setOpen != null) props.setOpen(true)
    }

    return (
        <Box sx={{ mt: 0.5 }} onClick={e => clickDetail()}>
            {/* 今日成交{props.row.price.u}張， */}
            <div>
                <Typography variant="caption" sx={{ p: 1, alignSelf: 'center' }}>轉換起始日：{props.row.info != null ?props.row.info.csdate:""}</Typography>
            </div>

            <ButtonGroup variant="text" aria-label="text button group" sx={{ width: '100%', mt: 0, justifyContent: 'center' }}>
                <Button sx={{width: '33%' }}  color="inherit">
                    <Stack sx={{ justifyContent: 'center' }}>
                        <Typography variant="caption" sx={{ alignSelf: 'center' }}>CB收盤價(元)</Typography>
                        <Typography sx={{ alignSelf: 'center', fontSize: 30, fontWeight: 'bold' }}>{props.row.cb2.bprice}</Typography>
                    </Stack>
                </Button>
                <Button sx={{width: '33%' }}  color="inherit">
                    <Stack sx={{ justifyContent: 'center' }}>
                        <Typography variant="caption" sx={{ alignSelf: 'center' }}>股票收盤價(元)</Typography>
                        <Typography sx={{ alignSelf: 'center', fontSize: 30, fontWeight: 'bold' }}  >{props.row.cb2.sprice}</Typography>
                    </Stack>
                </Button>
                <Button sx={{width: '33%' }}  color="inherit">
                    <Stack sx={{ justifyContent: 'center' }}>
                        <Typography variant="caption" sx={{ alignSelf: 'center' }}>轉換價(元)</Typography>
                        <Typography sx={{ alignSelf: 'center', fontSize: 30, fontWeight: 'bold' }}  >{props.row.cb2.cprice}</Typography>
                    </Stack>
                </Button>
            </ButtonGroup>

            <Divider variant="middle" sx={{ bgcolor: (theme) => theme.palette.mode === "light" ? theme.palette.primary.main : theme.palette.secondary.main }}
                style={{
                    border: "none",
                    height: 0.5,
                    // margin: 0,
                }}
            />

            <ButtonGroup variant="text" aria-label="text button group" sx={{ width: '100%', mt: 0, justifyContent: 'center' }}>
                <Button sx={{width: '33%' }} color="inherit">
                    <Stack sx={{ justifyContent: 'center' }}>
                        <Typography variant="caption" sx={{ alignSelf: 'center' }}>轉換價值(元)</Typography>
                        <Typography sx={{ alignSelf: 'center', fontSize: 30, fontWeight: 'bold' }}>{props.row.cb2.gprice}</Typography>
                    </Stack>

                </Button>
                <Button sx={{width: '33%' }}  color="inherit">
                    <Stack sx={{ justifyContent: 'center' }}>
                        <Typography variant="caption" sx={{ alignSelf: 'center' }}>折溢價%</Typography>
                        <Typography sx={{ alignSelf: 'center', fontSize: 30, fontWeight: 'bold' }} color={props.row.cb2.bprice > props.row.cb2.gprice ? "error" : "green"}>{getOvrate()}</Typography>
                    </Stack>
                </Button>
                {props.row.info != null && <Button sx={{width: '33%' }}  color="inherit">
                    <Stack sx={{ justifyContent: 'center' }}>
                        <Typography variant="caption" sx={{ alignSelf: 'center' }}>已轉換率%</Typography>
                        <Typography sx={{ alignSelf: 'center', fontSize: 30, fontWeight: 'bold', }} color={props.row.info.rest  === 0 ? "error" : "green"} >{(((props.row.info.oriisu - props.row.info.rest) * 100) / props.row.info.oriisu).toFixed(1)}</Typography>
                    </Stack></Button>}
            </ButtonGroup>

        </Box>

    );
}
