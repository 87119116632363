import React from 'react'
import { BrowserRouter as Router, Route, HashRouter, Routes } from 'react-router-dom'
import CHome from './chome/CHome'
import CHomeM1 from './mhome/CHome'
import CHomeM2 from './ioshome/CHome'

export default props => (   
      <HashRouter>
        <Routes>
          <Route exact path='/' element={ <CHome/> } />   
          <Route exact path='/gool' element={ <CHome/> } />
          <Route exact path='/ios' element={ <CHome/> } />
          <Route exact path='/gool1' element={ <CHome/> } />
          <Route exact path='/ios1' element={ <CHomeM2/> } />
          <Route exact path='/open' element={ <CHome/> } />           
        </Routes>
      </HashRouter>
  )