import React, { useState, useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AllPrice from './AllPrice';
import BasicDetail from './BasicDetail'
import NetValue from './NetValue'
import ChangeRate from './ChangeRate'
import { putInpQueryParam } from '../util/ID'
import { getId } from '../util/ID'
import HistoryCB from './HistoryCB';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function DetailDialog2(props) {
    const [open, setOpen] = React.useState(false);
    const [result, setResult] = React.useState(false);
    const [value, setValue] = React.useState(0);




    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        if (props.close != null) props.close(false)
    };

    useEffect(() => {
        if (props.open) setOpen(true)
        else setOpen(false)
    }, [props.open]);

    useEffect(() => {
        if (props.open && props.bid != null) {
            fetchData()
        }
    }, [props.bid]);


    const fetchData = () => {
        let param = {
            bid: props.bid,
            sn: getId(),
        }

        if (localStorage.getItem("lic") != null) {
            param['lic'] = localStorage.getItem("lic")
        }

        param = putInpQueryParam(param)

        fetch("/ocb/v1/stock2/cb/detail2", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result != null && result.length > 0) {
                        setResult(result[0])
                    }
                }
            );
    }

    const clickLove = () => {
        let param = {
            sn: getId(),
            sid: result.cb2.bid,
            tp: props.menu,
            froms: props.menu
        }

        if (localStorage.getItem("lic") != null) {
            param['lic'] = localStorage.getItem("lic")
        }

        fetch("/ocb/v1/stock2/cb/clicklove", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result != null && result.status === 0) {
                        handleClose()
                    }
                }
            );
    }

    return (
        <div>
            {open && result && <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar elevation={1} color="default" sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            {result.cb2.sname}  {result.cb2.bid}
                            {/* <Typography variant="caption" sx={{ fontWeight: 'normal' }} >{props.row.cb2.bid}</Typography> */}
                        </Typography>
                        <Button autoFocus color="inherit" onClick={clickLove}>
                            加入最愛
                        </Button>
                    </Toolbar>
                </AppBar>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                >
                    <Tab label="基本資料" />
                    <Tab label="價格與成交量" />
                    <Tab label="轉換價值" />
                    <Tab label="轉換率" />
                    <Tab label="歷史年成交統計 (VIP)" />
                </Tabs>

                {value === 0 && <BasicDetail tp={props.tp} menu={props.menu} row={result} />}
                {value === 1 && <AllPrice tp={props.tp} menu={props.menu} row={result} />}
                {value === 2 && <NetValue tp={props.tp} menu={props.menu} row={result} />}
                {value === 3 && <ChangeRate tp={props.tp} menu={props.menu} row={result} />}
                {value === 4 && <HistoryCB tp={props.tp} menu={props.menu} row={result} />}
            </Dialog>}
        </div>
    );
}
