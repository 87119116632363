import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LineChart, BarChart , CandlestickChart } from 'echarts/charts';
import {
    GridComponent,
    TooltipComponent,
    TitleComponent,
    TimelineComponent,
    MarkPointComponent,
    MarkLineComponent,
    MarkAreaComponent,
    LegendComponent,
    DataZoomComponent,
    DataZoomInsideComponent,
    DataZoomSliderComponent,
    VisualMapComponent,
    VisualMapContinuousComponent,
    VisualMapPiecewiseComponent,
} from 'echarts/components';
import {
    CanvasRenderer,
} from 'echarts/renderers';


echarts.use(
    [VisualMapComponent, VisualMapContinuousComponent, VisualMapPiecewiseComponent, TitleComponent, TooltipComponent, GridComponent, LineChart, CandlestickChart, CanvasRenderer, DataZoomComponent,
        DataZoomInsideComponent, DataZoomSliderComponent, MarkPointComponent, MarkLineComponent, TimelineComponent,
        LegendComponent, MarkAreaComponent, BarChart ]
);


const useStyles = makeStyles(theme => ({
    table: {
        // minWidth: 650,
    },
    root1: {
        flexGrow: 1,
    },
    menuButton: {
        // marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    grid: {
        // width: `calc(100% + ${6 + (theme.spacing(1))}px)`
    }
}));



export default function GoodPriceChart(props) {
    const classes = useStyles();
    const theme = useTheme();

    const [option, setOption] = React.useState(null);
    const [result, setResult] = React.useState(null);

    function getYY3() {
        let data = []
        for (var i = 0; i < result.length; i++) {
            let k = 0
            if (result[i].v3 > 0) k = 1
            else if (result[i].v3 < 0) k = -1
            data.push([i, result[i].v3, k]);
        }
        return data
    }

    function genoption1() {
        let k = {
            animation:false,
            grid: [{
                top: 25,
                left: 0,
                right: 0,
                // height: 100,
                bottom: 5,
                containLabel:true
            }],
            visualMap: {
                show: false,
                dimension: 2,
                seriesIndex: 2,
                pieces: [{
                    value: 1,
                    color: "#d41c1c"
                }, {
                    value: 0,
                    color: "#8f8f8f"
                }, {
                    value: -1,
                    color: '#65b206'
                }]
            },
            legend: {
                data: ['轉換價值', 'CB收盤價'],
                right:0,
                icon: 'roundRect',
                formatter: function (name) {
                    return name
                    // let value = result[result.length - 1].v1
                    // if(name === '債券') {
                    //     value = result[result.length - 1].v2
                    // } else if(name === '折溢') {
                    //     value = result[result.length - 1].v3
                    // }
                    // return name + " : " + value
                }
                // ...
            },
            xAxis: [{
                type: 'category',
                boundaryGap: true,
                data: result.map(v => v.x),
            }],
            yAxis: [{
                scale: true,
                type: 'value',
                position: 'left',
                show: true,
                splitLine: {
                    show: true,
                    lineStyle: {
                        opacity: 0.2
                    }
                }                
            },{
                scale: true,
                type: 'value',
                position: 'right',
                show: true,
                splitLine: {
                    show: true,
                    lineStyle: {
                        opacity: 0.2
                    }
                }                
            }],
            series: [
                {
                    name: '轉換價值',
                    type: 'line',
                    data: result.map(v => v.v1),
                    smooth: true,
                    showSymbol: false,
                    markLine: {
                        symbol: "none",
                        label: {
                            show: false
                        },
                        data: [
                            {
                                name: '100',
                                yAxis: 100,
                                lineStyle: {
                                    width: 2,
                                }
                            }
                        ]
                    },
                },
                {
                    name: 'CB收盤價',
                    type: 'line',
                    data: result.map(v => v.v2),
                    smooth: true,
                    showSymbol: false,
                },
                // {
                //     name: '折溢',
                //     // type: 'bar',
                //     // data: getYY3(),
                //     type: 'line',
                //     data: result.map(v => v.v3),
                //     showSymbol: false,
                //     xAxisIndex: 0,
                //     yAxisIndex: 1,
                // }
            ]
        };
        return k;
    }

    const fetchData = () => {
        let param = {
            bid: props.row.cb2.bid
        }

        fetch("/ocb/v1/stock3/pic1", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result != null) {
                        setResult(result)
                    }
                }
            );
    }

    useEffect(() => {
        if(result != null && result.length > 0) setOption(genoption1)
    }, [result]);

    useEffect(() => {
        // setOption(genoption1)
        if(props.row != null) fetchData()
    }, [props.row]);

    const clickDetail = () => {
        if(props.setOpen != null) props.setOpen(true)
    }

    return (
        <div style={{padding:1}}  onClick={e => clickDetail()}>
            {option != null ?

                <ReactEChartsCore
                    theme={theme.palette.mode}
                    echarts={echarts}
                    option={option}
                    notMerge={true}
                    lazyUpdate={false}
                    style={{ height: 120 }}
                // onEvents={onEvents}
                />
                : null}
        </div>
    );
}

