import React, { useEffect, useRef } from 'react';
import { Height } from '../util/ScreenHeight'
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Adsense } from '@ctrl/react-adsense';

export default function CLanding(props) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const getNoAd = () => {
        return localStorage.getItem('noad') != null ? localStorage.getItem('noad') : "0"
    }

    return (
        <Box style={{ flexDirection: 'column', position: 'absolute', width: '100%', top: Height.header, bottom: fullScreen ? 55 : 0, overflowY: 'scroll' }} sx={{}}>
            
            {getNoAd() === "0" && <Adsense
                client="ca-pub-8613274553211553"
                slot="4424041064"
                style={{ display: 'block' }}
                layout="in-article"
                format="fluid"
            />}

            <section className="cid-t52wtqvNyi" id="header2-0">
                <div className="mbr-overlay" style={{ opacity: 0.7, backgroundColor: "rgb(0, 0, 0)" }}></div>

                <div className="container align-center">
                    <div className="row justify-content-md-center">
                        <div className="mbr-white col-md-10 col-lg-7">

                            <h1 className="mbr-section-title mbr-semibold mbr-fonts-style align-center display-1">可轉債挖土機</h1>
                            <p className="mbr-text mbr-fonts-style display-7">輕鬆地幫助您找到最安全可靠的可轉債投資機會.</p>
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <div className="mbr-section-btn align-center"><a className="btn btn-danger display-7" onClick={e => props.setMenu(2)}>立即使用</a></div>
                                <div className="mbr-section-btn align-center"><a className="btn btn-danger display-7" onClick={e => props.setMenu(7)}>成為VIP</a></div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>

            <section className="features1 cid-t52wMqftM0" id="features4-2">




                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-lg-12 title-block">
                            <h2 className="mbr-section-title align-center mbr-bold mbr-fonts-style mbr-black display-2">軟體特色.</h2>


                        </div>
                    </div>


                    <div className="media-container-row">
                        <div className="card col-12 col-md-6 col-lg-4">
                            <div className="card-inner">
                                <div className="card-img align-center">
                                    <span className="mbr-iconfont mbr-iconfont-1 mobi-mbri-contact-form mobi-mbri"></span>
                                </div>
                                <div className="card-box">
                                    <h4 className="card-title align-center mbr-bold mbr-fonts-style mbr-black display-5">策略簡易</h4>
                                    <p className="mbr-text align-center mbr-fonts-style mbr-light mbr-black display-7">10種策略來找到適合投資的可轉債</p>

                                    <div className="card-icon align-center">
                                        <span className="mbr-iconfont mbr-iconfont-2 icon-arrow mobi-mbri-right mobi-mbri"></span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card col-12 col-md-6 col-lg-4">
                            <div className="card-inner">
                                <div className="card-img align-center">
                                    <span className="mbr-iconfont mbr-iconfont-1 mobi-mbri-rocket mobi-mbri"></span>
                                </div>
                                <div className="card-box">
                                    <h4 className="card-title mbr-black align-center mbr-bold mbr-fonts-style display-5">資訊清晰<br /></h4>
                                    <p className="mbr-text mbr-black align-center mbr-fonts-style mbr-light display-7">重要資訊利用圖表或表格呈現，一目了然</p>

                                    <div className="card-icon align-center">
                                        <span className="mbr-iconfont mbr-iconfont-2 icon-arrow mobi-mbri-right mobi-mbri"></span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card card-3 col-12 col-md-6 col-lg-4">
                            <div className="card-inner">


                                <div className="card-img align-center">
                                    <span className="mbr-iconfont mbr-iconfont-1 mobi-mbri-image-gallery mobi-mbri"></span>
                                </div>
                                <div className="card-box">
                                    <h4 className="card-title mbr-black align-center mbr-bold mbr-fonts-style display-5">手機/網頁同步</h4>
                                    <p className="mbr-text mbr-black align-center mbr-fonts-style mbr-light display-7">相同序號手機／網頁／平板皆可使用</p>

                                    <div className="card-icon align-center">
                                        <span className="mbr-iconfont mbr-iconfont-2 icon-arrow mobi-mbri-right mobi-mbri"></span>
                                    </div>
                                </div>
                            </div>
                        </div>



                    </div>

                </div>

            </section>

            <section className="cid-t53b4ZmLH3" data-bg-video="https://vimeo.com/158559984" id="header5-8">



                <div className="mbr-overlay" style={{ opacity: 0.8, backgroundColor: "rgb(0, 0, 0)" }}></div>
                <div className="container align-left">
                    <div className="row">
                        <div className="mbr-white col-md-12 col-lg-12">

                            <h1 className="mbr-section-title mbr-semibold mbr-fonts-style align-left display-1">一次性付費，永久使用</h1>

                            <div className="mbr-section-btn align-left"><a className="btn btn-danger-outline display-7" onClick={e => props.setMenu(7)}>購買去</a></div>
                        </div>
                    </div>
                </div>

            </section>

            <section className="info2 cid-t534WhLbkO" id="info2-6">
                <div className="align-center container-fluid">
                    <div className="row justify-content-center">
                        <div className="card col-12 col-lg-6">
                            <div className="wrapper">
                                <h3 className="mbr-section-title mb-4 mbr-fonts-style display-5">
                                    <strong>Android 版本</strong></h3>

                                <div className="mbr-section-btn"><a className="btn btn-white display-4" href="https://reurl.cc/Lm22m9">立即下載</a></div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="wrapper">
                                <h3 className="mbr-section-title mbr-fonts-style mb-4 display-5"><strong>iOS 版本</strong></h3>

                                <div className="mbr-section-btn"><a className="btn btn-white display-4" href="https://reurl.cc/kEqG6n">立即下載</a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="content14 cid-t534LqOByW" id="content14-5">





                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-12 col-lg-10">
                            <h3 className="mbr-section-title mbr-fonts-style mb-4 display-5">
                                <strong>常見問題</strong></h3>
                            <ul className="list mbr-fonts-style display-7">
                                <li><strong>購買後如何收到序號？</strong><br />付款完成後，會收到付款完成通知信，商店訂單編號前8碼即是序號。</li>
                                <li><strong>早鳥購買的序號有時間限制？</strong><br />無使用期限。</li>
                                <li><strong>之後策略會增加？</strong><br />會。有任何的寶貴意見也可以寫信給我們。</li>
                                <li><strong>資料都何時更新？
                                </strong><br />晚上9點左右。</li>
                                <li><strong>若有任何問題或者建議要怎麼讓我們知道？<br /></strong>可以寫信至jookershop@gmail.com。</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <section className="features1 cid-t54JKIDqBZ" id="features6-9">




                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-lg-12 title-block">
                            <h2 className="mbr-section-title align-center mbr-bold mbr-fonts-style mbr-white display-2">其他優質軟體</h2>


                        </div>
                    </div>

                    <div className="media-container-row">
                        <div className="card col-12 col-md-6 col-lg-4" >
                            <div className="card-inner">
                                <div className="card-img align-left">
                                    <a href="https://stock-digger.com"><span className="mbr-iconfont mbr-iconfont-1 mobi-mbri-contact-form mobi-mbri"></span></a>
                                </div>
                                <div className="card-box">
                                    <h4 className="card-title align-left mbr-fonts-style mbr-white display-5">股票挖土機</h4>
                                    <p className="mbr-text align-left mbr-fonts-style mbr-light display-4">
                                        將自己的投資理念轉換成幾條簡單的文字規則，讓您從近2000支的股票與ETF中，快速的挖出珍貴的投資標的。 </p>

                                </div>
                            </div>
                        </div>

                        <div className="card col-12 col-md-6 col-lg-4">
                            <div className="card-inner">
                                <div className="card-img align-left">
                                    <a href="https://stock-candle.com"><span className="mbr-iconfont mbr-iconfont-1 mobi-mbri-rocket mobi-mbri"></span></a>
                                </div>
                                <div className="card-box">
                                    <h4 className="card-title mbr-white align-left mbr-fonts-style display-5">
                                        股票光明燈</h4>
                                    <p className="mbr-text align-left mbr-fonts-style mbr-light display-4">
                                        股票光明燈找出策略中高勝率／高期望值／高報酬率的股票，提供簡單的進出場燈號，讓投資發揮最大的效益。</p>

                                </div>
                            </div>
                        </div>

                        <div className="card card-3 col-12 col-md-6 col-lg-4">
                            <div className="card-inner">
                                <div className="card-img align-left">
                                    <a href="https://stock-move.com/"><span className="mbr-iconfont mbr-iconfont-1 mobi-mbri-image-gallery mobi-mbri"></span></a>
                                </div>
                                <div className="card-box">
                                    <h4 className="card-title mbr-white align-left mbr-fonts-style display-5">
                                        動能投資法</h4>
                                    <p className="mbr-text align-left mbr-fonts-style mbr-light display-4">
                                        將90日(可調整)股價利用線性回歸畫出直線，此直線的斜率就是動能， 直線傾斜角度越大動能亦越大。</p>
                                </div>
                            </div>
                        </div>



                    </div>
                </div>
            </section>



        </Box>
    );
}
