import Month12Year7 from "../menu/Month12Year7"
import Number0_15 from '../menu/Number0_15'
import RevM from "../menu/RevM"
import Percent5_5_100 from '../menu/Percent5_5_100'
import Month12 from "../menu/Month12"
import EmptyMenu from '../menu/EmptyMenu'
import ZDay from "../menu/ZDay"
import Juridical from "../menu/Juridical"
import BuySell from "../menu/BuySell"
import Day_5 from '../menu/Day_5'
import Piece from '../menu/Piece'
import DWM from '../menu/DWM'
import BigSmall from '../menu/BigSmall'
import CrossW from '../menu/CrossW'
import SeasonAll1 from '../menu/SeasonAll1'
import Year51 from '../menu/Year51'
import Percent5_5_300 from '../menu/Percent5_5_300'
import Season3Year7 from '../menu/Season3Year7'
import Dollar0_50 from '../menu/Dollar0_50'
import Season3 from '../menu/Season3'
import WL from '../menu/WL'
import Year7 from '../menu/Year7'
import SeasonAll2 from '../menu/SeasonAll2'
import Avg from '../menu/Avg'
import Time4_30 from "../menu/Time4_30"
import Season1 from "../menu/Season1"
import Season12 from '../menu/Season12'
import SeasonAll from '../menu/SeasonAll'
import Tp from "../menu/Tp"
import Percent0_20 from '../menu/Percent0_20'
import Number100 from '../menu/Number100'
import Number1000 from '../menu/Number1000'
import Point from '../menu/Point'
import CostM from '../menu/CostM'
import Num51 from '../menu/Num51'
import AddSub from '../menu/AddSub'
import GreenRed from '../menu/GreenRed'
import Day_51 from '../menu/Day_51'
import Season3Year4 from '../menu/Season3Year4'
import Day_10 from '../menu/Day_10'
import Number0_100 from '../menu/Number0_100'
import UpDown from '../menu/UpDown'
import Number0100 from '../menu/Number0100'
import Day1020 from '../menu/Day1020'
import BaLee from '../menu/BaLee'
import KDW from '../menu/KDW'
import KDD from '../menu/KDD'
import KDM from '../menu/KDM'
import KDMenu from '../menu/KDMenu'
import KDDMenu from '../menu/KDDMenu'
import KDJ from '../menu/KDJ'
import Day_5w from '../menu/Day_5w'
import MoneyMB from '../menu/MoneyMB'
import Percent10_100_0 from '../menu/Percent10_100_0'
import Stock0_2000 from '../menu/Stock0_2000'
import T20 from '../menu/T20'
import IntCashStock from '../menu/IntCashStock'
import BYear from '../menu/BYear'
import MonthAll from '../menu/MonthAll'
import Rate from '../menu/Rate'
import ROEA from '../menu/ROEA'
import Ba1 from '../menu/Ba1'
import BS1 from '../menu/BS1'
import PL from '../menu/PL'
import Cash from '../menu/Cash'
import Percent100_0_1000 from '../menu/Percent100_0_1000'
import Year10 from '../menu/Year10'
import Dollar0_1000 from '../menu/Dollar0_1000'
import Day_10_240 from '../menu/Day_10_240'
import BetaV from '../menu/BetaV'
import Premi from '../menu/Premi'
import STp from '../menu/STp'
import DIFMACD from '../menu/DIFMACD'
import Number1_9 from '../menu/Number1_9'
import UpDown1 from '../menu/UpDown1'
import Day_0_10 from '../menu/Day_0_10'
import Day_20 from '../menu/Day_20'
import MA2 from '../menu/MA2'
import MAWeek from '../menu/MAWeek'
import MA3 from '../menu/MA3'
import Num3 from '../menu/Num3'
import MA from '../menu/MA'
import RedBlack from '../menu/RedBlack'
import KLine from '../menu/KLine'
import Percent0_20_1 from '../menu/Percent0_20_1'
import BigSmall1 from '../menu/BigSmall1'
import KDays from '../menu/KDays'
import KType from '../menu/KType'
import Dollar100001 from '../menu/Dollar100001'
import Piece0_50000 from '../menu/Piece0_50000'
import Week10 from '../menu/Week10'
import Ba from '../menu/Ba'
import ShortLong from '../menu/ShortLong'
import NumberN10 from '../menu/NumberN10'
import DayYear from '../menu/DayYear'
import NumberN10N from '../menu/NumberN10N'
import Day_10_2401 from '../menu/Day_10_2401'
import Num50_500 from '../menu/Num50_500'
import Year5 from '../menu/Year5'
import LongDay from '../menu/LongDay'
import OHL from '../menu/OHL'
import HL from '../menu/HL'
import Number10 from '../menu/Number10'
import Month3 from '../menu/Month3'
import Avg1 from '../menu/Avg1'
import Avg2 from '../menu/Avg2'
import BBLine from '../menu/BBLine'
import NumberSmall from '../menu/NumberSmall'
import MA1 from '../menu/MA1'
import PercentP0_100 from '../menu/PercentP0_100'
import PercentN5_5_100 from '../menu/PercentN5_5_100'
import Happy from '../menu/Happy'
import Happy1 from '../menu/Happy1'
import Number10Time from '../menu/Number10Time'
import BigToSmall from '../menu/BigToSmall'
import PN from '../menu/PN'
import DMI1 from '../menu/DMI1'
import MRate from '../menu/MRate'
import Margin from '../menu/Margin'
import PercentDot from '../menu/PercentDot'
import Broker from '../menu/Broker'
import Dollar10000 from '../menu/Dollar10000'
import BigCus from '../menu/BigCus'
import Piece1 from '../menu/Piece1'
import NHouse from '../menu/NHouse'
import PToN from '../menu/PToN'
import PHouse from '../menu/PHouse'
import Percent5_5_301 from '../menu/Percent5_5_301'
import MoneyMil from '../menu/MoneyMil'
import Number1110 from '../menu/Number1110'
import EBSMenu from '../menu/EBSMenu'
import PriceLevel from '../menu/PriceLevel'
import Number0500 from '../menu/Number0500'
import Number01001 from '../menu/Number01001'
import Percent0100 from '../menu/Percent0100'
import SPDay from '../menu/SPDay'
import SP from '../menu/SP'
import GreenRed1 from '../menu/GreenRed1'
import Num5 from '../menu/Num5'
import BSSingle from '../menu/BSSingle'
import NumberN1000 from '../menu/NumberN1000'
import RiskDay from '../menu/RiskDay'
import RiskLevel from '../menu/RiskLevel'
import RiskMoney from '../menu/RiskMoney'
import MLN from '../menu/MLN'
import UpDown2 from '../menu/UpDown2'
import HighLow1 from '../menu/HighLow1'
import Action1 from '../menu/Action1'
import GrothBack from '../menu/GrothBack'
import Month24 from "../menu/Month24"
import KWeeks from '../menu/KWeeks'
import KMonth from '../menu/KMonth'
import YesNo from '../menu/YesNo'
import Percent30 from '../menu/Percent30'
import Day_5m from '../menu/Day_5m'

//////////////////////////////////////////////////////////////////////
///////////////////////////////////型態//////////////////////////////
//////////////////////////////////////////////////////////////////////

export const SHAPE_1 = [
    { desc: ['W底'], id: '451', img: "w.png" },
    { desc: ['頭肩底'], id: '452', img: "head_bottom.png" },
    { desc: ['三重底'], id: '453', img: "w3.png" },
    { desc: ['圓弧底'], id: '454', img: "w4.png" },
    { desc: ['下降楔型'], id: '455', img: "w5.png" },
    { desc: ['V型底'], id: '456', img: "w6.png" },
    { desc: ['一字形底'], id: '457', img: "w7.png" },
    { desc: ['下降旗'], id: '465', img: "w15.png" },
    { desc: ['前漲三角形'], id: '467', img: "w17.png" },
    { desc: ['頭頭高底底高'], id: '478', img: "w20.png" },
    { desc: ['頭肩頂'], id: '458', img: "w8.png" },
    { desc: ['M頭'], id: '459', img: "w9.png" },
    { desc: ['三重頂'], id: '460', img: "w10.png" },
    { desc: ['圓弧頂'], id: '461', img: "w11.png" },
    { desc: ['上升楔型'], id: '462', img: "w12.png" },
    { desc: ['倒V頂'], id: '463', img: "w13.png" },
    { desc: ['上升旗'], id: '464', img: "w14.png" },
    { desc: ['前跌喇叭形'], id: '468', img: "w18.png" },
    { desc: ['前跌三角形'], id: '466', img: "w16.png" },
    { desc: ['頭頭低底底低'], id: '479', img: "w21.png" },
]

export const SHAPE_2 = [
    { desc: ['量增價揚'], id: '470', img: "p0.png" },
    { desc: ['量增價跌'], id: '471', img: "p1.png" },
    { desc: ['量增價平'], id: '472', img: "p2.png" },
    { desc: ['量縮價漲'], id: '473', img: "p3.png" },
    { desc: ['量縮價跌'], id: '474', img: "p4.png" },
    { desc: ['量縮價平'], id: '475', img: "p5.png" },
    { desc: ['低檔爆量'], id: '476', img: "p6.png" },
    { desc: ['高檔爆量'], id: '477', img: "p7.png" },
]


export const SHAPE_1_IDS = SHAPE_1.map(i => { return i.id })
export const SHAPE_2_IDS = SHAPE_2.map(i => { return i.id })

//////////////////////////////////////////////////////////////////////
///////////////////////////////////輕鬆選//////////////////////////////
//////////////////////////////////////////////////////////////////////
export const EASY_1 = [
    { desc: ['高周轉率'], id: '246', sh: '近3日週轉率大30%' },
    { desc: ['放大量'], id: '245', sh: '近3日成交量多5倍' },
    { desc: ['漲幅驚人'], id: '247', sh: '近3日漲幅排行榜' },        
    { desc: ['實施庫藏股'], id: '244', sh: '正在實施庫藏股' },    
    { desc: ['突破壓力'], id: '243', sh: '近2日股價突破SAR' },
    { desc: ['營收股價背離'], id: '242', sh: '營收成長, 股價衰退' },
    { desc: ['四海遊龍'], id: '241', sh: '股價突破5,10,20,60日均' },
    { desc: ['EPS創新高'], id: '240', sh: '季/年EPS創5年新高' },
    { desc: ['漲停鎖不住'], id: '239', sh: '漲停打開' },
    { desc: ['最近跑贏大盤'], id: '238', sh: '2週內RS由負轉正' },
    { desc: ['財報搶先看'], id: '237', sh: '已發佈最新一季財報' },
    { desc: ['資金湧入的概念股'], id: '236', sh: '當日資金多漲幅為正的概念股' },
    { desc: ['0050成分股'], id: '235', sh: '占基金淨資產價值1%以上' },
    { desc: ['兼具成長又便宜'], id: '234', sh: 'PEG 0.6' },
    { desc: ['跟投信買在起始點'], id: '233', sh: '近3日發生近3個月首買' },
    { desc: ['多頭強勢股'], id: '232', sh: '大+DI與ADX' },
    { desc: ['散戶丟大戶接'], id: '230', sh: '近2日散戶賣超大戶買超' },
    { desc: ['會發錢的ETF'], id: '228', sh: '近1年有配息的ETF' },
    { desc: ['就是愛當沖'], id: '227', sh: '震幅大, 漲幅大, 投信大買' },
    { desc: ['今年比去年好'], id: '206', sh: '今年EPS已超過去年' },
    { desc: ['營收搶先看'], id: '225', sh: '每月1到10日先公布營收' },
    { desc: ['波動小報酬高'], id: '224', sh: '20日報酬高波動率小' },
    { desc: ['近一季三率三升'], id: '223', sh: '三率YoY達30%以上' },
    { desc: ['近期要除權息'], id: '222', sh: '二週內有除權息發生' },
    { desc: ['漲一波依然強勢'], id: '221', sh: 'KD鈍化，布林貼上軌，乖離大' },
    { desc: ['近一季從虧轉盈'], id: '220', sh: '最新一季QoQ從負變正' },
    { desc: ['短線跌深搶反彈'], id: '219', sh: '20日報酬高波動率小' },
    { desc: ['COVID-19後漲最兇'], id: '217', sh: '2020.1.21日之後漲幅最大' },
    { desc: ['COVID-19後跌最兇'], id: '218', sh: '2020.1.21日之後跌幅最大' },
    { desc: ['債轉股有擔保'], id: '216', sh: '發行可轉換公司債並有擔保' },
    { desc: ['剛公布發放股利'], id: '215', sh: '近期董事會宣布股利發放' },
    { desc: ['盤整後起漲'], id: '214', sh: '布林通道狹窄後開口打開' },
    { desc: ['董監改選行情'], id: '212', sh: '董監持股低 質押高 大戶增' },
    { desc: ['成交爆大量'], id: '213', sh: '近期成交量10倍增' },
    { desc: ['快速填息填權'], id: '211', sh: '最近一次填權息速度快' },
    { desc: ['最近常戰勝大盤'], id: '210', sh: '漲得比大盤多/跌得比大盤少' },
    { desc: ['專注本業不貪心'], id: '209', sh: '本業收入佔稅前淨利高' },
    { desc: ['借錢也要買來放'], id: '208', sh: '融資餘額增加' },
    { desc: ['內部大戶用力買'], id: '207', sh: '近兩週扣除法人的大戶' },
    { desc: ['我立志要存股'], id: '205', sh: '殖利率/股息發放' },
    { desc: ['大戶讓我安心'], id: '204', sh: '大戶持股比例' },
    { desc: ['漲幅有點猛'], id: '203', sh: '股價上漲幅度' },
    { desc: ['新聞一直報'], id: '41', sh: '新話題' },
    { desc: ['大家偷偷在關注'], id: '47', sh: '關注清單排名前面' },
    { desc: ['最近股價創新高'], id: '202', sh: '股價創新高' },
    { desc: ['股價跌破淨值了'], id: '201', sh: '股價淨值比' },
    { desc: ['股價最近盤整中'], id: '48', sh: '糾結線' },
    { desc: ['最近被打入冷宮'], id: '40', sh: '股價估算' },
    { desc: ['獲利好又便宜'], id: '33', sh: '本益比低, 營收高' },
    { desc: ['外資最近常常買'], id: '35', sh: '外資持續買入' },
    { desc: ['老闆愛自己公司'], id: '42', sh: '董事持股比例高' },
    { desc: ['營收一直強強滾'], id: '38', sh: '營收持續增長' },
    { desc: ['長期現金分紅'], id: '34', sh: '持續配股票股利' },
    { desc: ['很會用資產賺錢'], id: '36', sh: '資產報酬率高' },
    { desc: ['賣一個賺兩個'], id: '37', sh: '產品毛利率高' },
    { desc: ['巴菲特都這樣做'], id: '43', sh: '高ROE, 低負債' },
    { desc: ['公司負債很少'], id: '39', sh: '負債比率低' },
    { desc: ['先跟客戶收錢'], id: '226', sh: '合約負債近三年都大於25%' },
]

export const EASY_1_IDS = EASY_1.map(i => { return i.id })

//////////////////////////////////////////////////////////////////////
///////////////////////////////////券商//////////////////////////////
//////////////////////////////////////////////////////////////////////
export const BROKER_1 = [
    {
        desc: ['近', '內', 'Top100', '超張數的股票'],
        menu: [Day_10, Broker, BuySell], id: '705',
        value: ['v1', 's1', 'v2']
    }, {
        desc: ['近', '內', 'Top100', '超金額的股票'],
        menu: [Day_10, Broker, BuySell], id: '712',
        value: ['v1', 's1', 'v2']
    }, {
        desc: ['近', '內', '交易金額', '萬以上所操作股票'],
        menu: [Day_10, Broker, MoneyMil], id: '711',
        value: ['v1', 's1', 'v2']
    },
]
export const BROKER_1_IDS = BROKER_1.map(i => { return i.id })


//////////////////////////////////////////////////////////////////////
///////////////////////////////////籌碼//////////////////////////////
//////////////////////////////////////////////////////////////////////

export const MONEY_1 = [
    {
        desc: ['今日融資維持率', '', ''],
        menu: [BigSmall, MRate], id: '906'
    }, {
        desc: ['', '連續', '超', '且每天', '以上'],
        menu: [Margin, BuySell, Day_5, Piece], id: '763'
    }, {
        desc: ['', '連續', '超', '且每天', '以上'],
        menu: [Margin, BuySell, Day_5, Piece], id: '764'
    }, {
        desc: ['融資使用率小於', ''],
        menu: [Percent5_5_100], id: '748'
    }, {
        desc: ['融券使用率小於', ''],
        menu: [Percent5_5_100], id: '749'
    }, {
        desc: ['近', '', '餘額', '', ''],
        menu: [Day_10, Margin, AddSub, Percent5_5_100], id: '750'
    }, {
        desc: ['可當沖交易標的'],
        menu: [], id: '725'
    }, {
        desc: ['可現股賣出後現款買進的當沖標的'],
        menu: [], id: '741'
    }, {
        desc: ['近', '借券賣出餘額', '', ''],
        menu: [Day_5, AddSub, Percent5_5_100], id: '673'
    }, {
        desc: ['近', '融資餘額與融資限額比增加', ''],
        menu: [Day_0_10, Percent0_20], id: '81'
    }, {
        desc: ['近', '融資餘額與融資限額比減少', ''],
        menu: [Day_0_10, Percent0_20], id: '82'
    }, {
        desc: ['近', '融券餘額與融券限額比增加', '以上'],
        menu: [Day_0_10, Percent0_20], id: '83'
    }, {
        desc: ['近', '融券餘額與融券限額比減少', '以上'],
        menu: [Day_0_10, Percent0_20], id: '84'
    }, {
        desc: ['融資使用率大於', ''],
        menu: [Percent5_5_100], id: '85'
    }, {
        desc: ['融券使用率大於', ''],
        menu: [Percent5_5_100], id: '86'
    }
]

export const MONEY_2 = [
    {
        desc: ['今日股價', '240日', '成本價'],
        menu: [BigSmall, CostM], id: '922', tp: 3, prv: true
    }, {
        desc: ['', '連續', '超', '且總量', '以上'],
        menu: [Juridical, BuySell, Day_5, Piece], id: '911', tp: 3
    }, {
        desc: ['', '連續', '超', '且總量', '以上'],
        menu: [Juridical, BuySell, Day_5, Piece], id: '912', tp: 3
    }, {
        desc: ['', '連續', '超', '且每天', '以上'],
        menu: [Juridical, BuySell, Day_5, Piece], id: '761', tp: 3
    }, {
        desc: ['', '連續', '超', '且每天', '以上'],
        menu: [Juridical, BuySell, Day_5, Piece], id: '762', tp: 3
    }, {
        desc: ['', '過去', '都沒有任何交易，近', '才開始出現交易'],
        menu: [Juridical, Day_5, Day_5], id: '656'
    }, {
        desc: ['', '近', '買賣超大於過去', '的', '超', '以上'],
        menu: [Juridical, Day_5, Day_5, BuySell, Piece0_50000], id: '647'
    }, {
        desc: ['近', '的', '', '超排名前', '名'],
        menu: [Day_10, Juridical, BuySell, Number10], id: '598'
    }, {
        desc: ['近', '的', '', '超金額排名前', '名'],
        menu: [Day_10, Juridical, BuySell, Number10], id: '702'
    }, {
        desc: ['', '近', '買超佔股本', '以上'],
        menu: [Juridical, Day_5, PercentDot], id: '584'
    }, {
        desc: ['', '近', '賣超佔股本', '以上'],
        menu: [Juridical, Day_5, PercentDot], id: '646'
    }, {
        desc: ['最近', '內', '成交量佔總成交量', '以上'],
        menu: [Day_5, Juridical, Percent5_5_100], id: '556'
    }, {
        desc: ['最近', '內', '持股比例增加', '以上'],
        menu: [Day_5, Juridical, Percent0_20], id: '555'
    }, {
        desc: ['', '今日', '超過', '股票'],
        menu: [Juridical, BuySell, Piece0_50000], id: '20'
    }, {
        desc: ['', '今日', '超過', '股票'],
        menu: [Juridical, BuySell, Piece0_50000], id: '91'
    }, {
        desc: ['', '在', '內', '超過', '股票'],
        menu: [Juridical, Day_5, BuySell, Piece0_50000], id: '21'
    }, {
        desc: ['', '在', '內', '超過', '股票'],
        menu: [Juridical, Day_5, BuySell, Piece0_50000], id: '92'
    }, {
        desc: ['', '在最近', '內', '超天數大於', '的股票'],
        menu: [Juridical, Day_5, BuySell, Day_5], id: '22'
    }, {
        desc: ['', '在最近', '內', '超天數大於', '的股票'],
        menu: [Juridical, Day_5, BuySell, Day_5], id: '93'
    }, {
        desc: ['近', '', '超外本比Top', ''],
        menu: [Day_5, BuySell, Num50_500], id: '946', new: 0
    }, {
        desc: ['近', '', '超投本比Top', ''],
        menu: [Day_5, BuySell, Num50_500], id: '947', new: 0
    }
]



export const MONEY_3 = [
    {
        desc: ['主力在', '日內', '超大於', ''],
        menu: [Day_5, BuySell, Day_5], id: '678'
    }, {
        desc: ['近', '內', '', '超大於', '以上'],
        menu: [Day_10, Broker, BuySell, Piece0_50000], id: '649',
        value: ['v1', 's1', 'v2', 'v3']
    }, {
        desc: ['近', '內', '', '超大於', '以上'],
        menu: [Day_10, Broker, BuySell, Piece0_50000], id: '953',
        value: ['v1', 's1', 'v2', 'v3']
    }, {
        desc: ['近', '內', '', '超大於', '以上'],
        menu: [Day_10, Broker, BuySell, Piece0_50000], id: '956',
        value: ['v1', 's1', 'v2', 'v3']
    }, {
        desc: ['近', '內', '', '超天數超過', '以上'],
        menu: [Day_10, Broker, BuySell, Day_10], id: '650',
        value: ['v1', 's1', 'v2', 'v3']
    }, {
        desc: ['近', '內', '', '超天數超過', '以上'],
        menu: [Day_10, Broker, BuySell, Day_10], id: '954',
        value: ['v1', 's1', 'v2', 'v3']
    }, {
        desc: ['近', '內', '', '超天數超過', '以上'],
        menu: [Day_10, Broker, BuySell, Day_10], id: '957',
        value: ['v1', 's1', 'v2', 'v3']
    }, {
        desc: ['近', '內', '首次買入過去', '沒持有的'],
        menu: [Day_10, Broker, Day_10], id: '651',
        value: ['v1', 's1', 'v2']
    }, {
        desc: ['近', '內', '首次買入過去', '沒持有的'],
        menu: [Day_10, Broker, Day_10], id: '955',
        value: ['v1', 's1', 'v2']
    }, {
        desc: ['近', '內', '首次買入過去', '沒持有的'],
        menu: [Day_10, Broker, Day_10], id: '958',
        value: ['v1', 's1', 'v2']
    }, {
        desc: ['主力近', '', '大於', '以上'],
        menu: [Day_5, BuySell, Piece0_50000], id: '557'
    }, {
        desc: ['主力近', '買賣超大於過去', '的買賣超', '以上'],
        menu: [Day_5, Day_5, Piece0_50000], id: '558'
    }, {
        desc: ['主力連續', '超', '以上'],
        menu: [BuySell, Day_5], id: '559'
    }, {
        desc: ['中實戶持股近', '', '', '以上'],
        menu: [Day_10, AddSub, Piece0_50000], id: '583'
    },
]




export const MONEY_4 = [
    {
        desc: ['近', '擁有', '股票以上並佔公司總持股大於', ''],
        menu: [Week10, Piece, Percent5_5_100], id: '715'
    }, {
        desc: ['擁有', ',', ',', '以上股東持股比例連續', '增加'],
        menu: [Piece1, Piece1, Piece1, Week10], id: '627'
    }, {
        desc: ['擁有', ',', ',', '以上股東人數連續', '增加'],
        menu: [Piece1, Piece1, Piece1, Week10], id: '628'
    }, {
        desc: ['擁有', ',', ',', '以下股東持股比例連續', '增加'],
        menu: [Piece1, Piece1, Piece1, Week10], id: '631'
    }, {
        desc: ['擁有', ',', ',', '以下股東人數連續', '增加'],
        menu: [Piece1, Piece1, Piece1, Week10], id: '632'
    }, {
        desc: ['擁有', '股票以上並佔公司總持股', '以上的大戶們，最近', '人數增加超過', ''],
        menu: [Piece, Percent5_5_100, Week10, Percent0_20], id: '71'
    }, {
        desc: ['擁有股票市值', '以上並佔公司總持股', '以上的大戶們，最近', '人數增加超過', ''],
        menu: [Dollar10000, Percent5_5_100, Week10, Percent0_20], id: '72'
    }, {
        desc: ['擁有', '股票以下並佔公司總持股', '以下的散戶們，最近', '人數減少超過', ''],
        menu: [Piece, Percent5_5_100, Week10, Percent0_20], id: '73'
    }, {
        desc: ['擁有股票市值', '以下並佔公司總持股', '以下的散戶們，最近', '人數減少超過', ''],
        menu: [Dollar10000, Percent5_5_100, Week10, Percent0_20], id: '74'
    }, {
        desc: ['擁有', '股票以上並佔公司總持股', '以上的大戶們，最近', '，人數每週都增加'],
        menu: [Piece, Percent5_5_100, Week10], id: '75'
    }, {
        desc: ['擁有股票市值', '以上並佔公司總持股', '以上的大戶們，最近', '，人數每週都增加'],
        menu: [Dollar10000, Percent5_5_100, Week10], id: '76'
    }, {
        desc: ['最近', '內部大戶買進超過總股數的', '以上'],
        menu: [Week10, Percent0_20], id: '79'
    }, {
        desc: ['', '持股近', '增加', '以上'],
        menu: [BigCus, Week10, PercentDot], id: '581'
    },
]



export const MONEY_5 = [
    {
        desc: ['擁有', ',', ',', '以上股東持股比例連續', '減少'],
        menu: [Piece1, Piece1, Piece1, Week10], id: '629'
    }, {
        desc: ['擁有', ',', ',', '以上股東人數連續', '減少'],
        menu: [Piece1, Piece1, Piece1, Week10], id: '630'
    }, {
        desc: ['擁有', ',', ',', '以下股東持股比例連續', '減少'],
        menu: [Piece1, Piece1, Piece1, Week10], id: '633'
    }, {
        desc: ['擁有', ',', ',', '以下股東人數連續', '減少'],
        menu: [Piece1, Piece1, Piece1, Week10], id: '634'
    }, {
        desc: ['擁有', '股票以上並佔公司總持股', '以上的大戶們，最近', '人數減少超過', ''],
        menu: [Piece, Percent5_5_100, Week10, Percent0_20], id: '505'
    }, {
        desc: ['擁有股票市值', '以上並佔公司總持股', '以上的大戶們，最近', '人數減少超過', ''],
        menu: [Dollar10000, Percent5_5_100, Week10, Percent0_20], id: '506'
    }, {
        desc: ['擁有', '股票以下並佔公司總持股', '以下的散戶們，最近', '人數增加超過', ''],
        menu: [Piece, Percent5_5_100, Week10, Percent0_20], id: '507'
    }, {
        desc: ['擁有股票市值', '以下並佔公司總持股', '以下的散戶們，最近', '人數增加超過', ''],
        menu: [Dollar10000, Percent5_5_100, Week10, Percent0_20], id: '508'
    }, {
        desc: ['擁有', '股票以上並佔公司總持股', '以上的大戶們，最近', '，人數每週都減少'],
        menu: [Piece, Percent5_5_100, Week10], id: '509'
    }, {
        desc: ['擁有股票市值', '以上並佔公司總持股', '以上的大戶們，最近', '，人數每週都減少'],
        menu: [Dollar10000, Percent5_5_100, Week10], id: '510'
    }, {
        desc: ['最近', '內部大戶賣出超過總股數的', '以上'],
        menu: [Week10, Percent0_20], id: '80'
    }, {
        desc: ['', '持股近', '減少', '以上'],
        menu: [BigCus, Week10, PercentDot], id: '582'
    },
]

export const MONEY_6 = [
    {
        desc: ['', '籌碼集中度連續增加', ''],
        menu: [ZDay, ZDay], id: '910'
    }, {
        desc: ['', '日籌碼集中度 > ', '日籌碼集中度 > ', '日籌碼集中度 > ', '日籌碼集中度 > ', '日籌碼集中度(最大50日)'],
        menu: [EmptyMenu, EmptyMenu, EmptyMenu, EmptyMenu, EmptyMenu], id: '774', prv: 1
    }, {
        desc: ['', '日籌碼集中度 < ', '日籌碼集中度 < ', '日籌碼集中度 < ', '日籌碼集中度 < ', '日籌碼集中度(最大50日)'],
        menu: [EmptyMenu, EmptyMenu, EmptyMenu, EmptyMenu, EmptyMenu], id: '775', prv: 1
    }, {
        desc: ['', '籌碼集中度', '', '籌碼集中度'],
        menu: [ZDay, BigSmall, ZDay], id: '743'
    }, {
        desc: ['券資比', '', ''],
        menu: [BigSmall, Percent5_5_100], id: '638'
    }, {
        desc: ['連續', '家數差', '', ''],
        menu: [Day_10, BigSmall, NHouse], id: '782'
    }, {
        desc: ['近', '買賣家數差由', ''],
        menu: [Day_10, PToN], id: '599'
    }, {
        desc: ['近', '買賣家數差持續', ''],
        menu: [Day_10, AddSub], id: '600'
    }, {
        desc: ['近', '的家數差平均大於', '以上'],
        menu: [Day_10, PHouse], id: '97'
    }, {
        desc: ['近', '的家數差平均小於', ''],
        menu: [Day_10, NHouse], id: '98'
    }, {
        desc: ['近', '的籌碼集中度大於', '以上'],
        menu: [Day_10, Percent5_5_100], id: '99'
    }, {
        desc: ['近', '的籌碼集中度大於', '以上'],
        menu: [Day_10, Percent5_5_100], id: '951'
    }, {
        desc: ['近', '的籌碼集中度小於', '以上'],
        menu: [Day_10, PercentN5_5_100], id: '100'
    }, {
        desc: ['近', '的籌碼集中度小於', '以上'],
        menu: [Day_10, PercentN5_5_100], id: '952'
    }, {
        desc: ['', '的周轉率大於', '以上'],
        menu: [Day_5, Percent5_5_301], id: '561'
    }, {
        desc: ['近', '內的', '籌碼集中度由', ''],
        menu: [Day_10, ZDay, PToN], id: '609'
    }
]

export const MONEY_7 = [
    {
        desc: ['', '近', '的當沖率都', '以上'],
        menu: [Day_5, BigSmall, Percent5_5_100], id: '586'
    }, {
        desc: ['八大官股在', '內', '超過', '股票'],
        menu: [Day_20, BuySell, Piece], id: '526'
    }, {
        desc: ['八大官股在', '內', '超天數大於', ''],
        menu: [Day_20, BuySell, Day_20], id: '527'
    }, {
        desc: ['董監事持股比率大於', '%'],
        menu: [Percent5_5_100], id: '45'
    }, {
        desc: ['目前董監質押比率大於', ''],
        menu: [Percent5_5_100], id: '501'
    }, {
        desc: ['今年度要董監改選'],
        menu: [], id: '503'
    }
]



export const MONEY_1_IDS = MONEY_1.map(i => { return i.id })
export const MONEY_2_IDS = MONEY_2.map(i => { return i.id })
export const MONEY_3_IDS = MONEY_3.map(i => { return i.id })
export const MONEY_4_IDS = MONEY_4.map(i => { return i.id })
export const MONEY_5_IDS = MONEY_5.map(i => { return i.id })
export const MONEY_6_IDS = MONEY_6.map(i => { return i.id })
export const MONEY_7_IDS = MONEY_7.map(i => { return i.id })

//////////////////////////////////////////////////////////////////////
///////////////////////////////////TECH///////////////////////////////
//////////////////////////////////////////////////////////////////////


export const TECH_1 = [
    {
        desc: ['', '', '連續', '', '', ''],
        menu: [DWM, KDJ, Day_10, BigSmall, Number0_100], id: '905', tp: 5,
    },
    {
        desc: ['日', '', '前', '日', ''],
        menu: [KDJ, BigSmall, Day_10, KDJ], id: '729', tp: 4
    }, {
        desc: ['日', '', '', ''],
        menu: [KDJ, UpDown, Number0100], id: '730', tp: 3
    }, {
        desc: ['近', '內股價與KD(', ')呈現', ''],
        menu: [Day1020, DWM, BaLee], id: '679', tp: 3
    }, {
        desc: ['', '', '', '', ''],
        menu: [DWM, KDJ, BigSmall, Number0_100], id: '668', tp: 4
    }, {
        desc: ['', '', '', '', ''],
        menu: [DWM, KDJ, BigSmall, Number0_100], id: '784', tp: 4
    }, {
        desc: ['近', '日J與', '發生', ''],
        menu: [Day_5, KDD, CrossW], id: '669', tp: 3
    }, {
        desc: ['近', '週J與', '發生', ''],
        menu: [Day_5, KDW, CrossW], id: '670', tp: 3
    }, {
        desc: ['近', '月J與', '發生', ''],
        menu: [Day_5, KDM, CrossW], id: '671', tp: 3
    },

]

export const TECH_12 = [
    {
        desc: ['', '', '', ''],
        menu: [KDMenu, BigSmall, Number0_100], id: '547', tp: 4
    }, {
        desc: ['', '', '', ''],
        menu: [KDMenu, BigSmall, Number0_100], id: '548', tp: 4
    },
    {
        desc: ['', '', '', '', '以上'],
        menu: [KDMenu, BigSmall, KDDMenu, Number0_100], id: '549', tp: 4
    },
    {
        desc: ['', '', '', '', '以上'],
        menu: [KDMenu, BigSmall, KDDMenu, Number0_100], id: '783', tp: 4
    }, {
        desc: ['最近', '內KD出現黃金交叉(日)'],
        menu: [Day_10], id: '56', tp: 1
    }, {
        desc: ['最近', '內KD出現黃金交叉(週)'],
        menu: [Day_10], id: '57', tp: 1
    }, {
        desc: ['最近', '內KD出現黃金交叉(月)'],
        menu: [Day_10], id: '553', tp: 1
    }, {
        desc: ['最近', '內KD出現死亡交叉(日)'],
        menu: [Day_10], id: '58', tp: 1
    }, {
        desc: ['最近', '內KD出現死亡交叉(週)'],
        menu: [Day_10], id: '59', tp: 1
    }, {
        desc: ['最近', '內KD出現死亡交叉(月)'],
        menu: [Day_10], id: '554', tp: 1
    }, {
        desc: ['前1個月的月KD出現', ''],
        menu: [CrossW], id: '917', tp: 1
    }
]


export const TECH_2 = [
    {
        desc: ['本周', '', '前', '週的', ''],
        menu: [DIFMACD, BigSmall, Number1_9, DIFMACD], id: '751'
    }, {
        desc: ['今日', '', '前', '的', ''],
        menu: [DIFMACD, BigSmall, Day_5, DIFMACD], id: '752'
    }, {
        desc: ['今日', '', '前', '的', ''],
        menu: [DIFMACD, BigSmall, Day_5, DIFMACD], id: '754'
    }, {
        desc: ['前', '', '', '', '前', ''],
        menu: [Day_5, DIFMACD, BigSmall, Day_5, DIFMACD], id: '753'
    }, {
        desc: ['前', '', '', '', '前', ''],
        menu: [Day_5, DIFMACD, BigSmall, Day_5, DIFMACD], id: '755'
    }, {
        desc: ['近', '內股價與MACD(', ')呈現', ''],
        menu: [Day1020, DWM, BaLee], id: '680'
    }, {
        desc: ['近', '內', '', '', '穿越0軸'],
        menu: [Day_5, DWM, DIFMACD, UpDown1], id: '677'
    }, {
        desc: ['近', '內', '', '', '穿越或碰到0軸'],
        menu: [Day_5, DWM, DIFMACD, UpDown1], id: '9007', new: 0
    }, {
        desc: ['', 'OSC近', '都是', ''],
        menu: [DWM, Day_5, GreenRed], id: '659'
    },
]


export const TECH_13 = [
    {
        desc: ['近', '出現MACD', '（日）'],
        menu: [Day_5, CrossW], id: '564'
    }, {
        desc: ['近', '出現MACD', '（週）'],
        menu: [Day_5, CrossW], id: '565'
    }, {
        desc: ['近', '出現MACD', '（月）'],
        menu: [Day_5, CrossW], id: '566'
    }, {
        desc: ['', 'OSC發生綠柱變紅柱'],
        menu: [DWM], id: '567'
    }, {
        desc: ['', 'OSC發生紅柱變綠柱'],
        menu: [DWM], id: '568'
    }, {
        desc: ['', '', '', '', ''],
        menu: [DWM, DIFMACD, BigSmall, DIFMACD], id: '569'
    }, {
        desc: ['', '', '', '0軸'],
        menu: [DWM, DIFMACD, BigSmall], id: '570'
    }, {
        desc: ['', '', '', '0軸'],
        menu: [DWM, DIFMACD, BigSmall], id: '571'
    }, {
        desc: ['', 'MACD', '連續', '', '以上'],
        menu: [DWM, GreenRed, AddSub, Day_0_10], id: '590'
    }, {
        desc: ['', '前', 'MACD', '連續', '', ''],
        menu: [Day_20, DWM, GreenRed, AddSub, Day_20], id: '701'
    }, {
        desc: ['', '', '大於', '且小於', '(僅能輸入整數)'],
        menu: [DWM, DIFMACD, EmptyMenu, EmptyMenu], id: '666'
    },
]


export const TECH_3 = [
    {
        desc: ['連續', '股價都', '', '均線'],
        menu: [Day_10, BigSmall, Day_10], id: '756'
    }, {
        desc: ['今日股價 < ', '日均線 < ', '日均線 < ', '日均線 < ', '日均線 < ', '日均線'],
        menu: [EmptyMenu, EmptyMenu, EmptyMenu, EmptyMenu, EmptyMenu], id: '744'
    }, {
        desc: ['最近', '內的股價有突破', '日均線'],
        menu: [Day_10, EmptyMenu], id: '60'
    }, {
        desc: ['最近', '內的股價有跌破', '日均線'],
        menu: [Day_10, EmptyMenu], id: '61'
    }, {
        desc: ['近', '內的股價', '', '均線'],
        menu: [Day_10, UpDown, MAWeek], id: '602'
    }, {
        desc: ['今日股價大於', '均線的', ''],
        menu: [MA, Percent5_5_100], id: '62'
    }, {
        desc: ['今日股價小於', '均線的', ''],
        menu: [MA, Percent5_5_100], id: '523'
    }, {
        desc: ['', '日均線在', '日均線之上'],
        menu: [EmptyMenu, EmptyMenu], id: '532'
    }, {
        desc: ['', '日均線在', '日均線之上'],
        menu: [EmptyMenu, EmptyMenu], id: '533'
    }, {
        desc: ['', '日均線在', '日均線之上'],
        menu: [EmptyMenu, EmptyMenu], id: '9017', prv: 1
    }, {
        desc: ['', '周均線在', '周均線之上'],
        menu: [EmptyMenu, EmptyMenu], id: '9020', prv: 1
    }, {
        desc: ['', '月均線在', '月均線之上'],
        menu: [EmptyMenu, EmptyMenu], id: '9021', prv: 1
    }, {
        desc: ['今日股價大於', '日均線的', '%'],
        menu: [EmptyMenu, EmptyMenu], id: '541'
    }, {
        desc: ['今日股價小於', '日均線的', '%'],
        menu: [EmptyMenu, EmptyMenu], id: '542'
    }, {
        desc: ['近', '內', '日均線與', '日均線有發生', ''],
        menu: [Day_10, EmptyMenu, EmptyMenu, CrossW], id: '601'
    }, {
        desc: ['近', '內', '週均線與', '週均線有發生', ''],
        menu: [Day_10, EmptyMenu, EmptyMenu, CrossW], id: '694'
    }, {
        desc: ['近', '內', '月均線與', '月均線有發生', ''],
        menu: [Day_10, EmptyMenu, EmptyMenu, CrossW], id: '695'
    },
]

export const TECH_4 = [
    {
        desc: ['今日的', '日均線比', '前的', '日均線增加', ''],
        menu: [MA3, Day_10, MA3, Percent5_5_100], id: '697'
    }, {
        desc: ['今日的', '日均線比', '前的', '日均線增加', ''],
        menu: [MA3, Day_10, MA3, Percent5_5_100], id: '731'
    }, {
        desc: ['今日的', '日均線比', '前的', '日均線減少', ''],
        menu: [MA3, Day_10, MA3, Percent5_5_100], id: '698'
    }, {
        desc: ['今日的', '日均線比', '前的', '日均線減少', ''],
        menu: [MA3, Day_10, MA3, Percent5_5_100], id: '732'
    }, {
        desc: ['今日的股價大於', '均線', '到', '之間'],
        menu: [MA3, Percent30, Percent30], id: '699'
    }, {
        desc: ['今日的股價大於', '均線', '到', '之間'],
        menu: [MA3, Percent30, Percent30], id: '942'
    }, {
        desc: ['今日的股價大於', '均線', '到', '之間'],
        menu: [MA3, Percent30, Percent30], id: '943'
    }, {
        desc: ['今日的股價小於', '均線', '到', '之間'],
        menu: [MA3, Percent30, Percent30], id: '700'
    }, {
        desc: ['今日的股價小於', '均線', '到', '之間'],
        menu: [MA3, Percent30, Percent30], id: '944'
    }, {
        desc: ['今日的股價小於', '均線', '到', '之間'],
        menu: [MA3, Percent30, Percent30], id: '945'
    }, {
        desc: ['今日股價 > ', '日均線 > ', '日均線 > ', '日均線 >', '日均線 >', '日均線'],
        menu: [EmptyMenu, EmptyMenu, EmptyMenu, EmptyMenu, EmptyMenu], id: '733'
    }, {
        desc: ['', '均線', '扣抵值'],
        menu: [EmptyMenu, BigSmall], id: '735'
    }, {
        desc: ['前', '起算已經連續', '股價', '', '日均線'],
        menu: [Day_5, Day_5, BigSmall, EmptyMenu], id: '941', tp: 1
    }, {
        desc: ['', '', '均線角度', '', '度（整數90 ～ -90）'],
        menu: [EmptyMenu, DWM, BigSmall, EmptyMenu], id: '913', tp: 1,
    }, {
        desc: ['', '', '均線角度', '', '度（整數90 ～ -90）'],
        menu: [EmptyMenu, DWM, BigSmall, EmptyMenu], id: '914', tp: 1,
    }, {
        desc: ['', '', '均線角度介於', '度與', '度（整數90 ～ -90）之間'],
        menu: [EmptyMenu, DWM, EmptyMenu, EmptyMenu], id: '9014', prv: 1
    }, {
        desc: ['', '', '均線角度介於', '度與', '度（整數90 ～ -90）之間'],
        menu: [EmptyMenu, DWM, EmptyMenu, EmptyMenu], id: '9015', prv: 1
    }, {
        desc: ['', '', '均線角度介於', '度與', '度（整數90 ～ -90）之間'],
        menu: [EmptyMenu, DWM, EmptyMenu, EmptyMenu], id: '9016', prv: 1
    }, {
        desc: ['今日股價突破', ''],
        menu: [Avg], id: '574', tp: 1
    }, {
        desc: ['今日股價跌破', ''],
        menu: [Avg], id: '920', tp: 1
    }, {
        desc: ['近', '的', '日均線斜率', '', '%'],
        menu: [Day_5, EmptyMenu, BigSmall], id: '948',
        stext: [0, 0, 0, 1]
    }, {
        desc: ['近', '的', '周均線斜率', '', '%'],
        menu: [Day_5, EmptyMenu, BigSmall], id: '949',
        stext: [0, 0, 0, 1]
    }, {
        desc: ['近', '的', '月均線斜率', '', '%'],
        menu: [Day_5, EmptyMenu, BigSmall], id: '950',
        stext: [0, 0, 0, 1]
    },
]

export const TECH_14 = [
    {
        desc: ['今日價格', '當', '價格*2減去之前第', '根K線收盤價', '以上已達', '次'],
        menu: [BigSmall, DWM, Number0100, Percent0_20, Number0_15], id: '492', prv: 1
    }, {
        desc: ['利用前', '根K線推估', '', '的均線值都', '現值', '以上'],
        menu: [Number0100, Num3, DWM, BigSmall, Percent0_20], id: '495', prv: 1
    }, {
        desc: ['利用前', '根K線推估', '', '的均線值都', '現值', '以上'],
        menu: [Number0100, Num3, DWM, BigSmall, Percent0_20], id: '496', prv: 1
    }, {
        desc: ['利用前', '根K線推估', '', '的均線值都', '現值', '以上'],
        menu: [Number0100, Num3, DWM, BigSmall, Percent0_20], id: '497', prv: 1
    }, {
        desc: ['持續', '的股價 > ', '日均線 > ', '日均線 > ', '日均線 > ', '日均線 > ', '日均線'],
        menu: [Day_5, EmptyMenu, EmptyMenu, EmptyMenu, EmptyMenu, EmptyMenu], id: '908', tp: 1, prv: true
    }, {
        desc: ['持續', '的股價 < ', '日均線 < ', '日均線 < ', '日均線 < ', '日均線 < ', '日均線'],
        menu: [Day_5, EmptyMenu, EmptyMenu, EmptyMenu, EmptyMenu, EmptyMenu], id: '909', tp: 1, prv: true
    }, {
        desc: ['持續', '的股價 > ', '周均線 > ', '周均線 > ', '周均線 > ', '周均線 > ', '周均線'],
        menu: [Day_5w, EmptyMenu, EmptyMenu, EmptyMenu, EmptyMenu, EmptyMenu], id: '939', tp: 1, prv: true
    }, {
        desc: ['持續', '的股價 < ', '周均線 < ', '周均線 < ', '周均線 < ', '周均線 < ', '周均線'],
        menu: [Day_5w, EmptyMenu, EmptyMenu, EmptyMenu, EmptyMenu, EmptyMenu], id: '940', tp: 1, prv: true
    }, {
        desc: ['持續', '的股價 > ', '月均線 > ', '月均線 > ', '月均線 > ', '月均線 > ', '月均線'],
        menu: [Day_5m, EmptyMenu, EmptyMenu, EmptyMenu, EmptyMenu, EmptyMenu], id: '9022', tp: 1, prv: true
    }, {
        desc: ['持續', '的股價 < ', '月均線 < ', '月均線 < ', '月均線 < ', '月均線 < ', '月均線'],
        menu: [Day_5m, EmptyMenu, EmptyMenu, EmptyMenu, EmptyMenu, EmptyMenu], id: '9023', tp: 1, prv: true
    }, {
        desc: ['持續', '', '日均線角度 > ', '日均線角度 > ', '日均線角度 > ', '日均線角度 > ', '日均線角度'],
        menu: [Day_5, EmptyMenu, EmptyMenu, EmptyMenu, EmptyMenu, EmptyMenu], id: '915', tp: 1, prv: true
    }, {
        desc: ['持續', '', '日均線角度 < ', '日均線角度 < ', '日均線角度 < ', '日均線角度 < ', '日均線角度'],
        menu: [Day_5, EmptyMenu, EmptyMenu, EmptyMenu, EmptyMenu, EmptyMenu], id: '916', tp: 1, prv: true
    },
]

export const TECH_5 = [
    {
        desc: ['連續', '', 'K為', ''],
        menu: [Day_10, DWM, RedBlack], id: '928'
    }, {
        desc: ['連續', '', 'K為', ''],
        menu: [Day_10, DWM, RedBlack], id: '929'
    }, {
        desc: ['今天的是', '，1天前是', '，2天前是', '，3天前是', '，4天前是', ''],
        menu: [KLine, KLine, KLine, KLine, KLine], id: '623'
    }, {
        desc: ['最近', '都有', '跳空缺口', '',],
        menu: [Day_5, UpDown1, Percent0_20_1], id: '622'
    }, {
        desc: ['最近', '出現至少', '的', '跳空缺口', '以上'],
        menu: [Day_5, T20, UpDown1, Percent5_5_100], id: '665'
    }, {
        desc: ['今天是', '，實體', '', ''],
        menu: [RedBlack, BigSmall1, Percent0_20_1], id: '612'
    }, {
        desc: ['今天上影線', '', '，下影線', '', ''],
        menu: [BigSmall1, Percent0_20_1, BigSmall1, Percent0_20_1], id: '613'
    }, {
        desc: ['1天前是', '，實體', '', ''],
        menu: [RedBlack, BigSmall1, Percent0_20_1], id: '614'
    }, {
        desc: ['1天前上影線', '', '，下影線', '', ''],
        menu: [BigSmall1, Percent0_20_1, BigSmall1, Percent0_20_1], id: '615'
    }, {
        desc: ['2天前是', '，實體', '', ''],
        menu: [RedBlack, BigSmall1, Percent0_20_1], id: '616'
    }, {
        desc: ['2天前上影線', '', '，下影線', '', ''],
        menu: [BigSmall1, Percent0_20_1, BigSmall1, Percent0_20_1], id: '617'
    }, {
        desc: ['3天前是', '，實體', '', ''],
        menu: [RedBlack, BigSmall1, Percent0_20_1], id: '618'
    }, {
        desc: ['3天前上影線', '', '，下影線', '', ''],
        menu: [BigSmall1, Percent0_20_1, BigSmall1, Percent0_20_1], id: '619'
    }, {
        desc: ['4天前是', '，實體', '', ''],
        menu: [RedBlack, BigSmall1, Percent0_20_1], id: '620'
    }, {
        desc: ['4天前上影線', '', '，下影線', '', ''],
        menu: [BigSmall1, Percent0_20_1, BigSmall1, Percent0_20_1], id: '621'
    }, {
        desc: ['', '的K線', '', '', '的K線', ''],
        menu: [KDays, KType, BigSmall1, KDays, KType], id: '624'
    }, {
        desc: ['', '的K線', '', '', '的K線', ''],
        menu: [KDays, KType, BigSmall1, KDays, KType], id: '625'
    }, {
        desc: ['', '的K線', '', '', '的K線', ''],
        menu: [KDays, KType, BigSmall1, KDays, KType], id: '626'
    }
]



export const TECH_6 = [
    {
        desc: ['近', '內有', '以上是', '平均成交量', ''],
        menu: [Day_51, Day_51, Day_51, AddSub], id: '930', tp: 8, prv: 1
    }, {
        desc: ['近', '內有', '的單日成交量', '', '平均量'],
        menu: [Day_51, Day_51, BigSmall, Day_51], id: '931', tp: 8, prv: 1
    }, {
        desc: ['近', '成交量都', '', '均量'],
        menu: [Day_51, BigSmall, Day_51], id: '932', tp: 8
    }, {
        desc: ['成交量近', '有', '是', ''],
        menu: [Day_51, Day_51, GreenRed], id: '933', tp: 8
    }, {
        desc: ['成交量連續', '都是', ''],
        menu: [Day_51, GreenRed], id: '934', tp: 8
    }, {
        desc: ['近', '成交量總量介於', '張到', '張之間'],
        menu: [Day_51, EmptyMenu, EmptyMenu], id: '923', tp: 8
    }, {
        desc: ['今日交易量創', '新高'],
        menu: [Day_10], id: '769'
    }, {
        desc: ['今日成交量比前', '成交量多出', '到', ''],
        menu: [Day_10, Percent5_5_300, Percent5_5_300], id: '745'
    }, {
        desc: ['今日成交量', '前', '成交量的', ''],
        menu: [BigSmall, Day_10, Percent5_5_300], id: '746'
    }, {
        desc: ['今日成交值', '', ''],
        menu: [BigSmall, Dollar100001], id: '737'
    }, {
        desc: ['今日成交量大於', '均量的', ''],
        menu: [Day_10_240, Percent5_5_100], id: '736'
    }, {
        desc: ['今日成交量 > ', '日均量 > ', '日均量 > ', '日均量 > ', '日均量 > ', '日均量'],
        menu: [EmptyMenu, EmptyMenu, EmptyMenu, EmptyMenu, EmptyMenu], id: '734'
    }, {
        desc: ['今日成交量 < ', '日均量 < ', '日均量 < ', '日均量 < ', '日均量 < ', '日均量'],
        menu: [EmptyMenu, EmptyMenu, EmptyMenu, EmptyMenu, EmptyMenu], id: '747'
    }, {
        desc: ['', '日均量 > ', '日均量 > ', '日均量 > ', '日均量 > ', '日均量'],
        menu: [EmptyMenu, EmptyMenu, EmptyMenu, EmptyMenu, EmptyMenu], id: '9012', prv: 1, new: 0
    }, {
        desc: ['', '日均量 < ', '日均量 < ', '日均量 < ', '日均量 < ', '日均量'],
        menu: [EmptyMenu, EmptyMenu, EmptyMenu, EmptyMenu, EmptyMenu], id: '9013', prv: 1, new: 0
    }, {
        desc: ['日成交量連續', '都', '', ''],
        menu: [Day_10, AddSub, Piece0_50000], id: '714'
    },

]

export const TECH_15 = [
    {
        desc: ['', '成交量均量大於', ''],
        menu: [Day_10, Piece0_50000], id: '713'
    }, {
        desc: ['日成交量連續', '每日增加', '以上'],
        menu: [Day_5, Piece0_50000], id: '67'
    }, {
        desc: ['週成交量連續', '每週增加', '以上'],
        menu: [Week10, Piece0_50000], id: '68'
    }, {
        desc: ['月成交量連續', '每月增加', '以上'],
        menu: [Month12, Piece0_50000], id: '69'
    }, {
        desc: ['近', '每日成交張數都在', '以上'],
        menu: [Day_5, Piece0_50000], id: '94'
    }, {
        desc: ['近', '每週成交張數都在', '以上'],
        menu: [Week10, Piece0_50000], id: '95'
    }, {
        desc: ['近', '每月成交張數都在', '以上'],
        menu: [Month12, Piece0_50000], id: '96'
    }, {
        desc: ['近', '的日成交量暴增', '以上'],
        menu: [Day_5, Ba], id: '504'
    }, {
        desc: ['今日成交量為', '均量', '以下'],
        menu: [Day_5, Percent5_5_100], id: '562'
    }, {
        desc: ['今日成交量比', '均量多出', ''],
        menu: [Day_5, Percent5_5_100], id: '696'
    }, {
        desc: ['近', '成交量大於', '均量的', '以上'],
        menu: [Day_5, Day_5, Ba], id: '563'
    }, {
        desc: ['今日股價突破', '區間內最大成交量之股價高點價格'],
        menu: [Day_10], id: '765', prv: 1
    }, {
        desc: ['今日股價跌破', '區間內最大成交量之股價低點價格'],
        menu: [Day_10], id: '766', prv: 1
    }, {
        desc: ['今日股價接近', '區間內最大成交量之股價高點', ''],
        menu: [Day_10, Percent5_5_100], id: '767', prv: 1
    }, {
        desc: ['今日股價接近', '區間內最大成交量之股價低點', ''],
        menu: [Day_10, Percent5_5_100], id: '768', prv: 1
    }, {
        desc: ['近', '有發生', '跟', '均量'],
        menu: [Day_10, Day_51, Day_51, CrossW], id: '793', prv: 1
    }, {
        desc: ['近', '成交量', '前', '均量的', ''],
        menu: [Month12, BigSmall, Month12, Ba], id: '9008', prv: 1, new: 0
    }, {
        desc: ['今日有成交量的股票'],
        menu: [], id: '9018', prv: 1
    }, {
        desc: ['昨量比(預估量/昨日總量)大於', ''],
        menu: [Number100], id: '9019', prv: 1
    },
]

export const TECH_7 = [
    {
        desc: ['近', '內', '日與', '日RSI有發生'],
        menu: [Day_10, Number0_100, Number0_100, CrossW], id: '794'
    }, {
        desc: ['近', '內', '週與', '週RSI有發生'],
        menu: [Day_10, Number0_100, Number0_100, CrossW], id: '795'
    }, {
        desc: ['近', '內', '月與', '月RSI有發生'],
        menu: [Day_10, Number0_100, Number0_100, CrossW], id: '796'
    }, {
        desc: ['短天期', '日RSI', '長天期', '日RSI', '以上'],
        menu: [Number0_100, BigSmall, Number0_100, Number0_100], id: '797'
    }, {
        desc: ['短天期', '週RSI', '長天期', '週RSI', '以上'],
        menu: [Number0_100, BigSmall, Number0_100, Number0_100], id: '798'
    }, {
        desc: ['短天期', '月RSI', '長天期', '月RSI', '以上'],
        menu: [Number0_100, BigSmall, Number0_100, Number0_100], id: '799'
    }, {
        desc: ['', '', 'RSI突破', '並持續', '天'],
        menu: [Number0_100, DWM, Number0_100, Number0_100], id: '900'
    }, {
        desc: ['', '', 'RSI大於', '並持續', '天以上'],
        menu: [Number0_100, DWM, Number0_100, Number0_100], id: '9027'
    }, {
        desc: ['', '', 'RSI跌破', '並持續', '天'],
        menu: [Number0_100, DWM, Number0_100, Number0_100], id: '901'
    }, {
        desc: ['', '', 'RSI小於', '並持續', '天以上'],
        menu: [Number0_100, DWM, Number0_100, Number0_100], id: '9028'
    }, {
        desc: ['近', '內股價與', '', 'RSI呈現', ''],
        menu: [Day1020, Number0_100, DWM, BaLee], id: '681'
    }, {
        desc: ['', '日RSI大於', ''],
        menu: [Number0_100, Number0_100], id: '63'
    }, {
        desc: ['', '日RSI小於', ''],
        menu: [Number0_100, Number0_100], id: '64'
    }, {
        desc: ['最近', '內', '日RSI向上突破', '日RSI（黃金交叉）'],
        menu: [Day_10, Number0_100, Number0_100], id: '65'
    }, {
        desc: ['最近', '內', '日RSI向下跌破', '日RSI（死亡交叉）'],
        menu: [Day_10, Number0_100, Number0_100], id: '66'
    }, {
        desc: ['近', '內威廉指標短天期與長天期的值有呈現', '以上'],
        menu: [Day_10, CrossW], id: '706'
    }, {
        desc: ['', '前的威廉指標', '的值介於', '到', '之間'],
        menu: [Day_10, ShortLong, NumberN10, NumberN10], id: '707'
    }, {
        desc: ['今天威廉指標', '的值介於', '到', '之間'],
        menu: [ShortLong, NumberN10, NumberN10], id: '708'
    }, {
        desc: ['近', '威廉指標', '', ''],
        menu: [Day_10, ShortLong, UpDown1], id: '709'
    }, {
        desc: ['近', '威廉指標', '的值曾介於', '到', '之間'],
        menu: [Day_10, ShortLong, NumberN10, NumberN10], id: '710'
    },
]



export const TECH_8 = [
    {
        desc: ['今日股價一直', '開盤價'],
        menu: [BigSmall], id: '907'
    }, {
        desc: ['近', '內有發生連續', '漲停'],
        menu: [Day_10, Day_10], id: '904'
    }, {
        desc: ['近', '個股強於大盤', ''],
        menu: [Day_10, Percent0_20], id: '787'
    }, {
        desc: ['今日', '', '昨日', '', '以上'],
        menu: [OHL, BigSmall, OHL, Percent0_20], id: '780'
    }, {
        desc: ['今日', '', '昨日', '', '以上'],
        menu: [OHL, BigSmall, OHL, Percent0_20], id: '781'
    }, {
        desc: ['今日股價', '前', '', ''],
        menu: [BigSmall, Day_10, OHL], id: '778'
    }, {
        desc: ['今日股價', '前', '', ''],
        menu: [BigSmall, Day_10, OHL], id: '779'
    }, {
        desc: ['股價大於', '最高價的', '並小於', ''],
        menu: [DayYear, Percent5_5_100, Percent5_5_100], id: '742'
    }, {
        desc: ['今日的股價高於前', '最高價'],
        menu: [Day_5], id: '740'
    }, {
        desc: ['今日股價大於', '高點但不超過', ''],
        menu: [Day_10_240, Percent5_5_100], id: '738'
    }, {
        desc: ['近', '股票', '為', '以上'],
        menu: [Day_10_240, HL, Percent5_5_300], id: '723'
    }, {
        desc: ['近', '的股價創下', '來的新低'],
        menu: [Day_10, Day_5], id: '703'
    }, {
        desc: ['近', '輸給大盤', '以上'],
        menu: [Day_10, Day_10], id: '704'
    }, {
        desc: ['近', '內漲跌幅介於', '%到', '%之間'],
        menu: [Day_10, NumberN10N, NumberN10N], id: '902'
    }, {
        desc: ['近', '', 'TOP', ''],
        menu: [Day_10_2401, HL, Num50_500], id: '903'
    }, {
        desc: ['股價大於', '的最高價的', ''],
        menu: [DayYear, Percent5_5_100], id: '662'
    }, {
        desc: ['股價大於', '最低價的', ''],
        menu: [DayYear, Percent5_5_100], id: '663'
    }, {
        desc: ['目前股價高於', '元並且低於', ''],
        menu: [Dollar0_1000, Dollar0_1000], id: '13'
    }, {
        desc: ['最近', '內的股價已創下近', '來的新高'],
        menu: [Day_5, Year5], id: '52'
    }, {
        desc: ['最近', '的股價創', '來的新高'],
        menu: [Day_5, LongDay], id: '560'
    }, {
        desc: ['最近', '的股價已漲了', '以上'],
        menu: [Day_5, Percent5_5_100], id: '53'
    }, {
        desc: ['最近', '內打敗大盤至少', '以上'],
        menu: [Day_51, Day_51], id: '89'
    }, {
        desc: ['', ' ~ ', '期間漲幅最大的股票(例20200301)'],
        menu: [EmptyMenu, EmptyMenu], id: '535'
    }, {
        desc: ['', ' ~ ', '期間跌幅最大的股票(例20200301)'],
        menu: [EmptyMenu, EmptyMenu], id: '536'
    }, {
        desc: ['最近', '的股票振幅都大於', '以上'],
        menu: [Day_10, Percent0_20], id: '596'
    }, {
        desc: ['最近', '的漲幅最大的前', '名'],
        menu: [Day_10, Number10], id: '597'
    }, {
        desc: ['目前股價高於近6個月,1年,2年的最低價', '以內'],
        menu: [Percent0_20], id: '607'
    }, {
        desc: ['開盤價與收盤價', '', ''],
        menu: [BigSmall, Percent0_20], id: '610'
    },
]



export const TECH_9 = [
    {
        desc: ['最近', '的5天，20天平均線差距都在股價', '內'],
        menu: [Month3, Percent0_20], id: '522', tp: 11
    }, {
        desc: ['最近', '的5天，20天，60天平均線差距都在股價', '內'],
        menu: [Month3, Percent0_20], id: '46', tp: 11
    }, {
        desc: ['最近', '', '平均線差距都在股價', '內'],
        menu: [Day_5, Avg, Percent0_20], id: '573', tp: 11
    }, {
        desc: ['最近', '', '平均線差距都在股價', '以內'],
        menu: [Day_5, Avg1, Percent0_20], id: '791', tp: 11, prv: true
    }, {
        desc: ['最近', '', '平均線差距都在股價', '以內'],
        menu: [Day_5, Avg2, Percent0_20], id: '792', tp: 11, prv: true
    }, {
        desc: ['最近', '', '平均線差距都在', '以內'],
        menu: [Day_5, Avg, Percent0_20], id: '720', tp: 11
    }, {
        desc: ['最近', '股價有接近布林', '', '之內'],
        menu: [Day_10, BBLine, NumberSmall], id: '786', tp: 11
    }, {
        desc: ['今日的布林', '比前', '', '', ''],
        menu: [BBLine, Day_10, AddSub, Percent5_5_100], id: '657', tp: 11
    }, {
        desc: ['最近', '的布林帶寬都', '', ''],
        menu: [Day_20, BigSmall, Percent5_5_100], id: '514', tp: 11
    }, {
        desc: ['最近', '的布林%b都', '', ''],
        menu: [Day_20, BigSmall, Percent5_5_100], id: '515', tp: 11
    }, {
        desc: ['今日的布林帶寬比', '前的帶寬', '', ''],
        menu: [Day_20, BigSmall, Percent5_5_100], id: '516', tp: 11
    }, {
        desc: ['今日的布林%b比', '前的%b', '', ''],
        menu: [Day_20, BigSmall, Percent5_5_100], id: '517', tp: 11
    }, {
        desc: ['', '布林%b', ''],
        menu: [DWM,  UpDown, Percent5_5_100], id: '9029', tp: 11, new: 1
    }, {
        desc: ['', '前的布林帶寬已持續', '都', '', ''],
        menu: [Day_20, Day_20, BigSmall, Percent5_5_100], id: '518', tp: 11
    }, {
        desc: ['', '前的布林%b已持續', '都', '', ''],
        menu: [Day_20, Day_20, BigSmall, Percent5_5_100], id: '519', tp: 11
    }, {
        desc: ['最近', '內股價曾發生', '', ''],
        menu: [Day_20, UpDown, BBLine], id: '520', tp: 11
    }, {
        desc: ['最近', '內股價曾發生', '', ''],
        menu: [Day_20, UpDown, BBLine], id: '521', tp: 11
    }, {
        desc: ['最近', '股價曾', '肯特納', ''],
        menu: [Day_20, UpDown, BBLine], id: '937', tp: 11, prv: true
    }, {
        desc: ['最近', '股價曾', '肯特納', ''],
        menu: [Day_20, UpDown, BBLine], id: '938', tp: 11, prv: true
    },
]

export const TECH_10 = [
    {
        desc: ['近', '發生CCI值', '', ''],
        menu: [Day_10, UpDown, EmptyMenu], id: '9005', tp: 9, prv: true
    },{
        desc: ['連續', 'CCI值', '', ''],
        menu: [Day_10, BigSmall, EmptyMenu], id: '9006', tp: 9, prv: true
    },{
        desc: ['', '的乖離率大於', ''],
        menu: [MA1, PercentP0_100], id: '537', tp: 9
    },
    {
        desc: ['', '的乖離率小於', ''],
        menu: [MA1, PercentN5_5_100], id: '538', tp: 9
    }, {
        desc: ['收盤價落在樂活五線譜的', ''],
        menu: [Happy], id: '603', tp: 9
    }, {
        desc: ['樂活五線譜趨勢線方向是', ''],
        menu: [Happy1], id: '604', tp: 9
    }, {
        desc: ['近', 'Mansfield RS值都', '', ''],
        menu: [Day_10, BigSmall, Number10Time], id: '716', tp: 9
    }, {
        desc: ['近', 'Mansfield RS值都持續', ''],
        menu: [Day_10, AddSub], id: '717', tp: 9
    }, {
        desc: ['今日Mansfield RS值從', '排名前100名'],
        menu: [BigToSmall], id: '718', tp: 9
    }, {
        desc: ['近', 'Mansfield RS值從', ''],
        menu: [Day_10, PN], id: '719', tp: 9
    }, {
        desc: ['近', '股票大盤比都', '', ''],
        menu: [Day_10, BigSmall, NumberSmall], id: '721', tp: 9
    }, {
        desc: ['近', '股票大盤比都持續', ''],
        menu: [Day_10, AddSub], id: '722', tp: 9
    }, {
        desc: ['近', '發生短心理線與長心理線', ''],
        menu: [Day_10, CrossW], id: '925', tp: 9
    }, {
        desc: ['今日短心理線', '', ''],
        menu: [BigSmall, EmptyMenu], id: '926', tp: 9
    }, {
        desc: ['今日長心理線', '', ''],
        menu: [BigSmall, EmptyMenu], id: '927', tp: 9
    },
]



export const TECH_11 = [
    {
        desc: ['最近', '+DI都', '', '(', ')'],
        menu: [Day_0_10, BigSmall, Percent5_5_100, DWM], id: '639'
    }, {
        desc: ['最近', '-DI都', '', '(', ')'],
        menu: [Day_0_10, BigSmall, Percent5_5_100, DWM], id: '640'
    }, {
        desc: ['最近', 'ADX都', '', '(', ')'],
        menu: [Day_0_10, BigSmall, Percent5_5_100, DWM], id: '641'
    }, {
        desc: ['最近', '+DI', '', '(', ')'],
        menu: [Day_0_10, AddSub, Percent5_5_100, DWM], id: '642'
    }, {
        desc: ['最近', '-DI', '', '(', ')'],
        menu: [Day_0_10, AddSub, Percent5_5_100, DWM], id: '643'
    }, {
        desc: ['最近', 'ADX', '', '(', ')'],
        menu: [Day_0_10, AddSub, Percent5_5_100, DWM], id: '644'
    }, {
        desc: ['最近', '發生過+DI與-DI', '(', ')'],
        menu: [Day_0_10, CrossW, DWM], id: '645'
    }, {
        desc: ['今日', '', '', '', '以上(', ')'],
        menu: [DMI1, BigSmall1, DMI1, Percent5_5_300, DWM], id: '654'
    }, {
        desc: ['近', '發生', '與', '', '(', ')'],
        menu: [Day_10, DMI1, DMI1, CrossW, DWM], id: '655'
    }, {
        desc: ['近', '發生', '與', '', '(', ')'],
        menu: [Day_10, DMI1, DMI1, CrossW, DWM], id: '672'
    }, {
        desc: ['近', '日OBV', '，股價', ''],
        menu: [Day_10, UpDown2, UpDown2], id: '959'
    }, {
        desc: ['近', '日OBV', '，股價', ''],
        menu: [Day_10, UpDown2, UpDown2], id: '960'
    }, {
        desc: ['近', '日OBV', '', '以上'],
        menu: [Day_10, AddSub, Percent5_5_300], id: '961'
    }, {
        desc: ['近', '日OBV', '', '以上'],
        menu: [Day_10, AddSub, Percent5_5_300], id: '962'
    }, {
        desc: ['日OBV創下近', '新', ''],
        menu: [Day_5, HighLow1], id: '965'
    }, {
        desc: ['近', '發生SAR從', '波變', '波'],
        menu: [Day_10, UpDown2, UpDown2], id: '9000', new: 0, prv: true
    }, {
        desc: ['連續', 'SAR都是', '波'],
        menu: [Day_10, UpDown2], id: '9001', new: 0, prv: true
    }, {
        desc: ['近', 'SAR', '波佔', '以上'],
        menu: [Day_10, UpDown2, Day_10], id: '9002', new: 0, prv: true
    },
]


export const TECH_16 = [
    {
        desc: ['本周的是', '，1周前是', '，2周前是', '，3周前是', '，4周前是', ''],
        menu: [KLine, KLine, KLine, KLine, KLine], id: '968'
    }, {
        desc: ['最近', '都有', '跳空缺口', '',],
        menu: [Day_5, UpDown1, Percent0_20_1], id: '969'
    }, {
        desc: ['最近', '出現至少', '的', '跳空缺口', '以上'],
        menu: [Day_5, T20, UpDown1, Percent5_5_100], id: '970'
    }, {
        desc: ['本周是', '，實體', '', ''],
        menu: [RedBlack, BigSmall1, Percent0_20_1], id: '971'
    }, {
        desc: ['本周上影線', '', '，下影線', '', ''],
        menu: [BigSmall1, Percent0_20_1, BigSmall1, Percent0_20_1], id: '972'
    }, {
        desc: ['1周前是', '，實體', '', ''],
        menu: [RedBlack, BigSmall1, Percent0_20_1], id: '973'
    }, {
        desc: ['1周前上影線', '', '，下影線', '', ''],
        menu: [BigSmall1, Percent0_20_1, BigSmall1, Percent0_20_1], id: '974'
    }, {
        desc: ['2周前是', '，實體', '', ''],
        menu: [RedBlack, BigSmall1, Percent0_20_1], id: '975'
    }, {
        desc: ['2周前上影線', '', '，下影線', '', ''],
        menu: [BigSmall1, Percent0_20_1, BigSmall1, Percent0_20_1], id: '976'
    }, {
        desc: ['3周前是', '，實體', '', ''],
        menu: [RedBlack, BigSmall1, Percent0_20_1], id: '977'
    }, {
        desc: ['3周前上影線', '', '，下影線', '', ''],
        menu: [BigSmall1, Percent0_20_1, BigSmall1, Percent0_20_1], id: '978'
    }, {
        desc: ['4周前是', '，實體', '', ''],
        menu: [RedBlack, BigSmall1, Percent0_20_1], id: '979'
    }, {
        desc: ['4周前上影線', '', '，下影線', '', ''],
        menu: [BigSmall1, Percent0_20_1, BigSmall1, Percent0_20_1], id: '980'
    }, {
        desc: ['', '的K線', '', '', '的K線', ''],
        menu: [KWeeks, KType, BigSmall1, KWeeks, KType], id: '981'
    }, {
        desc: ['', '的K線', '', '', '的K線', ''],
        menu: [KWeeks, KType, BigSmall1, KWeeks, KType], id: '982'
    }, {
        desc: ['', '的K線', '', '', '的K線', ''],
        menu: [KWeeks, KType, BigSmall1, KWeeks, KType], id: '983'
    }, {
        desc: ['本周周K', '前', '周周K最高點'],
        menu: [BigSmall, Num51], id: '9024', prv: 1
    }
]

export const TECH_17 = [
    {
        desc: ['本月的是', '，1個月前是', '，2個月前是', '，3個月前是', '，4個月前是', ''],
        menu: [KLine, KLine, KLine, KLine, KLine], id: '984'
    }, {
        desc: ['最近', '都有', '跳空缺口', '',],
        menu: [Day_5, UpDown1, Percent0_20_1], id: '985'
    }, {
        desc: ['最近', '出現至少', '的', '跳空缺口', '以上'],
        menu: [Day_5, T20, UpDown1, Percent5_5_100], id: '986'
    }, {
        desc: ['本月是', '，實體', '', ''],
        menu: [RedBlack, BigSmall1, Percent0_20_1], id: '987'
    }, {
        desc: ['本月上影線', '', '，下影線', '', ''],
        menu: [BigSmall1, Percent0_20_1, BigSmall1, Percent0_20_1], id: '988'
    }, {
        desc: ['1個月前是', '，實體', '', ''],
        menu: [RedBlack, BigSmall1, Percent0_20_1], id: '989'
    }, {
        desc: ['1個月前上影線', '', '，下影線', '', ''],
        menu: [BigSmall1, Percent0_20_1, BigSmall1, Percent0_20_1], id: '990'
    }, {
        desc: ['2個月前是', '，實體', '', ''],
        menu: [RedBlack, BigSmall1, Percent0_20_1], id: '991'
    }, {
        desc: ['2個月前上影線', '', '，下影線', '', ''],
        menu: [BigSmall1, Percent0_20_1, BigSmall1, Percent0_20_1], id: '992'
    }, {
        desc: ['3個月前是', '，實體', '', ''],
        menu: [RedBlack, BigSmall1, Percent0_20_1], id: '993'
    }, {
        desc: ['3個月前上影線', '', '，下影線', '', ''],
        menu: [BigSmall1, Percent0_20_1, BigSmall1, Percent0_20_1], id: '994'
    }, {
        desc: ['4個月前是', '，實體', '', ''],
        menu: [RedBlack, BigSmall1, Percent0_20_1], id: '995'
    }, {
        desc: ['4個月前上影線', '', '，下影線', '', ''],
        menu: [BigSmall1, Percent0_20_1, BigSmall1, Percent0_20_1], id: '996'
    }, {
        desc: ['', '的K線', '', '', '的K線', ''],
        menu: [KMonth, KType, BigSmall1, KMonth, KType], id: '997'
    }, {
        desc: ['', '的K線', '', '', '的K線', ''],
        menu: [KMonth, KType, BigSmall1, KMonth, KType], id: '998'
    }, {
        desc: ['', '的K線', '', '', '的K線', ''],
        menu: [KMonth, KType, BigSmall1, KMonth, KType], id: '999'
    }, {
        desc: ['本月月K', '前', '月月K最高點'],
        menu: [BigSmall, Num51], id: '9025', prv: 1
    }
]


export const TECH_1_IDS = TECH_1.map(i => { return i.id })
export const TECH_2_IDS = TECH_2.map(i => { return i.id })
export const TECH_3_IDS = TECH_3.map(i => { return i.id })
export const TECH_4_IDS = TECH_4.map(i => { return i.id })
export const TECH_5_IDS = TECH_5.map(i => { return i.id })
export const TECH_6_IDS = TECH_6.map(i => { return i.id })
export const TECH_7_IDS = TECH_7.map(i => { return i.id })
export const TECH_8_IDS = TECH_8.map(i => { return i.id })
export const TECH_9_IDS = TECH_9.map(i => { return i.id })
export const TECH_10_IDS = TECH_10.map(i => { return i.id })
export const TECH_11_IDS = TECH_11.map(i => { return i.id })
export const TECH_12_IDS = TECH_12.map(i => { return i.id })
export const TECH_13_IDS = TECH_13.map(i => { return i.id })
export const TECH_14_IDS = TECH_14.map(i => { return i.id })
export const TECH_15_IDS = TECH_15.map(i => { return i.id })
export const TECH_16_IDS = TECH_16.map(i => { return i.id })
export const TECH_17_IDS = TECH_17.map(i => { return i.id })


//////////////////////////////////////////////////////////////////////
///////////////////////////////////BASIC//////////////////////////////
//////////////////////////////////////////////////////////////////////


export const BASIC_1 = [
    {
        desc: ['市值與', '個月營收總和比小於', ''],
        menu: [Num51, Point], id: '924', tp: 6,
    }, {
        desc: ['最新營收為近', '以來的前', '名'],
        menu: [Month12Year7, Number0_15], id: '770', tp: 6, prv: true,
    }, {
        desc: ['近三個月營收', '都超過'],
        menu: [RevM, Percent5_5_100, Percent5_5_100, Percent5_5_100], id: '788', tp: 6, prv: true
    }, {
        desc: ['連續', '營收', '都增加', '以上'],
        menu: [Month12, RevM, Percent5_5_100], id: '789', tp: 6, prv: true
    }, {
        desc: ['', '平均營收', '', '平均營收', '以上'],
        menu: [Month12, BigSmall, Month12, Percent5_5_100], id: '790', tp: 6, prv: true
    }, {
        desc: ['今年至今的總營收創', '來同月份的新高'],
        menu: [Month12], id: '739', tp: 6
    }, {
        desc: ['上個月的營收', '小於', '，這個月的營收', '大於'],
        menu: [RevM, Percent5_5_100, RevM, Percent5_5_100], id: '664', tp: 6
    }, {
        desc: ['近一月的營收創下', '來的新高'],
        menu: [Year51], id: '658', tp: 6
    }, {
        desc: ['最近', '總營收跟過去', '總營收相比，有超過', '以上'],
        menu: [Month12Year7, Month12Year7, Percent5_5_100], id: '31', tp: 6
    }, {
        desc: ['最近', '內，營收每', '都持續有', '以上的成長'],
        menu: [Year7, Month12Year7, Percent5_5_100], id: '2', tp: 6
    }, {
        desc: ['最近', '的營收跟去年同期相比增加', '以上的成長'],
        menu: [Month12Year7, Percent5_5_100], id: '30', tp: 6
    }, {
        desc: ['最近', '的營收', '都大於', ''],
        menu: [Month12, RevM, Percent5_5_100], id: '591', tp: 6
    }, {
        desc: ['最近', '的營收', '都大於', ''],
        menu: [Month12, RevM, Percent5_5_100], id: '771', tp: 6
    }
]

export const BASIC_10 = [
    {
        desc: ['近1季的EPS創近', '的新高'],
        menu: [SeasonAll2], id: '918', tp: 6
    }, {
        desc: ['近1年的EPS創近', '的新高'],
        menu: [Year51], id: '919', tp: 6
    }, {
        desc: ['近', 'EPS的YoY都大於', '以上'],
        menu: [SeasonAll1, Percent5_5_300], id: '652', tp: 6
    }, {
        desc: ['近', 'EPS的QoQ都大於', '以上'],
        menu: [SeasonAll1, Percent5_5_300], id: '653', tp: 6
    }, {
        desc: ['連續', '的EPS都大於', ''],
        menu: [Season3Year7, Dollar0_50], id: '7', tp: 6
    }, {
        desc: ['最近', '的EPS已經超過去年整年EPS的', ''],
        menu: [Season3, Percent5_5_100], id: '14', tp: 6
    }, {
        desc: ['最近', '的EPS跟過去', '相比, 增加', '以上'],
        menu: [Season3, Season3, Percent5_5_300], id: '49', tp: 6
    }, {
        desc: ['近', '的EPS的YoY都大於', '以上'],
        menu: [Year51, Percent5_5_300], id: '776', tp: 6
    }, {
        desc: ['近', '內有發生EPS由', ''],
        menu: [SeasonAll1, WL], id: '785', tp: 6
    }
]


export const BASIC_2 = [
    {
        desc: ['今年公告的', 'YoY大於', ''],
        menu: [IntCashStock, Percent5_5_300], id: '9003', prv: 1
    }, {
        desc: ['股本大於', '且小於', ''],
        menu: [MoneyMB, MoneyMB], id: '667'
    }, {
        desc: ['持續', '，現金股利都發放超過', '以上'],
        menu: [Year7, Dollar0_50], id: '3'
    }, {
        desc: ['持續', '，每張股票都發放超過', '以上'],
        menu: [Year7, Stock0_2000], id: '4'
    }, {
        desc: ['持續', '，每年發放的股利都占盈餘的', '以上'],
        menu: [Year7, Percent10_100_0], id: '12'
    }, {
        desc: ['最近', '的除權息，填權息天數要在', '之內'],
        menu: [T20, Day_5], id: '90'
    }, {
        desc: ['最近', '內，董事或股東會公告發放', '超過', ''],
        menu: [Day_10, IntCashStock, Dollar0_50], id: '531'
    }, {
        desc: ['', '的', '內，董事或股東會公告', '大於', ''],
        menu: [BYear, MonthAll, Rate, Percent0_20], id: '724', prv: 1
    }, {
        desc: ['', '的', '發生除權息，', '大於', ''],
        menu: [BYear, MonthAll, Rate, Percent0_20], id: '726'
    }, {
        desc: ['近', '的平均', '大於', ''],
        menu: [Year51, Rate, Percent0_20], id: '727'
    }, {
        desc: ['近', '的最小值', '大於', ''],
        menu: [Year51, Rate, Percent0_20], id: '777'
    }, {
        desc: ['今年董事或股東會尚未公告股利'],
        menu: [], id: '728'
    }, {
        desc: ['最近', '內要除權息'],
        menu: [Day_5], id: '572'
    }, {
        desc: ['依據今年公告，', '大於', '以上'],
        menu: [Rate, Percent0_20], id: '589'
    }, {
        desc: ['最近一次除息現金股息殖利率大於', ''],
        menu: [Percent0_20], id: '6'
    }
]

export const BASIC_3 = [
    {
        desc: ['目前本益比小於', ''],
        menu: [Time4_30], id: '5', tp: 7
    }, {
        desc: ['目前本益比大於', ''],
        menu: [Time4_30], id: '935', tp: 7
    }, {
        desc: ['連續', '三率三升'],
        menu: [Season1], id: '635', tp: 7
    }, {
        desc: ['最近', '的毛利率平均大於', ''],
        menu: [Season3Year7, Percent5_5_100], id: '8', tp: 7
    }, {
        desc: ['最近', '的平均毛利率創下', '來新高'],
        menu: [Season3Year7, Year7], id: '15', tp: 7
    }, {
        desc: ['最近', '的平均毛利率比前', '都來的高'],
        menu: [Season12, Season12], id: '511', tp: 7
    }, {
        desc: ['最近', '的毛利YoY都大於', '以上'],
        menu: [SeasonAll, Percent5_5_300], id: '575', tp: 7
    }, {
        desc: ['最近', '的毛利QoQ都大於', '以上'],
        menu: [SeasonAll, Percent5_5_300], id: '576', tp: 7
    }, {
        desc: ['目前本益比高於近6個月,1年,2年的最低價', '以內'],
        menu: [Percent0_20], id: '608', tp: 7
    }, {
        desc: ['股本大於', '億元並小於', '億元'],
        menu: [Number100, Number100], id: '611', tp: 7
    }, {
        desc: ['股價排名', '營收毛利排名', '以上'],
        menu: [BigSmall, Number1000], id: '648', tp: 7
    }, {
        desc: ['總報酬本益比', ''],
        menu: [BigSmall, Point], id: '921', tp: 7
    }, {
        desc: ['產業類別為', ''],
        menu1: [Tp], id: '524', tp: 7
    },
]


export const BASIC_4 = [
    {
        desc: ['近', '的', '都大於', ''],
        menu: [Season1, ROEA, Percent5_5_100], id: '757'
    }, {
        desc: ['近', '的', '都大於', ''],
        menu: [Season1, ROEA, Percent5_5_100], id: '759'
    }, {
        desc: ['連續', '的近四季', '都大於', ''],
        menu: [Season1, ROEA, Percent5_5_100], id: '758'
    }, {
        desc: ['連續', '的近四季', '都大於', ''],
        menu: [Season1, ROEA, Percent5_5_100], id: '760'
    }, {
        desc: ['最近', '的股東權益報酬率都大於', ''],
        menu: [Year7, Percent5_5_100], id: '9'
    }, {
        desc: ['最近', '的股東權益報酬率都大於', ''],
        menu: [Year7, Percent5_5_100], id: '772'
    }, {
        desc: ['最近', '的資產報酬率都大於', ''],
        menu: [Year7, Percent5_5_100], id: '10'
    }, {
        desc: ['最近', '的負債比率都小於', ''],
        menu: [Year7, Percent5_5_100], id: '11'
    }, {
        desc: ['近', '的本業收入都大於稅前淨利的', ''],
        menu: [Season3Year7, Percent10_100_0], id: '88'
    }, {
        desc: ['最近', '內的利息保障倍數都大於', '以上'],
        menu: [Year51, Ba1], id: '595'
    },
]

export const BASIC_5 = [
    {
        desc: ['帳上現金及約當現金高於股本', '倍'],
        menu: [Num51], id: '9026', prv: 1
    },
    {
        desc: ['近', '資產負債表年報中的', '每年都增加', ''],
        menu: [Year51, BS1, Percent5_5_300], id: '682'
    }, {
        desc: ['近', '資產負債表年報中的', '每年都增加', ''],
        menu: [Year51, BS1, Percent5_5_300], id: '688'
    }, {
        desc: ['近', '損益表年報中的', '每年都增加', ''],
        menu: [Year51, PL, Percent5_5_300], id: '683'
    }, {
        desc: ['近', '損益表年報中的', '每年都增加', ''],
        menu: [Year51, PL, Percent5_5_300], id: '689'
    }, {
        desc: ['近', '現金流量表年報中的', '每年都增加', ''],
        menu: [Year51, Cash, Percent5_5_300], id: '684'
    }, {
        desc: ['近', '現金流量表年報中的', '每年都增加', ''],
        menu: [Year51, Cash, Percent5_5_300], id: '690'
    }, {
        desc: ['近', '現金流量表年報中的', '都大於', ''],
        menu: [Year51, Cash, MoneyMB], id: '9030', new: 1
    }, {
        desc: ['近', '資產負債表年報中的', '平均增加', ''],
        menu: [Year51, BS1, Percent5_5_300], id: '685'
    }, {
        desc: ['近', '資產負債表年報中的', '平均增加', ''],
        menu: [Year51, BS1, Percent5_5_300], id: '691'
    }, {
        desc: ['近', '損益表年報中的', '平均增加', ''],
        menu: [Year51, PL, Percent5_5_300], id: '686'
    }, {
        desc: ['近', '損益表年報中的', '平均增加', ''],
        menu: [Year51, PL, Percent5_5_300], id: '692'
    }, {
        desc: ['近', '現金流量表年報中的', '平均增加', ''],
        menu: [Year51, Cash, Percent5_5_300], id: '687'
    }, {
        desc: ['近', '現金流量表年報中的', '平均增加', ''],
        menu: [Year51, Cash, Percent5_5_300], id: '693'
    },
]


export const BASIC_6 = [
    {
        desc: ['最近', '的營業利益率都大於', ''],
        menu: [Season3Year4, Percent5_5_100], id: '16', tp: 10
    }, {
        desc: ['最近', '的平均營業利益率創下', '以來的新高'],
        menu: [Season3Year4, Year7], id: '17', tp: 10
    }, {
        desc: ['最近', '的平均營業利益率比前', '都來的高'],
        menu: [Season12, Season12], id: '512', tp: 10
    }, {
        desc: ['最近', '的營益YoY都大於', '以上'],
        menu: [SeasonAll, Percent5_5_300], id: '577', tp: 10
    }, {
        desc: ['最近', '的營益QoQ都大於', '以上'],
        menu: [SeasonAll, Percent5_5_300], id: '578', tp: 10
    }, {
        desc: ['最近', '的稅後淨利率平均大於', ''],
        menu: [Season3Year4, Percent5_5_100], id: '18', tp: 10
    }, {
        desc: ['最近', '的平均稅後淨利率創下', '以來的新高'],
        menu: [Season3Year4, Year7], id: '19', tp: 10
    }, {
        desc: ['最近', '的平均稅後淨利率比前', '都來的高'],
        menu: [Season12, Season12], id: '513', tp: 10
    }, {
        desc: ['最近', '的淨利YoY都大於', '以上'],
        menu: [SeasonAll, Percent5_5_300], id: '579', tp: 10
    }, {
        desc: ['最近', '的淨利QoQ都大於', '以上'],
        menu: [SeasonAll, Percent5_5_300], id: '580', tp: 10
    }, {
        desc: ['股價淨值比小於', ''],
        menu: [Number0_15], id: '51', tp: 10
    }, {
        desc: ['股價淨值比大於', ''],
        menu: [Number0_15], id: '936', tp: 10
    },
]

export const BASIC_7 = [
    {
        desc: ['最近', '的流動比率都超過', ''],
        menu: [Year7, Percent100_0_1000], id: '23'
    }, {
        desc: ['最近', '的速動比率都超過', ''],
        menu: [Year7, Percent100_0_1000], id: '24'
    }, {
        desc: ['股價小於', '平均現金股利的', ''],
        menu: [Year7, Time4_30], id: '25'
    }, {
        desc: ['現在的股價小於用最近', '的平均自由現金流去估算公司存活', '年，折現率為', '的股價'],
        menu: [Year7, Year10, Percent5_5_100], id: '32'
    }, {
        desc: ['最近', '內的合約負債率都大於', '以上'],
        menu: [Year51, Percent5_5_100], id: '594'
    }, {
        desc: ['最近', '合約負債都較上季增加', '以上'],
        menu: [Season1, Percent5_5_300], id: '636'
    }, {
        desc: ['最近', '合約負債都較去年同期增加', '以上'],
        menu: [Season1, Percent5_5_300], id: '637'
    }
]


export const BASIC_8 = [
    {
        desc: ['可轉債轉換價介於', '到', '之間'],
        menu: [Dollar0_1000, Dollar0_1000], id: '660'
    }, {
        desc: ['可轉債現價介於', '到', '之間'],
        menu: [Dollar0_1000, Dollar0_1000], id: '661'
    }, {
        desc: ['可轉債轉換價值介於', '到', '之間'],
        menu: [Dollar0_1000, Dollar0_1000], id: '9033', new: 1
    }, {
        desc: ['近', '的', '波動率', '', ''],
        menu: [Day_10_240, DWM, BigSmall, Percent5_5_100], id: '587'
    }, {
        desc: ['近', '的beta', '', ''],
        menu: [Day_10_240, BigSmall, BetaV], id: '588'
    }, {
        desc: ['有發行可轉換公司債'],
        menu: [], id: '528'
    }, {
        desc: ['可轉債距到期日', '', '以上'],
        menu: [BigSmall, Day_10_240], id: '592'
    }, {
        desc: ['可轉債', '比率', '', ''],
        menu: [Premi, BigSmall, Percent5_5_100], id: '593'
    }, {
        desc: ['可轉換公司債有擔保'],
        menu: [], id: '530'
    }
]


export const BASIC_9 = [
    {
        desc: ['挑選', ''],
        menu: [STp], id: '605'
    }, {
        desc: ['ETF', '比率', '', ''],
        menu: [Premi, BigSmall, Percent5_5_100], id: '606'
    }, {
        desc: ['股票/ETF代號為', ''],
        id: '539', stext: [1]
    }, {
        desc: ['股票期貨標的'],
        menu: [], id: '963', new: 0
    }, {
        desc: ['股票選擇權標的'],
        menu: [], id: '964', new: 0
    }, {
        desc: ['非股票期貨標的'],
        menu: [], id: '966', new: 0
    }, {
        desc: ['非股票選擇權標的'],
        menu: [], id: '967', new: 0
    }, {
        desc: ['目前', '處置股'],
        menu: [YesNo], id: '9009', new: 0, prv: 1
    }, {
        desc: ['目前', '注意股'],
        menu: [YesNo], id: '9010', new: 0, prv: 1
    }, {
        desc: ['目前有達到處置門檻，尚未發生處置'],
        menu: [], id: '9011', new: 0, prv: 1
    },


]



export const BASIC_1_IDS = BASIC_1.map(i => { return i.id })
export const BASIC_2_IDS = BASIC_2.map(i => { return i.id })
export const BASIC_3_IDS = BASIC_3.map(i => { return i.id })
export const BASIC_4_IDS = BASIC_4.map(i => { return i.id })
export const BASIC_5_IDS = BASIC_5.map(i => { return i.id })
export const BASIC_6_IDS = BASIC_6.map(i => { return i.id })
export const BASIC_7_IDS = BASIC_7.map(i => { return i.id })
export const BASIC_8_IDS = BASIC_8.map(i => { return i.id })
export const BASIC_9_IDS = BASIC_9.map(i => { return i.id }).concat(['675', '676'])
export const BASIC_10_IDS = BASIC_10.map(i => { return i.id })



//////////////////////////////////////////////////////////////////////
///////////////////////////////////懶人選股////////////////////////////
//////////////////////////////////////////////////////////////////////

export const LAZY_1 = [
    { desc: ['本益比'], id: '101' },
    { desc: ['營收成長率'], id: '102' },
    { desc: ['股東權益報酬率'], id: '104' },
    { desc: ['資產收益率'], id: '105' },
    { desc: ['毛利率'], id: '106' },
    { desc: ['持續股利發放'], id: '108' },
    { desc: ['股利發放率'], id: '109' },
    { desc: ['殖利率'], id: '103' },
]

export const LAZY_2 = [
    {
        desc: ['近', 'CMF有發生', ''],
        menu: [Day_5, CrossW], id: '801'
    }, {
        desc: ['近', 'CMF都', '', ''],
        menu: [Day_5, BigSmall, Number1110], id: '802'
    }, {
        desc: ['連續', '價格', '與CMF', ''],
        menu: [Day_5, AddSub, AddSub], id: '803'
    },
    { desc: ['季正YoY的比例'], id: '360' },
    { desc: ['季正QoQ的比例'], id: '361' },
    { desc: ['季平均YoY'], id: '362' },
    { desc: ['季平均QoQ'], id: '363' },
    { desc: ['年正YoY的比例'], id: '364' },
    { desc: ['年平均YoY'], id: '365' },
    { desc: ['最近一季YoY'], id: '367' },
    { desc: ['最近一季QoQ'], id: '368' },
    { desc: ['最近一年YoY'], id: '369' },
    { desc: ['最近四季YoY'], id: '370' },
    { desc: ['年預估'], id: '372' },
]


export const LAZY_3 = [
    {
        desc: ['近', '籌碼呈現', '(依據成交張數)'],
        menu: [Day_0_10, EBSMenu], id: '431'
    }, {
        desc: ['近', '籌碼呈現', '(依據成交金額)'],
        menu: [Day_0_10, EBSMenu], id: '432'
    }, {
        desc: ['近', '籌碼連續呈現', '(依據成交張數)'],
        menu: [Day_0_10, EBSMenu], id: '433'
    }, {
        desc: ['近', '籌碼連續呈現', '(依據成交金額)'],
        menu: [Day_0_10, EBSMenu], id: '434'
    },
    { desc: ['主力熱買'], id: '301' },
    { desc: ['大戶熱買'], id: '302' },
    { desc: ['散戶熱買'], id: '307' },
    { desc: ['內部大戶熱買'], id: '308' },
    { desc: ['內部大戶狂賣'], id: '309' },
    { desc: ['神秘券商熱買'], id: '306' },
    { desc: ['大戶持續購買'], id: '121' },
    { desc: ['買超籌碼集中'], id: '310' },
    { desc: ['賣超籌碼集中'], id: '311' },
    { desc: ['借券賣出餘額大增'], id: '337' },
]

export const LAZY_4 = [
    { desc: ['外資熱買'], id: '303' },
    { desc: ['投信熱買'], id: '304' },
    { desc: ['自營商熱買'], id: '305' },
    { desc: ['八大官股熱買'], id: '348' },
    { desc: ['外資持續購買'], id: '118' },
    { desc: ['投信持續購買'], id: '119' },
    { desc: ['自營商持續購買'], id: '120' },
    { desc: ['八大官股持續買'], id: '349' },
]

export const LAZY_5 = [
    { desc: ['5日內與大盤勝負'], id: '312' },
    { desc: ['10日內與大盤勝負'], id: '313' },
    { desc: ['20日內與大盤勝負'], id: '314' },
    { desc: ['60日內與大盤勝負'], id: '315' },
    { desc: ['5日漲幅'], id: '316' },
    { desc: ['10日漲幅'], id: '317' },
    { desc: ['20日漲幅'], id: '318' },
    { desc: ['60日漲幅'], id: '319' },
    { desc: ['連續贏大盤天數'], id: '320' },
    { desc: ['近九年平均填息/權天數'], id: '336' },
    { desc: ['近九年股息發放率'], id: '334' },
    { desc: ['近九年填息/權率'], id: '335' },
]

export const LAZY_6 = [
    { desc: ['營收毛利為王'], id: '435' },
    { desc: ['歷史月排名'], id: '321' },
    { desc: ['歷史季排名'], id: '322' },
    { desc: ['歷史年排名'], id: '323' },
    { desc: ['近一年常創新高'], id: '330' },
    { desc: ['月增率為正次數'], id: '324' },
    { desc: ['季增率為正次數'], id: '325' },
    { desc: ['年增率為正次數'], id: '326' },
    { desc: ['近二年常創新高'], id: '331' },
    { desc: ['月增率連續為正'], id: '327' },
    { desc: ['季增率連續為正'], id: '328' },
    { desc: ['年增率連續為正'], id: '329' },
    { desc: ['近三年常創新高'], id: '332' },
    { desc: ['成長率動能大幅增強'], id: '406' },
    { desc: ['成長率黃金交叉'], id: '407' },
    { desc: ['營收動能大幅增強'], id: '408' },
    { desc: ['營收黃金交叉'], id: '409' },
]

export const LAZY_7 = [
    {
        desc: ['PEG(稅後淨利成長)大於', '且小於', ''],
        menu: [Point, Point], id: '446'
    }, {
        desc: ['PEG(EPS成長)大於', '且小於', ''],
        menu: [Point, Point], id: '447'
    }, {
        desc: ['PEG(預估EPS成長)大於', '且小於', ''],
        menu: [Point, Point], id: '448'
    },
    { desc: ['現金流折現法'], id: '338' },
    { desc: ['股利折現法'], id: '339' },
    { desc: ['本益比比較法'], id: '340' },
    { desc: ['近五日價格突破極度悲傷價'], id: '430' },
    {
        desc: ['股價處在', '(股價盈餘比圖)'],
        menu: [PriceLevel], id: '436'
    }, {
        desc: ['股價處在', '(股價營收比圖)'],
        menu: [PriceLevel], id: '437'
    }, {
        desc: ['股價處在', '(股價淨值比圖)'],
        menu: [PriceLevel], id: '438'
    }, {
        desc: ['股價處在', '(股價乖離圖)'],
        menu: [PriceLevel], id: '439'
    }, {
        desc: ['串聯指標綠轉紅並維持', '以上'],
        menu: [Day_5], id: '809'
    }, {
        desc: ['串聯指標紅轉綠並維持', '以上'],
        menu: [Day_5], id: '810'
    }, {
        desc: ['串聯指標紅色Top', ''],
        menu: [Number0500], id: '811'
    }, {
        desc: ['串聯指標綠色Top', ''],
        menu: [Number0500], id: '812'
    }, {
        desc: ['串聯指標大於', ''],
        menu: [Number01001], id: '813'
    }, {
        desc: ['串聯指標小於', ''],
        menu: [Number01001], id: '814'
    }, {
        desc: ['串聯指標昨天小於', '，今天大於'],
        menu: [Number01001, Number01001], id: '815'
    }, {
        desc: ['串聯指標昨天大於', '，今天小於'],
        menu: [Number01001, Number01001], id: '816'
    },
]


export const LAZY_8 = [
    { desc: ['分點券商買超天數（週）'], id: '389' },
    { desc: ['分點券商買超天數（2週）'], id: '390' },
    { desc: ['分點券商買超天數（月）'], id: '391' },
    { desc: ['分點券商買超天數（2月）'], id: '392' },
    { desc: ['分點券商連續買超（週）'], id: '393' },
    { desc: ['分點券商連續買超（2週）'], id: '394' },
    { desc: ['分點券商連續買超（月）'], id: '395' },
    { desc: ['分點券商連續買超（2月）'], id: '396' },
    { desc: ['分點券商買入異常'], id: '397' },
    { desc: ['分點券商賣出異常'], id: '404' },
    { desc: ['分點券商買賣異常'], id: '405' },
    {
        desc: ['連續', '三方指標的', '', '', ''],
        menu: [Day_5, MLN, BigSmall, Number0_100], id: '829'
    }, {
        desc: ['三方指標昨日', '小於', '，今日', '大於', ''],
        menu: [MLN, Number0_100, MLN, Number0_100], id: '830'
    }, {
        desc: ['三方指標近', '', '與', '發生', ''],
        menu: [Day_5, MLN, MLN, CrossW], id: '831'
    }, {
        desc: ['三方指標近', '', '與', '發生', '(含相等)'],
        menu: [Day_5, MLN, MLN, CrossW], id: '832'
    }, {
        desc: ['三方戰勝圖有', '日沒信號直到今日才有', '才有', '信號'],
        menu: [Day_5, Day_5, MLN], id: '833'
    }, {
        desc: ['', '三方指標', '', '', ''],
        menu: [Day_5, MLN, BigSmall, MLN], id: '835'
    }
]



export const LAZY_9 = [
    {
        desc: ['連續', '都向上衝刺'],
        menu: [Day_5], id: '498'
    }, {
        desc: ['連續', '都向下休息'],
        menu: [Day_5], id: '808'
    }, {
        desc: ['前', '衝刺值都', '', '，今日衝刺值', '', ''],
        menu: [Day_5, BigSmall, Percent0100, BigSmall, Percent0100], id: '499'
    }, {
        desc: ['', '前衝刺值', '', '，今日衝刺值', '', ''],
        menu: [Day_5, BigSmall, Percent0100, BigSmall, Percent0100], id: '800'
    }, {
        desc: ['衝刺指標TOP', ''],
        menu: [Num50_500], id: '488'
    }, {
        desc: ['衝刺指標大於', '小於', ''],
        menu: [Percent5_5_100, Percent5_5_100], id: '489'
    }, {
        desc: ['衝刺指標加速度TOP', ''],
        menu: [Num50_500], id: '490'
    }, {
        desc: ['最近', '趨勢線剛剛轉', ''],
        menu: [Day_5, UpDown1], id: '449'
    },
    { desc: ['目前轉折波為頭頭高底底高'], id: '450' },
    { desc: ['目前轉折波為頭頭高'], id: '848' },
    { desc: ['目前轉折波為底底高'], id: '849' },
    { desc: ['目前轉折波為頭頭低底底低'], id: '834' },
    { desc: ['目前轉折波為頭頭低'], id: '850' },
    { desc: ['目前轉折波為底底低'], id: '851' },

    { desc: ['日向上'], id: '373' },
    { desc: ['日高檔鈍化'], id: '374' },
    { desc: ['日高檔鈍化次數多'], id: '375' },
    { desc: ['週向上'], id: '376' },
    { desc: ['週高檔鈍化'], id: '377' },
    { desc: ['週高檔鈍化次數多'], id: '378' },
    { desc: ['月向上'], id: '379' },
    { desc: ['月高檔鈍化'], id: '380' },
    { desc: ['月高檔鈍化次數多'], id: '381' },
    { desc: ['日金叉'], id: '382' },
    { desc: ['週金叉'], id: '383' },
    { desc: ['月金叉'], id: '384' },
]


export const LAZY_10 = [
    {
        desc: ['最近', '股價曾接近', '', '價', '以內'],
        menu: [Day_20, SPDay, SP, Percent0_20], id: '483'
    }, {
        desc: ['最近', '股價都接近', '', '價', '以內'],
        menu: [Day_20, SPDay, SP, Percent0_20], id: '482'
    }, {
        desc: ['', '壓力價大於', '支撐價', '以上'],
        menu: [SPDay, SPDay, Percent0_20], id: '484'
    },
    { desc: ['高%b'], id: '342' },
    { desc: ['低%b'], id: '343' },
    { desc: ['帶寬大'], id: '344' },
    { desc: ['帶寬小'], id: '345' },
    { desc: ['開口漸大'], id: '346' },
    { desc: ['開口漸小'], id: '347' },
    { desc: ['5日乖離碰觸天花板'], id: '356' },
    { desc: ['5日乖離碰觸地板'], id: '357' },
    { desc: ['20日乖離碰觸天花板'], id: '358' },
    { desc: ['20日乖離碰觸地板'], id: '359' },
    {
        desc: ['近', '日寶塔線發生', ''],
        menu: [Day_5, GreenRed1], id: '817'
    }, {
        desc: ['近', '周寶塔線發生', ''],
        menu: [Week10, GreenRed1], id: '818'
    }, {
        desc: ['近', '月寶塔線發生', ''],
        menu: [Month12, GreenRed1], id: '819'
    },
]

export const LAZY_11 = [
    { desc: ['(買) 移動平均線平緩，價格突破'], id: '410' },
    { desc: ['(買) 移動平均線上升，價格下跌支撐'], id: '411' },
    { desc: ['(買) 移動平均線上升，價格假跌破'], id: '412' },
    { desc: ['(買) 移動平均線下降，價格跌深反彈'], id: '413' },
    { desc: ['(賣) 移動平均線上升，價格跌破'], id: '414' },
    { desc: ['(賣) 移動平均線下降，價格上升受阻'], id: '415' },
    { desc: ['(賣) 移動平均線下降，價格假突破'], id: '416' },
    { desc: ['(賣) 移動平均線上升，價格過高拉回'], id: '417' },
    {
        desc: ['最近', '發生九轉序列', '第', '轉以上'],
        menu: [Day_20, UpDown1, Number1_9], id: '480'
    }, {
        desc: ['最近', '發生九轉序列', '第', '轉'],
        menu: [Day_20, UpDown1, Number1_9], id: '481'
    }, {
        desc: ['新三價線最近', '根紅棒有發生反轉的買進訊號'],
        menu: [Num5], id: '485'
    }, {
        desc: ['新三價線最近', '根綠棒有發生反轉的賣出訊號'],
        menu: [Num5], id: '486'
    }, {
        desc: ['最近', '有發生反轉的', '訊號'],
        menu: [Day_5, BSSingle], id: '487'
    }, {
        desc: ['', '', '期望指標連續', '', '', ''],
        menu: [Num51, DWM, Day_5, BigSmall, NumberN1000], id: '820'
    }, {
        desc: ['', '', '期望指標連續', '', '', ''],
        menu: [Num51, DWM, Day_5, BigSmall, NumberN1000], id: '821'
    }, {
        desc: ['', '', '期望指標', '前', '', ''],
        menu: [Num51, DWM, Day_5, BigSmall, NumberN1000], id: '822'
    }, {
        desc: ['', '', '期望指標', '前', '', ''],
        menu: [Num51, DWM, Day_5, BigSmall, NumberN1000], id: '823'
    }, {
        desc: ['近', '內發生', '日與', '日期望指標', ''],
        menu: [Day_5, Num51, Num51, CrossW], id: '824'
    }, {
        desc: ['', '', '期望指標連續', '', ''],
        menu: [Num51, DWM, Day_5, AddSub], id: '825'
    }, {
        desc: ['', '', '期望指標連續', '', ''],
        menu: [Num51, DWM, Day_5, AddSub], id: '826'
    }, {
        desc: ['', '', '期望指標', '', '', ''],
        menu: [Num51, DWM, BigSmall, Num51, DWM], id: '827'
    },
]


export const LAZY_12 = [
    {
        desc: ['連續', '的', '支壓柱都是', ''],
        menu: [Day_5, Day_51, GreenRed], id: '845'
    }, {
        desc: ['連續', '的', '支壓柱逐漸', ''],
        menu: [Day_5, Day_51, AddSub], id: '846'
    }, {
        desc: ['近', '發生', '支壓柱', ''],
        menu: [Day_5, Day_51, GreenRed1], id: '847'
    }, {
        desc: ['近', '的', '支壓柱大於', '小於'],
        menu: [Day_5, Day_51, EmptyMenu, EmptyMenu], id: '852'
    },
    {
        desc: ['近', '營收', '幅度大於', '，股價', '幅度', '以上'],
        menu: [Month24, GrothBack, Percent0100, GrothBack, Percent0100], id: '837'
    }, {
        desc: ['近', 'EPS', '幅度大於', '，股價', '幅度', '以上'],
        menu: [SeasonAll1, GrothBack, Percent0100, GrothBack, Percent0100], id: '838'
    }, {
        desc: ['近', '營收', '幅度', '到', ''],
        menu: [Month24, GrothBack, Percent0100, Percent0100], id: '839'
    }, {
        desc: ['近', 'EPS', '幅度', '到', ''],
        menu: [SeasonAll1, GrothBack, Percent0100, Percent0100], id: '840'
    }, {
        desc: ['近', '股價', '幅度', '到', ''],
        menu: [Month24, GrothBack, Percent0100, Percent0100], id: '841'
    }, {
        desc: ['', '', '', ''],
        menu: [RiskDay, RiskLevel, RiskMoney], id: '441'
    }, {
        desc: ['', '', '', ''],
        menu: [RiskDay, RiskLevel, RiskMoney], id: '440'
    },
    { desc: ['20日高風險,高報酬'], id: '418' },
    { desc: ['20日中風險,高報酬'], id: '419' },
    { desc: ['20日低風險,高報酬'], id: '420' },
    { desc: ['60日高風險,高報酬'], id: '421' },
    { desc: ['60日中風險,高報酬'], id: '422' },
    { desc: ['60日低風險,高報酬'], id: '423' },
    { desc: ['120日高風險,高報酬'], id: '424' },
    { desc: ['120日中風險,高報酬'], id: '425' },
    { desc: ['120日低風險,高報酬'], id: '426' },
    { desc: ['240日高風險,高報酬'], id: '427' },
    { desc: ['240日中風險,高報酬'], id: '428' },
    { desc: ['240日低風險,高報酬'], id: '429' }
]


export const LAZY_13 = [
    {
        desc: ['近', '的K線預測圖', '，', '後預測價', '今日價格', '以上'],
        menu: [Day_5, Action1, Action1, BigSmall, Percent0100], id: '836'
    },
    { desc: ['主力異常買超'], id: '350' },
    { desc: ['法人與外資異常買超'], id: '351' },
    { desc: ['投信異常買超'], id: '352' },
    { desc: ['日成交量異常爆量'], id: '353' },
    { desc: ['週成交量異常爆量'], id: '354' },
    { desc: ['股價落底盤整中'], id: '229' },
    { desc: ['盤中現股與零股(成交價)溢價排行榜'], id: '443' },
    { desc: ['盤中現股與零股(成交價)折價排行榜'], id: '442' },
    { desc: ['盤中現股與零股(委買價)溢價排行榜'], id: '445' },
    { desc: ['盤中現股與零股(委賣價)折價排行榜'], id: '444' },
]


export const LAZY_1_IDS = LAZY_1.map(i => { return i.id })
export const LAZY_2_IDS = LAZY_2.map(i => { return i.id })
export const LAZY_3_IDS = LAZY_3.map(i => { return i.id })
export const LAZY_4_IDS = LAZY_4.map(i => { return i.id })
export const LAZY_5_IDS = LAZY_5.map(i => { return i.id })
export const LAZY_6_IDS = LAZY_6.map(i => { return i.id })
export const LAZY_7_IDS = LAZY_7.map(i => { return i.id })
export const LAZY_8_IDS = LAZY_8.map(i => { return i.id })
export const LAZY_9_IDS = LAZY_9.map(i => { return i.id })
export const LAZY_10_IDS = LAZY_10.map(i => { return i.id })
export const LAZY_11_IDS = LAZY_11.map(i => { return i.id })
export const LAZY_12_IDS = LAZY_12.map(i => { return i.id })
export const LAZY_13_IDS = LAZY_13.map(i => { return i.id })