import React, { useState, useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AllPrice from './AllPrice';
import BasicDetail from './BasicDetail'
import NetValue from './NetValue'
import ChangeRate from './ChangeRate'
import { getId } from '../util/ID'
import HistoryCB from './HistoryCB';
import { Favorite, FavoriteBorder } from '@mui/icons-material';
// import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
// import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function DetailDialog1(props) {
    const [open, setOpen] = React.useState(false);
    const [value, setValue] = React.useState(0);

    const [curStock, setCurStock] = React.useState(null)
    const [enPrex, setEnPrex] = React.useState(true);
    const [enNext, setEnNext] = React.useState(true);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        if (props.close != null) props.close(false)
    };

    useEffect(() => {
        if (props.open) setOpen(true)
        else setOpen(false)
    }, [props.open]);

    // useEffect(() => {
    //     if (props.open && props.bid != null) {
    //         setOpen(true)

    //         if (props.allIds != null) {
    //             const index = props.allIds.findIndex(id => id === props.bid);
    //             if (index === 0) setEnPrex(false); else setEnPrex(true)
    //             if (index === props.allIds.length - 1) setEnNext(false); else setEnNext(true)
    //         } else {
    //             setEnPrex(false)
    //             setEnNext(false)
    //         }

    //         setCurStock(props.bid)
    //         // fetchData(props.bid);
    //     } else {
    //         setOpen(false)
    //     }
    // }, [props.bid, props.open]);

    // const handleNext = () => {
    //     if (props.allIds != null) {
    //         const index = props.allIds.findIndex(id => id === curStock);
    //         if (index != -1) {
    //             if (index + 1 < props.allIds.length) {
    //                 let newStockNo = props.allIds[index + 1]
    //                 setCurStock(newStockNo)
    //                 // fetchData(newStockNo)

    //                 if (index + 1 === props.allIds.length - 1) {
    //                     setEnNext(false)
    //                 } else {
    //                     setEnNext(true)
    //                 }
    //                 setEnPrex(true)
    //             } else {
    //                 //最後一頁
    //             }
    //         } else {
    //             //無此筆資料
    //         }
    //     }
    // }

    // const handlePrex = () => {
    //     if (props.allIds != null) {
    //         const index = props.allIds.findIndex(id => id === curStock);
    //         if (index != -1) {
    //             if (index - 1 >= 0) {
    //                 let newStockNo = props.allIds[index - 1]
    //                 setCurStock(newStockNo)
    //                 // fetchData(newStockNo)

    //                 if (index - 1 === 0) {
    //                     setEnPrex(false)
    //                 } else setEnPrex(true)

    //                 if (index - 1 === props.allIds.length - 1) {
    //                     setEnNext(false)
    //                 } else {
    //                     setEnNext(true)
    //                 }

    //             } else {
    //                 //最後一頁
    //             }
    //         } else {
    //             //無此筆資料
    //         }
    //     }
    // }

    const clickLove = () => {
        let param = {
            sn: getId(),
            sid: props.row.cb2.bid,
            tp: props.tp,
            froms: props.menu
        }

        if (localStorage.getItem("lic") != null) {
            param['lic'] = localStorage.getItem("lic")
        }

        fetch("/ocb/v1/stock2/cb/clicklove", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result != null && result.status === 0) {
                        if (props.updateLove != null) props.updateLove(props.row.cb2.bid, props.tp)
                        handleClose()
                    }
                }
            );
    }

    return (
        <div>
            {open && <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar elevation={1} color="default" sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            {props.row.cb2.sname}  {props.row.cb2.bid}
                            {/* <Typography variant="caption" sx={{ fontWeight: 'normal' }} >{props.row.cb2.bid}</Typography> */}
                        </Typography>
                        {/* {enPrex ? <KeyboardArrowLeftIcon style={{ color: "#707070" }} fontSize="large" onClick={handlePrex} /> :
                            <KeyboardArrowLeftIcon color="disabled" fontSize="large" onClick={handlePrex} />}

                        {enNext ? <KeyboardArrowRightIcon style={{ color: "#707070" }} fontSize="large" onClick={handleNext} /> :
                            <KeyboardArrowRightIcon color="disabled" fontSize="large" onClick={handleNext} />} */}

                        {props.row.islv? <Button startIcon={<FavoriteBorder></FavoriteBorder>} disableElevation variant='contained'   onClick={clickLove}>
                            移除最愛
                        </Button>: <Button startIcon={<FavoriteBorder></FavoriteBorder>} disableElevation variant='contained'  onClick={clickLove}>
                            加入最愛
                        </Button>}
                    </Toolbar>
                </AppBar>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                >
                    <Tab label="基本資料" />
                    <Tab label="價格與成交量" />
                    <Tab label="轉換價值" />
                    <Tab label="轉換率" />
                    <Tab label="歷史年成交統計 (VIP)" />
                </Tabs>

                {value === 0 && <BasicDetail tp={props.tp} menu={props.menu} row={props.row} />}
                {value === 1 && <AllPrice tp={props.tp} menu={props.menu} row={props.row} />}
                {value === 2 && <NetValue tp={props.tp} menu={props.menu} row={props.row} />}
                {value === 3 && <ChangeRate tp={props.tp} menu={props.menu} row={props.row} />}
                {value === 4 && <HistoryCB tp={props.tp} menu={props.menu} row={props.row} />}
            </Dialog>}
        </div>
    );
}
