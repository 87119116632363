import React, { useEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { Lightbulb, PropaneTwoTone } from '@mui/icons-material';
import FavoriteBorderOutlined from '@mui/icons-material/FavoriteBorderOutlined'
import Favorite from '@mui/icons-material/Favorite'
import { getId } from '../util/ID'

import Avatar from '@mui/material/Avatar';
import { deepOrange, green } from '@mui/material/colors';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import GoodPriceChart from '../pic/GoodPriceChart'
import DetailDialog from '../detail/DetailDialog1'
import RecBase from './RecBase'
import ButtonGroup from '@mui/material/ButtonGroup';

export default function RecItem(props) {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);


    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openSetting, setOpenSetting] = React.useState(false);

    const openMenu = Boolean(anchorEl);

    
    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };


    const clickLove = () => {
        let param = {
            sn: getId(),
            sid: props.row.cb2.bid,
            tp: props.tp,
            froms: props.menu
        }

        if (localStorage.getItem("lic") != null) {
            param['lic'] = localStorage.getItem("lic")
        }

        fetch("/ocb/v1/stock2/cb/clicklove", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result != null && result.status === 0) {
                        // if(props.refresh != null) props.refresh()
                        if (props.updateLove != null) props.updateLove(props.row.cb2.bid, props.tp)
                    }
                }
            );
    }

    const handleMenuChange = (newValue) => {
        setAnchorEl(null);
        setOpenSetting(true)
    };


    const getOvrate = () => {
        return (((props.row.cb2.bprice / props.row.cb2.gprice) - 1) * 100).toFixed(2)
    }


    return (
        <Stack spacing={0} sx={{ m: 1, mb: 0.5, p: 0, border: 2, borderColor: theme.palette.mode === "light" ? "#c1b5c7" : theme.palette.secondary.main, boxShadow: 0.5, borderRadius: '0px' }}>
            {open && <DetailDialog updateLove={props.updateLove} tp={props.tp} menu={props.menu} row={props.row} close={setOpen} open={open} />}

            <Box display={'flex'}>
                <Stack direction='row' spacing={0.5} sx={{ flex: 1, p: 0, pl: 1, pt: 0.5, alignSelf: 'center' }} >
                    {props.row.islv ? <Favorite color="error" onClick={e => clickLove()} /> :
                        <FavoriteBorderOutlined onClick={e => clickLove()} />}
                    <Typography sx={{ fontSize: 30, fontWeight: 'bold', alignSelf: 'center' }} onClick={e => setOpen(true)}>{props.row.cb2.sname}</Typography>
                    <Typography variant="caption" sx={{ fontWeight: 'normal' }} onClick={e => setOpen(true)}>{props.row.cb2.bid}</Typography>
                </Stack>
            </Box>

            <Divider sx={{ bgcolor: (theme) => theme.palette.mode === "light" ? "#c1b5c7" : theme.palette.secondary.main }}
                style={{
                    border: "none",
                    height: 0.5,
                    margin: 0,
                }} />

            <RecBase row={props.row} menu={props.menu} setOpen={setOpen} />

            <Divider sx={{ bgcolor: (theme) => theme.palette.mode === "light" ? "#c1b5c7" : theme.palette.secondary.main }}
                style={{
                    border: "none",
                    height: 0.5,
                    margin: 0,
                }} variant="middle" />

            {props.row != null && <GoodPriceChart  row={props.row}  setOpen={setOpen}/>}

        </Stack>

    );
}
