import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Typography } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { blueGrey } from '@mui/material/colors';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: blueGrey['300'],
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

function createData(name, value) {
    return { name, value };
}


export default function BasicDetail(props) {



    const rows = [
        createData('可轉債名稱', props.row.cb2.sname),
        createData('發行日', props.row.cb2.issue_date),
        createData('到期日', props.row.cb2.expire_date),
        createData('發行總額', props.row.info != null ? props.row.info.oriisu / 1000000 + "百萬元" : ""),
        createData('發行餘額', props.row.info != null ? props.row.info.rest / 1000000 + "百萬元": ""),
        createData('最新轉換生效日', props.row.cb2.cdate),
        createData('轉換價', props.row.cb2.cprice + "元"),
        createData('轉換比例', props.row.info != null ? (((props.row.info.oriisu - props.row.info.rest) * 100) / props.row.info.oriisu).toFixed(2) + "%": ""),
        createData('擔保', props.row.cb2.guarant ? "有" : "無"),
        createData('權利金百元報價(台新)', props.row.cb2.cbas != null ? (props.row.cb2.cbas.premium100).toFixed(2) + "元" : "無"),
        createData('CBAS權利金', props.row.cb2.cbas != null ? (props.row.cb2.cbas.premium).toFixed(2) + "元" : "無"),
        createData('CBAS折現率', props.row.cb2.cbas != null ? (props.row.cb2.cbas.rate).toFixed(2) + "%" : "無"),
    ];

    const rows1 = [
        createData('最新CB收盤價', props.row.cb2.bprice + "元"),
        createData('最新股票收盤價', props.row.cb2.sprice + "元"),
        createData('轉換價值', props.row.cb2.gprice + "元"),
        createData('折溢價%', (((props.row.cb2.bprice / props.row.cb2.gprice) - 1) * 100).toFixed(2) + "%"),
        createData('最新賣回日', props.row.cb2.sellbackd != "0" ? props.row.cb2.sellbackd : "無"),
        createData('最新賣回價格', props.row.cb2.sellbackp != 0 ? props.row.cb2.sellbackp + "元" : "無"),
        createData('強制贖回起日', props.row.info != null && props.row.info.sreturnd != "" ? props.row.info.sreturnd : "無"),
        createData('強制贖回迄日', props.row.info != null && props.row.info.ereturnd != "" ? props.row.info.ereturnd : "無"),
        createData('強制贖回價格', props.row.info != null && props.row.info.returnp != 0 ? props.row.info.returnp + "元" : "無"),
        createData('公開說明書', <Button 
        onClick={() => {
            window.open('https://doc.twse.com.tw/server-java/t57sb01?step=1&colorchg=1&co_id='+ props.row.cb2.cid + '&year=&seamon=&mtype=B&price', '')
        }}
        sx={{minWidth: 100}} disableElevation variant="contained">連結</Button>),
    
    ];

    return (
        // <Box sx={{ width: '100%'}}>
        <Grid container spacing={2} sx={{ p: 2 }} >
            <Grid item xs={12} sm={6}>
                <TableContainer component={Paper} elevation={3}>
                    <Table sx={{}} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell><Typography variant="body1" sx={{ fontWeight: "bold" }} >名稱</Typography></StyledTableCell>
                                <StyledTableCell align="left"><Typography variant="body1" sx={{ fontWeight: "bold" }} >值</Typography></StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <TableRow
                                    key={row.name}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        <Typography variant="body1" sx={{ fontWeight: "bold" }} >{row.name}</Typography>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography variant="body1" sx={{}} >{row.value}</Typography>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid item xs={12} sm={6}>
                <TableContainer component={Paper} elevation={1}>
                    <Table sx={{}} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell><Typography variant="body1" sx={{ fontWeight: "bold" }} >名稱</Typography></StyledTableCell>
                                <StyledTableCell align="left"><Typography variant="body1" sx={{ fontWeight: "bold" }} >值</Typography></StyledTableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows1.map((row) => (
                                <TableRow
                                    key={row.name}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        <Typography variant="body1" sx={{ fontWeight: "bold" }} >{row.name}</Typography>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography variant="body1" sx={{}} >{row.value}</Typography>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
        // </Box>
    );
}
