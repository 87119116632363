import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
// import ReactEChartsCore from 'echarts-for-react/lib/core';
// import * as echarts from 'echarts/core';
// import { LineChart, BarChart, CandlestickChart } from 'echarts/charts';
// import {
//     GridComponent,
//     TooltipComponent,
//     TitleComponent,
//     TimelineComponent,
//     MarkPointComponent,
//     MarkLineComponent,
//     MarkAreaComponent,
//     LegendComponent,
//     DataZoomComponent,
//     DataZoomInsideComponent,
//     DataZoomSliderComponent,
//     VisualMapComponent,
//     VisualMapContinuousComponent,
//     VisualMapPiecewiseComponent,
// } from 'echarts/components';
// import {
//     CanvasRenderer,
// } from 'echarts/renderers';
import { getMobileTooltip, getComputerTooltip, getAxisPointer, getMobileDataZoom, getComputerDataZoom } from '../util/GridA'
import useMediaQuery from '@mui/material/useMediaQuery';

// echarts.use(
//     [VisualMapComponent, VisualMapContinuousComponent, VisualMapPiecewiseComponent, TitleComponent, TooltipComponent, GridComponent, LineChart, CandlestickChart, CanvasRenderer, DataZoomComponent,
//         DataZoomInsideComponent, DataZoomSliderComponent, MarkPointComponent, MarkLineComponent, TimelineComponent,
//         LegendComponent, MarkAreaComponent, BarChart]
// );

import ReactECharts from 'echarts-for-react'
import { grey } from '@mui/material/colors';
import { Typography } from '@mui/material';

const useStyles = makeStyles(theme => ({
    table: {
        // minWidth: 650,
    },
    root1: {
        flexGrow: 1,
    },
    menuButton: {
        // marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    grid: {
        // width: `calc(100% + ${6 + (theme.spacing(1))}px)`
    }
}));



export default function ChangeRate(props) {
    const classes = useStyles();
    const theme = useTheme();

    const [option, setOption] = React.useState(null);
    const [result, setResult] = React.useState(null);
    const [result1, setResult1] = React.useState(null);
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const getStart = () => {
        if (result != null) {
            let k = result.length / 4
            if (k >= 100) return 75
            else return k
        } else return 0
    }


    function genoption1() {
        let k = {
            animation: false,
            grid: [{
                top: "7%",
                left: 5,
                right: 5,
                height: 250,
                // bottom: 5,
                containLabel: true
            }, {
                top: 330,
                left: 5,
                right: 5,
                // bottom: "7%",
                height: 100,
                containLabel: true
            }],
            legend: {
                data: ['已轉換%', '轉換數量(張)'],
                right: 0,
                icon: 'roundRect',
                formatter: function (name) {
                    return name
                    // let value = result[result.length - 1].v1
                    // if(name === '債券') {
                    //     value = result[result.length - 1].v2
                    // } else if(name === '折溢') {
                    //     value = result[result.length - 1].v3
                    // }
                    // return name + " : " + value
                }
                // ...
            },

            yAxis: [{
                scale: true,
                type: 'value',
                position: 'left',
                show: true,
                splitLine: {
                    show: true,
                    lineStyle: {
                        opacity: 0.2
                    }
                },
                gridIndex: 0,
                axisLabel: {
                    inside: true,
                    margin: 0
                },
            }, {
                scale: true,
                type: 'value',
                position: 'left',
                show: true,
                splitLine: {
                    show: true,
                    lineStyle: {
                        opacity: 0.2
                    }
                },
                gridIndex: 1,
                axisLabel: {
                    inside: true,
                    margin: 0
                },
            }],
            series: [
                {
                    name: '已轉換%',
                    type: 'bar',
                    data: (result.concat(result1)).map(v => ({
                        value: [v.x, v.v1],
                        itemStyle: {
                            color: v.x.length > 6 ? grey[300] : null,
                        }
                    })),
                    smooth: true,
                    showSymbol: false,
                    xAxisIndex: 0,
                    yAxisIndex: 0,
                },
                {
                    name: '轉換數量(張)',
                    type: 'bar',
                    data: (result.concat(result1)).map(v => v.v2),
                    showSymbol: false,
                    xAxisIndex: 1,
                    yAxisIndex: 1,
                },
            ]
        };
        let p = {
            type: 'line',
            label: { show: false },
            triggerTooltip: true,
            handle: {
                show: true,
                size: 22
            }
        }

        let x1 = {
            type: 'category',
            boundaryGap: true,
            data: (result.concat(result1)).map(v => v.x),
            gridIndex: 0,
        }

        let x2 = {
            type: 'category',
            boundaryGap: true,
            data: (result.concat(result1)).map(v => v.x),
            gridIndex: 1,
        }

        if (fullScreen) {
            k['tooltip'] = getMobileTooltip()
            k['axisPointer'] = getAxisPointer()
            k['dataZoom'] = getMobileDataZoom(getStart(), 100)

            x1['axisPointer'] = p
            x2['axisPointer'] = p
        } else {
            k['tooltip'] = getComputerTooltip()
            k['axisPointer'] = getAxisPointer()
            k['dataZoom'] = getComputerDataZoom(getStart(), 100, 470)
        }

        k['xAxis'] = [x1, x2]
        return k;
    }

    const fetchData = () => {
        let param = {
            bid: props.row.cb2.bid
        }

        fetch("/ocb/v1/stock3/changerate1", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result != null) {
                        setResult(result.r1)
                        setResult1(result.r2)
                    }
                }
            );
    }

    useEffect(() => {
        if (result != null && result.length > 0) setOption(genoption1)
    }, [result]);

    useEffect(() => {
        // setOption(genoption1)
        if (props.row != null) fetchData()
    }, [props.row]);



    return (
        <div style={{ padding: 0 }} >
            <Typography variant="caption" sx={{m: 2}}>灰色是利用每周集保戶股權分散表中總股數的增加來預估最新的轉換率與轉換張數</Typography>
            {option != null ?
                <ReactECharts
                    theme={theme.palette.mode}
                    option={option}
                    notMerge={true}
                    lazyUpdate={false}
                    style={{ height: 500,marginTop: 10 }}
                />
                // <ReactEChartsCore
                //     theme={theme.palette.mode}
                //     echarts={echarts}
                //     option={option}
                //     notMerge={true}
                //     lazyUpdate={false}
                //     style={{ height: 500 }}
                // />
                : null}
        </div>
    );
}

