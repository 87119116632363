import * as React from 'react';
import { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import ColorModeContext from '../util/ColorMode'
import { Height } from '../util/ScreenHeight'
import Stack from '@mui/material/Stack';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import SearchCompany from '../love/SearchCompany';
import DetailDialog2 from '../detail/DetailDialog2'
import AdUnitsIcon from '@mui/icons-material/AdUnits';
export default function CHeader(props) {
    const [open, setOpen] = React.useState(false);
    const [sid, setSID] = React.useState(null);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [anchorEl, setAnchorEl] = React.useState(null);

    const openMenu = Boolean(anchorEl);

    const colorMode = React.useContext(ColorModeContext);
    // theme.palette.primary.main

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        if (props.setMenu != null) {
            if (newValue === 0) props.setMenu(3)
            else if (newValue === 1) props.setMenu(2)
            else if (newValue === 2) props.setMenu(0)
            else if (newValue === 3) props.setMenu(4)
            else if (newValue === 4) props.setMenu(11)
            else if (newValue === 5) props.setMenu(7)
            else props.setMenu(0)
        }
        setValue(newValue);
    };

    useEffect(() => {
        if (props.menu != null) {
            if (props.menu === 3) setValue(0)
            else if (props.menu === 2) setValue(1)
            else if (props.menu === 0) setValue(2)
            else if (props.menu === 11) setValue(4)
            else if (props.menu === 7) setValue(5)
            else if (props.menu === 4) setValue(3)
            else setValue(2)
        }
    }, [props.menu]);

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleMenuChange = (newValue) => {
        setAnchorEl(null);
        if (props.setMenu != null) {
            if (newValue === 0) props.setMenu(3)
            else if (newValue === 1) props.setMenu(2)
            else if (newValue === 2) props.setMenu(0)
            else if (newValue === 3) props.setMenu(4)
            else if (newValue === 4) props.setMenu(11)
            else if (newValue === 5) props.setMenu(7)
            else props.setMenu(0)
        }
        setValue(newValue);
    };

    // useEffect(() => {
    //     if (sid != null) setOpen(true)
    //     else setOpen(false)
    // }, [sid]);

    const searchChange = (sid) => {
        setSID(sid)
        setOpen(true)
    }


    const changeScreenStyle = () => {
        let a = localStorage.getItem("screen")
        if(a == null || a === "0") {
            localStorage.setItem("screen", "1")
        } else {
            localStorage.setItem("screen", "0")
        }
        window.location.reload(false);
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            {open && <DetailDialog2 updateLove={props.updateLove} tp={props.tp} menu={props.menu} bid={sid} close={setOpen} open={open} />}

            <AppBar color="transparent" position="static" elevation={0} sx={{ height: Height.header, maxHeight: Height.header }}>
                <Toolbar style={{}}>
                    {/* <Box sx={{ flexGrow: 1 }}> */}
                    <Stack direction={"row"} sx={{ flexGrow: 1 }}>
                        <img src="fidget-spinner.png" style={{ width: 42, height: 42 }} />
                        {!fullScreen && <Typography variant="h5" sx={{ alignSelf: 'center', ml: 1, fontWeight: 'bold', whiteSpace: "nowrap" }}>可轉債挖土機</Typography>}
                    </Stack>
                    {/* </Box> */}

                    {!fullScreen && <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="首頁" value={0} />
                        <Tab label="策略" value={1} />
                        {/* <Tab label="技術燈" value={2}/> */}
                        {/* {localStorage.getItem("osc_debug") != null && <Tab label="券商燈" value={3}/>} */}
                        {/* <Tab label="券商燈" value={3}/> */}
                        <Tab label="自選股" value={4} />
                        <Tab label="購買" value={5} />
                    </Tabs>}

                    {!fullScreen ? <Box flexGrow={1}  sx={{mb:1, ml: 1}} >
                        <SearchCompany  tp={1} searchChange={searchChange}/>
                    </Box>: <Box flexGrow={1}  sx={{mb:0, ml: 1, width: "100%"}} >
                        <SearchCompany  tp={1} searchChange={searchChange}/>
                    </Box>}

                    <IconButton sx={{ ml: 1 }} onClick={colorMode.toggleColorMode} color="inherit">
                        {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon sx={{ color: "#000000" }} />}
                    </IconButton>

                    <IconButton sx={{ ml: 0 }} onClick={e =>changeScreenStyle()} color="inherit">
                        {theme.palette.mode === 'dark' ? <AdUnitsIcon /> : <AdUnitsIcon sx={{ color: "#000000" }} />}
                    </IconButton>



                    {fullScreen && <IconButton sx={{}} onClick={e => handleMenuClick(e)}>
                        <MoreVertIcon />
                    </IconButton>}
                    
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={openMenu}
                        onClose={handleMenuClose}
                    >
                        <MenuItem onClick={e => handleMenuChange(0)}>首頁</MenuItem>
                        <MenuItem onClick={e => handleMenuChange(1)}>策略</MenuItem>
                        {/* <MenuItem onClick={e => handleMenuChange(2)}>技術燈</MenuItem> */}
                        {/* {localStorage.getItem("osc_debug") != null && <MenuItem onClick={e => handleMenuChange(3)}>券商燈</MenuItem>} */}
                        {/* <MenuItem onClick={e => handleMenuChange(3)}>券商燈</MenuItem> */}
                        <MenuItem onClick={e => handleMenuChange(4)}>自選股</MenuItem>
                        <MenuItem onClick={e => handleMenuChange(5)}>購買</MenuItem>
                    </Menu>

                </Toolbar>
            </AppBar>
        </Box>
    );
}
