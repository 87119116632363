import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { grey, red, green } from '@mui/material/colors'
import { useTheme } from '@mui/material/styles';
import { getId } from '../util/ID'
import FavoriteBorderOutlined from '@mui/icons-material/FavoriteBorderOutlined'
import Favorite from '@mui/icons-material/Favorite'
import DetailDialog from '../detail/DetailDialog1'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.black,
    backgroundColor: grey[200],
    color: "#000000",
    // color: theme.palette.common.white,
    whiteSpace: "nowrap"
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    whiteSpace: "nowrap"
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  // '&:last-child td, &:last-child th': {
  //   border: 0,
  // },
}));


export default function CBLoveTables(props) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [row, setRow] = React.useState(null);

  const getOvrate = (row) => {
    if (row.cb2 != null)
      return (((row.cb2.bprice / row.cb2.gprice) - 1) * 100).toFixed(2)
    else return "-"
  }

  const getRateStyle = (row) => {
    let k = getOvrate(row)
    if (k > 10 || k < -3) return "contained"
    else return "outlined"
  }

  const getColor = (row) => {
    if (row != null && row.sprice != null) {
      if (row.sprice.i > 0) return red[400]
      else if (row.sprice.i < 0) return green[400]
      else return null
    } else return null
  }


  const getInv = (row) => {
    if (row != null && row.sprice != null) {
      let pp = ((row.sprice.i * 100) / (row.sprice.p - row.sprice.i)).toFixed(1)

      if (row.sprice.i > 0) return "▲" + row.sprice.i + '元 (' + pp + '%)'
      else if (row.sprice.i < 0) return "▼" + row.sprice.i + '元 (' + pp + '%)'
      else return row.sprice.i + '元' + ' (' + pp + '%)'
    } else return '▲0元'
  }


  const clickLove = (bid, froms, tp) => {
    let param = {
      sn: getId(),
      sid: bid,
      tp: tp,
      froms: froms
    }

    if (localStorage.getItem("lic") != null) {
      param['lic'] = localStorage.getItem("lic")
    }

    fetch("/ocb/v1/stock2/cb/clicklove", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(param)
    })
      .then(res => res.json())
      .then(
        result => {
          if (result != null && result.status === 0) {
            // if(props.refresh != null) props.refresh()
            if (props.updateLove != null) props.updateLove(bid, tp)
          }
        }
      );
  }

  const openDetail = (row) => {
    setRow(row)
    setOpen(true)
  }
  const rateColor = (row) => {
    let k = ((row.info.oriisu - row.info.rest) * 100) / row.info.oriisu
    if (k > 50) return "error"
    else return "inherit"
  }

  const rateVariant = (row) => {
    let k = ((row.info.oriisu - row.info.rest) * 100) / row.info.oriisu
    if (k > 80) return "contained"
    else if(k > 50) return "outlined"
    else return "text"
  }

  return (
    <>
      {open && row != null && <DetailDialog updateLove={props.updateLove} tp={row.cbl.tp} menu={row.cbl.froms} row={row} close={setOpen} open={open} />}

      <TableContainer sx={{ maxHeight: "100%" }}>
        <Table stickyHeader size='small' sx={{}} >
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{ borderRight: "1px solid grey", zIndex: 999, position: "sticky", left: 0, bgcolor: grey[200] }}>名稱</StyledTableCell>
              <StyledTableCell align="center">折溢價</StyledTableCell>
              <StyledTableCell align="right">CB收盤價</StyledTableCell>
              <StyledTableCell align="right">轉換價值</StyledTableCell>


              <StyledTableCell align="center">轉換率</StyledTableCell>
              <StyledTableCell align="right">股票價格</StyledTableCell>
              <StyledTableCell align="right">轉換價</StyledTableCell>
              <StyledTableCell align="center">剩餘年限</StyledTableCell>

              <StyledTableCell align="right">權利金百元報價</StyledTableCell>
              <StyledTableCell align="center">買回日</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.result.map((row) => (
              <StyledTableRow key={row.cb2.bid}>
                <StyledTableCell component="th" scope="row" sx={{ borderRight: "1px solid grey", zIndex: 998, position: "sticky", left: 0, bgcolor: theme.palette.mode === "light" ? grey[200] : grey[900], color: theme.palette.mode === "light" ? "#000000" : "#FFFFFF" }}>
                  <Stack direction={"row"} spacing={0.2} onClick={e => clickLove(row.cb2.bid, row.cbl.froms, row.cbl.tp)}>
                    {row.islv ? <Favorite fontSize='12' color="error" /> :
                      <FavoriteBorderOutlined fontSize='12' />}

                    <Stack direction={"column"} >
                      <Typography fontSize={12}>{row.cb2.sname}</Typography>
                      <Typography fontSize={12}>{row.cb2.bid}</Typography>
                    </Stack>
                  </Stack>
                </StyledTableCell>
                <StyledTableCell align="center" onClick={e => openDetail(row)}>
                  <Button size='small' fullWidth disableElevation variant={getRateStyle(row)} color={row.cb2 != null && row.cb2.bprice > row.cb2.gprice ? "error" : "success"}>{getOvrate(row)}%</Button>
                </StyledTableCell>
                <StyledTableCell align="right" onClick={e => openDetail(row)}>{row.cb2.bprice}元</StyledTableCell>
                <StyledTableCell align="right" onClick={e => openDetail(row)}>{row.cb2.gprice}元</StyledTableCell>


                <StyledTableCell align="center" onClick={e => openDetail(row)}>
                  <Button size='small' fullWidth disableElevation  variant={rateVariant(row)} color={rateColor(row)}>{(((row.info.oriisu - row.info.rest) * 100) / row.info.oriisu).toFixed(1)}%</Button>
                </StyledTableCell>
                <StyledTableCell align="right" onClick={e => openDetail(row)}>
                  <Typography fontSize={14} color={getColor(row)}>{row.sprice != null ? row.sprice.p : "-"}元</Typography>
                  <Typography fontSize={12} color={getColor(row)}>{getInv(row)}</Typography>
                </StyledTableCell>


                <StyledTableCell align="right" onClick={e => openDetail(row)}>{row.cb2.cprice}元</StyledTableCell>                
                <StyledTableCell align="center" onClick={e => openDetail(row)} >{(row.cb2.expiredays / 365).toFixed(2)}年</StyledTableCell>
                <StyledTableCell align="right" onClick={e => openDetail(row)}>{row.cb2.cbas != null && row.cb2.cbas.premium100 != null ? row.cb2.cbas.premium100.toFixed(2) : "-"}元</StyledTableCell>
                <StyledTableCell align="center" onClick={e => openDetail(row)}>{row.cb2.expire_date}</StyledTableCell>

              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
