
export const uuidv4 = () => {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}

export const getId = () => {
    if(localStorage.getItem("xuid") == null) {
        let id = uuidv4()
        localStorage.setItem("xuid", id)
        return id
    } else return localStorage.getItem("xuid")
}

export const makeid1 = (length) => {
    var result = '';
    var characters = 'ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnpqrstuvwxyz23456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export const getLic = () => {
    if(localStorage.getItem("lica") == null) {
        let id = makeid1(6)
        localStorage.setItem("lica", id)
        return id
    } else return localStorage.getItem("lica")
}

export const putInpQueryParam = (a) => {
    if (localStorage.getItem('free_y1') != null && localStorage.getItem('free_y1') != "5")
        a.inp["y1"] = parseInt(localStorage.getItem('free_y1'))
    if (localStorage.getItem('free_hr1') != null && localStorage.getItem('free_hr1') != 6.6)
        a.inp["hr1"] = parseFloat(localStorage.getItem('free_hr1'))
    if (localStorage.getItem('free_mr1') != null && localStorage.getItem('free_mr1') != 5)
        a.inp["mr1"] = parseFloat(localStorage.getItem('free_mr1'))
    if (localStorage.getItem('free_lr1') != null && localStorage.getItem('free_lr1') != 3.3)
        a.inp["lr1"] = parseFloat(localStorage.getItem('free_lr1'))
    if (localStorage.getItem('free_y') != null && localStorage.getItem('free_y') != "5")
        a.inp["y"] = parseInt(localStorage.getItem('free_y'))
    if (localStorage.getItem('free_g') != null && localStorage.getItem('free_g') != 0)
        a.inp["g"] = parseFloat(localStorage.getItem('free_g'))
    if (localStorage.getItem('free_r') != null && localStorage.getItem('free_r') != 0)
        a.inp["r"] = parseFloat(localStorage.getItem('free_r'))
    if (localStorage.getItem('free_hr') != null && localStorage.getItem('free_hr') != 20)
        a.inp["hr"] = parseFloat(localStorage.getItem('free_hr'))
    if (localStorage.getItem('free_mr') != null && localStorage.getItem('free_mr') != 10)
        a.inp["mr"] = parseFloat(localStorage.getItem('free_mr'))
    if (localStorage.getItem('free_lr') != null && localStorage.getItem('free_lr') != 6)
        a.inp["lr"] = parseFloat(localStorage.getItem('free_lr'))
    if (localStorage.getItem('free_hr2') != null && localStorage.getItem('free_hr2') != "")
        a.inp["hr2"] = parseFloat(localStorage.getItem('free_hr2'))
    if (localStorage.getItem('free_mr2') != null && localStorage.getItem('free_mr2') != "")
        a.inp["mr2"] = parseFloat(localStorage.getItem('free_mr2'))
    if (localStorage.getItem('free_lr2') != null && localStorage.getItem('free_lr2') != "")
        a.inp["lr2"] = parseFloat(localStorage.getItem('free_lr2'))
    if (localStorage.getItem('sd_param') != null && localStorage.getItem('sd_param') != 0)
        a.inp["sd"] = parseInt(localStorage.getItem('sd_param'))
    if (localStorage.getItem('ld_param') != null && localStorage.getItem('ld_param') != 0)
        a.inp["ld"] = parseInt(localStorage.getItem('ld_param'))
    if (localStorage.getItem('bb_y') != null && localStorage.getItem('bb_y') != 20)
        a.inp["bby"] = parseInt(localStorage.getItem('bb_y'))
    if (localStorage.getItem('bb_k') != null && localStorage.getItem('bb_k') != 2.0)
        a.inp["bbk"] = parseFloat(localStorage.getItem('bb_k'))
    if (localStorage.getItem('cal_sdd') != null && localStorage.getItem('cal_sdd') != "")
        a.inp["ldd"] = localStorage.getItem('cal_sdd')
    if (localStorage.getItem('cal_ldd') != null && localStorage.getItem('cal_ldd') != "")
        a.inp["sdd"] = localStorage.getItem('cal_ldd')
    if (localStorage.getItem('cal_wsdd') != null && localStorage.getItem('cal_wsdd') != "")
        a.inp["wldd"] = localStorage.getItem('cal_wsdd')
    if (localStorage.getItem('cal_wldd') != null && localStorage.getItem('cal_wldd') != "")
        a.inp["wsdd"] = localStorage.getItem('cal_wldd')
    if (localStorage.getItem('nows') != null)
        a.inp["nows"] = localStorage.getItem('nows')
    if (localStorage.getItem('epnow') != null)
        a.inp["epnow"] = localStorage.getItem('epnow')
    if (localStorage.getItem('ccy') != null && localStorage.getItem('ccy') != 3)
        a.inp["ccy"] = parseInt(localStorage.getItem('ccy'))
    if (localStorage.getItem('ccy1') != null && localStorage.getItem('ccy1') != 3)
        a.inp["ccy1"] = parseInt(localStorage.getItem('ccy1'))
    if (localStorage.getItem('lms') != null && localStorage.getItem('lms') != 12)
        a.inp["lms"] = parseInt(localStorage.getItem('lms'))
    if (localStorage.getItem('sms') != null && localStorage.getItem('sms') != 8)
        a.inp["sms"] = parseInt(localStorage.getItem('sms'))

    if (localStorage.getItem('kdp') != null && localStorage.getItem('kdp') != 9)
        a.inp["kdp"] = parseInt(localStorage.getItem('kdp'))
    if (localStorage.getItem('kdr') != null && localStorage.getItem('kdr') != 3.0)
        a.inp["kdr"] = parseFloat(localStorage.getItem('kdr'))
    if (localStorage.getItem('kdk') != null && localStorage.getItem('kdk') != 3.0)
        a.inp["kdk"] = parseFloat(localStorage.getItem('kdk'))

    if (localStorage.getItem('e12') != null && localStorage.getItem('e12') != 12)
        a.inp["e12"] = parseInt(localStorage.getItem('e12'))
    if (localStorage.getItem('e26') != null && localStorage.getItem('e26') != 26)
        a.inp["e26"] = parseInt(localStorage.getItem('e26'))
    if (localStorage.getItem('e9') != null && localStorage.getItem('e9') != 9)
        a.inp["e9"] = parseInt(localStorage.getItem('e9'))
    if (localStorage.getItem('hys') != null && localStorage.getItem('hys') != 1277)
        a.inp["hys"] = parseInt(localStorage.getItem('hys'))

    if (localStorage.getItem('bsco') != null && localStorage.getItem('bsco') != 50)
        a.inp["bsco"] = parseInt(localStorage.getItem('bsco'))
    if (localStorage.getItem('bsmo') != null && localStorage.getItem('bsmo') != 50)
        a.inp["bsmo"] = parseInt(localStorage.getItem('bsmo'))

    if (localStorage.getItem('dmi') != null && localStorage.getItem('dmi') != 14)
        a.inp["dmi"] = parseInt(localStorage.getItem('dmi'))
    if (localStorage.getItem('wis') != null && localStorage.getItem('wis') != 7)
        a.inp["wis"] = parseInt(localStorage.getItem('wis'))
    if (localStorage.getItem('wil') != null && localStorage.getItem('wil') != 14)
        a.inp["wil"] = parseInt(localStorage.getItem('wil'))
    if (localStorage.getItem('ema') != null && localStorage.getItem('ema') != 0)
        a.inp["ema"] = parseInt(localStorage.getItem('ema'))
    if (localStorage.getItem('kdj') != null && localStorage.getItem('kdj') != 0)
        a.inp["kdj"] = parseInt(localStorage.getItem('kdj'))

    if (localStorage.getItem('av1') != null && localStorage.getItem('av1') != 5)
        a.inp["av1"] = parseInt(localStorage.getItem('av1'))
    if (localStorage.getItem('av2') != null && localStorage.getItem('av2') != 20)
        a.inp["av2"] = parseInt(localStorage.getItem('av2'))
    if (localStorage.getItem('av3') != null && localStorage.getItem('av3') != 60)
        a.inp["av3"] = parseInt(localStorage.getItem('av3'))
    if (localStorage.getItem('av4') != null && localStorage.getItem('av4') != 120)
        a.inp["av4"] = parseInt(localStorage.getItem('av4'))
    if (localStorage.getItem('av5') != null && localStorage.getItem('av5') != 200)
        a.inp["av5"] = parseInt(localStorage.getItem('av5'))
    if (localStorage.getItem('bol') != null && localStorage.getItem('bol') != 3)
        a.inp["bol"] = parseInt(localStorage.getItem('bol'))
    if (localStorage.getItem('bolw') != null && localStorage.getItem('bolw') != 3)
        a.inp["bolw"] = parseInt(localStorage.getItem('bolw'))
    if (localStorage.getItem('bolm') != null && localStorage.getItem('bolm') != 3)
        a.inp["bolm"] = parseInt(localStorage.getItem('bolm'))
    if (localStorage.getItem('wl1') != null && localStorage.getItem('wl1') != 10)
        a.inp["wl1"] = parseInt(localStorage.getItem('wl1'))
    if (localStorage.getItem('wl2') != null && localStorage.getItem('wl2') != 22)
        a.inp["wl2"] = parseInt(localStorage.getItem('wl2'))
    if (localStorage.getItem('psys') != null && localStorage.getItem('psys') != 12)
        a.inp["psys"] = parseInt(localStorage.getItem('psys'))
    if (localStorage.getItem('psyl') != null && localStorage.getItem('psyl') != 24)
        a.inp["psyl"] = parseInt(localStorage.getItem('psyl'))
    if (localStorage.getItem('can1') != null && localStorage.getItem('can1') != 20)
        a.inp["can1"] = parseInt(localStorage.getItem('can1'))
    if (localStorage.getItem('can1') != null && localStorage.getItem('can2') != 10)
        a.inp["can2"] = parseInt(localStorage.getItem('can2'))
    if (localStorage.getItem('can3') != null && localStorage.getItem('can3') != 2.0)
        a.inp["can3"] = parseFloat(localStorage.getItem('can3'))
    if (localStorage.getItem('hlp') != null && localStorage.getItem('hlp') != 10)
        a.inp["hlp"] = parseInt(localStorage.getItem('hlp'))
    if (localStorage.getItem('cci') != null && localStorage.getItem('cci') != 14)
        a.inp["cci"] = parseInt(localStorage.getItem('cci'))

    return a
}