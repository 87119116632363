import React, { useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Paper from '@mui/material/Paper';
import SettingsInputComponent from '@mui/icons-material/SettingsInputComponent'
import Drafts from '@mui/icons-material/Drafts'
import CalendarViewMonth from '@mui/icons-material/CalendarViewMonth'
import SportsKabaddi from '@mui/icons-material/SportsKabaddi'
import Abc from '@mui/icons-material/Abc'
import CameraSharp from '@mui/icons-material/CameraSharp'
import CHeader from './CHeader'
import CLanding from './CLanding'

import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { getId } from '../util/ID'
import useMediaQuery from '@mui/material/useMediaQuery';
import RecContainer from '../rec/RecContainer';
import LoveContainer from '../love/LoveContainer';
import PaymentContainerg from '../payment/PaymentContainerg'

const useStyles = makeStyles(theme => ({
    // root: {
    //     // color: "green",
    //     "&$selected": {
    //         color: theme.palette.mode === "light" ? "#575555" : null
    //     },
    // },
    // selected: {
    //     "&$selected": {
    //         color: theme.palette.mode === "light" ? "#575555" : null
    //     },
    // }
}));


export default function CHome(props) {
    const [value, setValue] = React.useState(2);
    const [sig, setSig] = React.useState(null)

    const theme = useTheme();
    const classes = useStyles();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));



    const getContext = () => {
        if (value === 2)
            return <RecContainer />
        else if (value === 11)
            return <LoveContainer />
        else if (value === 3)
            return <CLanding setMenu={setValue} />
        else if (value === 7)
            return <PaymentContainerg />
        else return <RecContainer />

        // if (value === 11) return <LoveContainer />
        // else if (value === 2) return <ProfileContainer goToOSCPage={goToOSCPage} />
        // else if (value === 1) return <OSCContainer1 />
        // else if (value === 7) return <AccountContainer />
        // else if (value === 3) return <CLanding setMenu={setValue}/>
        // else if (value === 0) return <OSCContainer sig={sig}/>
        // else if (value === 4) return <BrokerContainer />
        // else return <CLanding />
    }

    const goToOSCPage = (sig) => {
        setSig(sig)
        setValue(0)
    }

    useEffect(() => {
        getId()
    }, []);

    const child1 = React.useMemo(() => <div style={{ overflowY: 'hidden', width: '100%' }}>
        {getContext()}
        {/* {console.log("getContext" + value)} */}
    </div>, [value]);

    return (
        <Box sx={{ bgcolor: 'background.default', color: 'text.primary', }} style={{ position: 'absolute', height: "100vh", overflowY: "hidden", width: '100%' }}>
            {/* <div style={{ position: 'absolute', height: "100vh", overflowY: "hidden", width: '100%' }}> */}
            <div style={{ top: 0 }} >
                <CHeader setMenu={setValue} menu={value} />
            </div>

            {/* <div style={{ overflowY: 'hidden', width: '100%' }}>
                {getContext()}
            </div> */}
            {child1}
            
            {fullScreen && <Paper sx={{ position: 'absolute', bottom: 0, left: 0, right: 0 }} elevation={3}>
                <BottomNavigation
                    showLabels
                    value={value}
                    onChange={(event, newValue) => {
                        setValue(newValue);
                    }}
                >
                    <BottomNavigationAction value={3} sx={{ minWidth: 0 }} classes={classes} label="首頁" icon={<Abc sx={{ color: theme.palette.mode === "light" ? "#4f4f4f" : null }} />} />
                    <BottomNavigationAction value={2} sx={{ minWidth: 0 }} classes={classes} label="策略" icon={<Drafts sx={{ color: theme.palette.mode === "light" ? "#4f4f4f" : null }} />} />
                    <BottomNavigationAction value={11} sx={{ minWidth: 0 }} classes={classes} label="自選" icon={<CalendarViewMonth sx={{ color: theme.palette.mode === "light" ? "#4f4f4f" : null }} />} />
                    <BottomNavigationAction value={7} sx={{ minWidth: 0 }} classes={classes} label="序號" icon={<SportsKabaddi sx={{ color: theme.palette.mode === "light" ? "#4f4f4f" : null }} />} />
                </BottomNavigation>
            </Paper>}
        </Box>
    );
}

