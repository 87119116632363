import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LineChart, BarChart, CandlestickChart } from 'echarts/charts';
import {
    GridComponent,
    TooltipComponent,
    TitleComponent,
    TimelineComponent,
    MarkPointComponent,
    MarkLineComponent,
    MarkAreaComponent,
    LegendComponent,
    DataZoomComponent,
    DataZoomInsideComponent,
    DataZoomSliderComponent,
    VisualMapComponent,
    VisualMapContinuousComponent,
    VisualMapPiecewiseComponent,
} from 'echarts/components';
import {
    CanvasRenderer,
} from 'echarts/renderers';
import { getMobileTooltip, getComputerTooltip, getAxisPointer, getMobileDataZoom, getComputerDataZoom } from '../util/GridA'
import useMediaQuery from '@mui/material/useMediaQuery';

echarts.use(
    [VisualMapComponent, VisualMapContinuousComponent, VisualMapPiecewiseComponent, TitleComponent, TooltipComponent, GridComponent, LineChart, CandlestickChart, CanvasRenderer, DataZoomComponent,
        DataZoomInsideComponent, DataZoomSliderComponent, MarkPointComponent, MarkLineComponent, TimelineComponent,
        LegendComponent, MarkAreaComponent, BarChart]
);


const useStyles = makeStyles(theme => ({
    table: {
        // minWidth: 650,
    },
    root1: {
        flexGrow: 1,
    },
    menuButton: {
        // marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    grid: {
        // width: `calc(100% + ${6 + (theme.spacing(1))}px)`
    }
}));



export default function NetValue(props) {
    const classes = useStyles();
    const theme = useTheme();

    const [option, setOption] = React.useState(null);
    const [result, setResult] = React.useState(null);
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    
    function getYY3() {
        let data = []
        for (var i = 0; i < result.length; i++) {
            let k = 0
            if (result[i].v3 > 0) k = 1
            else if (result[i].v3 < 0) k = -1
            data.push([i, result[i].v3, k]);
        }
        return data
    }

    const getStart = () => {
        if(result != null) {
            let k = result.length / 4
            if(k >= 100) return 75
            else return k
        } else return 0
     }


    function genoption1() {
        let k = {
            animation: false,
            grid: [{
                top: 25,
                left: 0,
                right: 11,
                height: 300,
                // bottom: 5,
                containLabel: true
            },{
                top: 350,
                left: 0,
                right: 11,
                height: 100,
                // bottom: 5,
                containLabel: true
            }],
            visualMap: {
                show: false,
                dimension: 2,
                seriesIndex: 2,
                pieces: [{
                    value: 1,
                    color: "#d41c1c"
                }, {
                    value: 0,
                    color: "#8f8f8f"
                }, {
                    value: -1,
                    color: '#65b206'
                }]
            },
            legend: {
                data: ['轉換價值', 'CB收盤價', '折溢%'],
                right: 0,
                icon: 'roundRect',
                formatter: function (name) {
                    return name
                    // let value = result[result.length - 1].v1
                    // if(name === '債券') {
                    //     value = result[result.length - 1].v2
                    // } else if(name === '折溢') {
                    //     value = result[result.length - 1].v3
                    // }
                    // return name + " : " + value
                }
                // ...
            },

            yAxis: [{
                scale: true,
                type: 'value',
                position: 'left',
                show: true,
                splitLine: {
                    show: true,
                    lineStyle: {
                        opacity: 0.2
                    }
                },
                gridIndex: 0,
            },{
                scale: true,
                type: 'value',
                position: 'left',
                show: true,
                splitLine: {
                    show: true,
                    lineStyle: {
                        opacity: 0.2
                    }
                },
                gridIndex: 1,
            }],
            series: [
                {
                    name: '轉換價值',
                    type: 'line',
                    data: result.map(v => v.v1),
                    smooth: true,
                    showSymbol: false,
                    markLine: {
                        symbol: "none",
                        label: {
                            show: false
                        },
                        data: [
                            {
                                name: '100',
                                yAxis: 100,
                                lineStyle: {
                                    width: 2,
                                }
                            }
                        ]
                    },
                    xAxisIndex: 0,
                    yAxisIndex: 0,
                },
                {
                    name: 'CB收盤價',
                    type: 'line',
                    data: result.map(v => v.v2),
                    smooth: true,
                    showSymbol: false,
                    xAxisIndex: 0,
                    yAxisIndex: 0,
                },
                {
                    name: '折溢%',
                    type: 'bar',
                    data: getYY3(),
                    // type: 'line',
                    // data: result.map(v => v.v3),
                    showSymbol: false,
                    xAxisIndex: 1,
                    yAxisIndex: 1,
                }
            ]
        };
        let p = {
            type: 'line',
            label: { show: false },
            triggerTooltip: true,
            handle: {
                show: true,
                size: 22
            }
        }

        let x1 = {
            type: 'category',
            boundaryGap: true,
            data: result.map(v => v.x),
            gridIndex: 0,
        }

        let x2 = {
            type: 'category',
            boundaryGap: true,
            data: result.map(v => v.x),
            gridIndex: 1,
        }

        if (fullScreen) {
            k['tooltip'] = getMobileTooltip()
            k['axisPointer'] = getAxisPointer()
            k['dataZoom'] = getMobileDataZoom(getStart(), 100)

            x1['axisPointer'] = p
            x2['axisPointer'] = p
        } else {
            k['tooltip'] = getComputerTooltip()
            k['axisPointer'] = getAxisPointer()
            k['dataZoom'] = getComputerDataZoom(getStart(), 100, 470)
        }

        k['xAxis'] = [x1, x2]
        return k;
    }

    const fetchData = () => {
        let param = {
            bid: props.row.cb2.bid
        }

        fetch("/ocb/v1/stock3/fpic1", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result != null) {
                        setResult(result)
                    }
                }
            );
    }

    useEffect(() => {
        if (result != null && result.length > 0) setOption(genoption1)
    }, [result]);

    useEffect(() => {
        // setOption(genoption1)
        if (props.row != null) fetchData()
    }, [props.row]);



    return (
        <div style={{ padding: 1 }} onClick={e => props.openDetail(1, props.menu)}>
            {option != null ?

                <ReactEChartsCore
                    theme={theme.palette.mode}
                    echarts={echarts}
                    option={option}
                    notMerge={true}
                    lazyUpdate={false}
                    style={{ height: 500 }}
                // onEvents={onEvents}
                />
                : null}
        </div>
    );
}

