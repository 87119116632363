import React from 'react';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Build from '@mui/icons-material/Build'
import RuleTp4 from '../ruletp/RuleTp4';
import { EASY_1, EASY_1_IDS, SHAPE_1, SHAPE_1_IDS, SHAPE_2, SHAPE_2_IDS, BROKER_1, BROKER_1_IDS } from '../ruletp/RuleData'

import { enableColor, disableColor } from '../util/Expansion'
import ExpansionPanel from '@mui/material/Accordion';
import ExpansionPanelDetails from '@mui/material/AccordionDetails';
import ExpansionPanelSummary from '@mui/material/AccordionSummary';
import Chip from '@mui/material/Chip';
import Percent0100 from '../menu/Percent0100';
import Number100 from '../menu/Number100';
import Day_5 from '../menu/Day_5';
import Day_10_240 from '../menu/Day_10_240';


const useStyles = makeStyles(theme => ({
}));


export default function AllCBStrageRules(props) {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState('EASYOn1');


    const handleChange = panel => (event, expanded) => {
        setExpanded(expanded ? panel : false)
    };


    const NumberN50 = [
        {id: "50", value: "50"},        
        {id: "45", value: "45"},
        {id: "40", value: "40"},
        {id: "35", value: "35"},
        {id: "30", value: "30"},
        {id: "25", value: "25"},
        {id: "20", value: "20"},
        {id: "15", value: "15"},
        {id: "10", value: "10"},
        {id: "9", value: "9"},
        {id: "8", value: "8"},
        {id: "7", value: "7"},
        {id: "6", value: "6"},
        {id: "5", value: "5"},
        {id: "4", value: "4"},
        {id: "3", value: "3"},
        {id: "2", value: "2"},
        {id: "1", value: "1"},
        {id: "0", value: "0"},
        {id: "-1", value: "-1"},
        {id: "-2", value: "-2"},
        {id: "-3", value: "-3"},
        {id: "-4", value: "-4"},                
        {id: "-5", value: "-5"},
        {id: "-6", value: "-6"},
        {id: "-7", value: "-7"},
        {id: "-8", value: "-8"},
        {id: "-9", value: "-9"},                    
        {id: "-10", value: "-10"},    
        {id: "-15", value: "-15"},       
        {id: "-20", value: "-20"},
        {id: "-25", value: "-25"},
        {id: "-30", value: "-30"},
        {id: "-35", value: "-35"},
        {id: "-40", value: "-40"},
        {id: "-45", value: "-45"},
        {id: "-50", value: "-50"},                                        
    ]

    const HL = [
        {id: "0", value: "新高"},        
        {id: "1", value: "新低"},
    ]

    const CB_PRICE = [         
        {id: "5", value: "5"},    
        {id: "10", value: "10"},    
        {id: "15", value: "15"},       
        {id: "20", value: "20"},
        {id: "30", value: "30"},
        {id: "40", value: "40"},
        {id: "50", value: "50"},
        {id: "60", value: "60"},
        {id: "70", value: "70"},
        {id: "75", value: "75"},
        {id: "80", value: "80"},  
        {id: "85", value: "85"},    
        {id: "90", value: "90"},
        {id: "95", value: "95"},    
        {id: "100", value: "100"},
        {id: "120", value: "120"},
        {id: "130", value: "130"}, 
        {id: "140", value: "140"}, 
        {id: "150", value: "150"}, 
        {id: "160", value: "160"}, 
        {id: "170", value: "170"}, 
        {id: "180", value: "180"}, 
        {id: "190", value: "190"},                                                                       
        {id: "200", value: "200"},
        {id: "300", value: "300"},
        {id: "400", value: "400"},          
        {id: "500", value: "500"},          
        {id: "600", value: "600"},          
        {id: "700", value: "700"},          
        {id: "800", value: "800"},          
        
      ];   

    const CB_RULE1 = [
        {
            desc: ['溢價率大於', '%，小於', '%'],
            menu: [NumberN50, NumberN50], id: '9106',
        }, {
            desc: ['CB價格大於', '元，小於', '元'],
            menu: [CB_PRICE, CB_PRICE], id: '9107'
        }, {
            desc: ['已轉換比例大於', '，小於', ''],
            menu: [Percent0100, Percent0100], id: '9108'
        }, {
            desc: ['到期日大於', '天，小於', '天'],
            menu: [Number100, Number100], id: '9109'
        }, {
            desc: ['CB價格創近新高', '', ''],
            menu: [Day_10_240, HL], id: '9110'
        }, {
            desc: ['CB成交量創近新高', '', ''],
            menu: [Day_10_240, HL], id: '9111'
        }
    ]

    const CB_RULE1_IDS = CB_RULE1.map(i => { return i.id })
    const isEASYOn1 = props.rows.some(rule => CB_RULE1_IDS.includes(rule.id));

    return (
        <div>
            <ExpansionPanel
                TransitionProps={{ unmountOnExit: true }}
                expanded={expanded === 'EASYOn1'}
                onChange={handleChange('EASYOn1')}
            >
                <ExpansionPanelSummary style={{
                    backgroundColor: isEASYOn1 ? enableColor : disableColor,
                    borderBottomStyle: 'solid',
                    borderWidth: 1,
                    borderColor: "#e0e0e0",
                }}>
                    <Build  style={{ fontSize: 30, marginRight: 10 }} />
                    <Typography  variant="subtitle1" >可轉債1</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>
                    {CB_RULE1.map(row => {
                        return <RuleTp4 key={row.id} row={row} ruleChange={props.updateRule} rules={props.rows} />
                    })}
                </ExpansionPanelDetails>
            </ExpansionPanel>

            
        </div>
    );
}