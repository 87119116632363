import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LineChart, CandlestickChart, ScatterChart } from 'echarts/charts';
import {
    GridComponent,
    TooltipComponent,
    TitleComponent,
    TimelineComponent,
    MarkPointComponent,
    MarkLineComponent,
    MarkAreaComponent,
    LegendComponent,
    DataZoomComponent,
    DataZoomInsideComponent,
    DataZoomSliderComponent,
} from 'echarts/components';
import {
    CanvasRenderer,
} from 'echarts/renderers';

import Button from '@mui/material/Button';
import { getMobileTooltip, getComputerTooltip, getAxisPointer, getMobileDataZoom, getComputerDataZoom } from '../util/GridA'
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import { grey, red, green } from '@mui/material/colors'

echarts.use(
    [ScatterChart, TitleComponent, TooltipComponent, GridComponent, LineChart, CandlestickChart, CanvasRenderer, DataZoomComponent,
        DataZoomInsideComponent, DataZoomSliderComponent, MarkPointComponent, MarkLineComponent, TimelineComponent,
        LegendComponent, MarkAreaComponent]
);



export default function HistoryCB(props) {
    const theme = useTheme();

    const [option, setOption] = React.useState(null);
    const [result, setResult] = React.useState(null);
    const [cPrice, setCPrice] = React.useState(null);

    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const getStart = () => {
        if (result != null) {
            //    console.log("result.length:" + result.length)
            let k = result.length / 4
            if (k >= 100) return 75
            else return k
        } else return 0
    }

    function compareFn(a, b) {
        if (a[0] < b[0]) {
            return -1;
        } else if (a[0] > b[0]) {
            return 1;
        }
        return 0;
    }

    function genoption1() {

        let k = {
            animation: false,
            grid: [{
                top: 30,
                left: 0,
                right: 11,
                height: 250,
                containLabel: true
            }],
            legend: {
                show: true
            },
            yAxis: [
                {
                    scale: true,
                    type: 'value',
                    position: 'left',
                    show: true,
                    splitLine: {
                        show: true,
                        lineStyle: {
                            opacity: 0.2
                        }
                    },
                    axisLabel: {
                        inside: true,
                        margin: 0
                    },
                }],
        };

        let series = result.map(row => {
            let h = row.l.map(v => { return [v.high_ymd, v.high] })
            let l = row.l.map(v => { return [v.low_ymd, v.low] })
            let a = h.concat(l).sort(compareFn)
            return {
                name: row.name,
                type: 'line',
                data: a,
                selectedMode: 'single',
            }

        })

        k["series"] = series

        let vp = {
            type: 'line',
            label: { show: false },
            triggerTooltip: true,
            handle: {
                show: true,
                size: 22
            }
        }

        let xData = result.map(row => {
            let h = row.l.map(v => { return v.high_ymd })
            let l = row.l.map(v => { return v.low_ymd })
            return h.concat(l)
        }
        ).flat().sort()

        let x1 = {
            type: 'category',
            boundaryGap: true,
            data: xData
        }

        if (fullScreen) {
            k['tooltip'] = getMobileTooltip()
            k['axisPointer'] = getAxisPointer()
            k['dataZoom'] = getMobileDataZoom(getStart(), 100)

            x1['axisPointer'] = vp
        } else {
            k['tooltip'] = getComputerTooltip()
            k['axisPointer'] = getAxisPointer()
            k['dataZoom'] = getComputerDataZoom(getStart(), 100, 470)
        }

        k['xAxis'] = [x1]
        return k;
    }

    const fetchData = () => {
        let param = {
            id: props.menu,
            sid: props.row.cb2.cid,
            bid: props.row.cb2.bid
        }

        if (localStorage.getItem("lic") != null) {
            param['lic'] = localStorage.getItem("lic")
        }

        fetch("/ocb/v1/stock2/cb/detail3", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result != null && result.status === 0) {
                        setResult(result.data)
                    }
                }
            );
    }

    useEffect(() => {
        if (result != null) setOption(genoption1)
    }, [result]);

    useEffect(() => {
        // console.log(props.row)
        if (props.row != null) {
            fetchData()
        }
    }, [props.row]);


    return (
        <Box>
            {option != null ?
                <Box sx={{ m: 2, mb: 5, }}>
                    <ReactEChartsCore
                        theme={theme.palette.mode}
                        echarts={echarts}
                        option={option}
                        notMerge={true}
                        lazyUpdate={false}
                        style={{ height: 330 }}
                    />
                </Box>
                : null}

            {result != null && result.map((row) => (
                <Box sx={{ m: 2, mb: 5, }}>
                    <Typography sx={{}} variant="h6" fontWeight={"bold"}>{row.name}({row.bid})</Typography>
                    <TableContainer component={Paper} sx={{ width: "100%" }} >
                        <Table size="small">
                            <TableHead sx={{ bgcolor: grey[700] }}>
                                <TableRow>
                                    <TableCell sx={{ whiteSpace: "nowrap", color: "#FFFFFF" }}>年度</TableCell>
                                    <TableCell align="right" sx={{ whiteSpace: "nowrap", color: "#FFFFFF" }}>成交單位(仟股)</TableCell>
                                    <TableCell align="right" sx={{ whiteSpace: "nowrap", color: "#FFFFFF" }}>成交金額(仟元)</TableCell>
                                    <TableCell align="right" sx={{ whiteSpace: "nowrap", color: "#FFFFFF" }} >成交筆數</TableCell>
                                    <TableCell align="right" sx={{ whiteSpace: "nowrap", color: "#FFFFFF" }} >最高日</TableCell>
                                    <TableCell align="right" sx={{ whiteSpace: "nowrap", color: "#FFFFFF" }} >最高價</TableCell>
                                    <TableCell align="right" sx={{ whiteSpace: "nowrap", color: "#FFFFFF" }} >最低日</TableCell>
                                    <TableCell align="right" sx={{ whiteSpace: "nowrap", color: "#FFFFFF" }} >最低價</TableCell>
                                    <TableCell align="right" sx={{ whiteSpace: "nowrap", color: "#FFFFFF" }} >均價</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {row.l.map((row1) => (
                                    <TableRow
                                        key={row1.year}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell sx={{ whiteSpace: "nowrap" }} >{row1.year}</TableCell>
                                        <TableCell sx={{ whiteSpace: "nowrap" }} align="right">{row1.unit}</TableCell>
                                        <TableCell sx={{ whiteSpace: "nowrap" }} align="right">{row1.money}</TableCell>
                                        <TableCell sx={{ whiteSpace: "nowrap" }} align="right">{row1.count}筆</TableCell>
                                        <TableCell sx={{ whiteSpace: "nowrap" }} align="right">{row1.high_ymd}</TableCell>
                                        <TableCell sx={{ whiteSpace: "nowrap" }} align="right">{row1.high}元</TableCell>
                                        <TableCell sx={{ whiteSpace: "nowrap" }} align="right">{row1.low_ymd}</TableCell>
                                        <TableCell sx={{ whiteSpace: "nowrap" }} align="right">{row1.low}元</TableCell>
                                        <TableCell sx={{ whiteSpace: "nowrap" }} align="right">{row1.avg}元</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {/* <Divider sx={{mb:5, mt:5}}/> */}

                </Box>
            )
            )}
        </Box>
    );
}

