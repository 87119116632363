import React, { useEffect, useRef } from 'react';
import RecItem from '../rec/RecItem'
import RecItem2 from '../rec/RecItem2'
import RecItem3 from '../rec/RecItem3'
import { Height } from '../util/ScreenHeight'
import RecSelector from '../rec/RecSelector'
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import { getId } from '../util/ID'

import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import AddLoveDialog from './AddLoveDialog';
import Button from '@mui/material/Button';
import AodIcon from '@mui/icons-material/Aod';
import Divider from '@mui/material/Divider';
import RecItem4 from '../rec/RecItem4';
import CBLoveTables from './CBLoveTables';

export default function LoveContainer(props) {
    const [page, setPage] = React.useState(1);
    const [total, setTotal] = React.useState(100)

    const [showEmpty, setShowEmpty] = React.useState(false);
    const [emptyMsg, setEmptyMsg] = React.useState('');
    const [result, setResult] = React.useState(null);
    const [menu, setMenu] = React.useState(null)
    const [loading, setLoading] = React.useState(false)
    const [openSetting, setOpenSetting] = React.useState(false);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const fetchData = () => {
        setLoading(true)

        // sn : String, sid : String, tp : Int, froms : Option[String] = None
        let param = {
            sn: getId()
        }

        if (localStorage.getItem("lic") != null) {
            param['lic'] = localStorage.getItem("lic")
        }


        fetch("/ocb/v1/stock2/cb/love", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    setLoading(false)

                    if (result != null && result.status === 0) {
                        if (result.data != null && result.data.length >= 0) {
                            setTotal(result.total)
                            setResult(result.data)
                        } else {
                            setTotal(0)
                            setResult(null)
                            setShowEmpty(true)
                            setEmptyMsg("目前尚未找到符合資料。")
                        }
                    }
                }
            );
    }

    useEffect(() => {
        fetchData()
    }, []);

    const updateLove = (sid, tp) => {
        if (sid != null && result != null) {
            let nresult = result.filter(r => {
                return !(r.cb2.bid === sid && r.cbl.tp === tp)
            })
            setResult(nresult)
        }
    }

    const getItem = (tp, row) => {
        if (row.cbl.froms === "0" || row.cbl.froms === "1")
            return <RecItem updateLove={updateLove} tp={tp} key={row.cb2.bid} row={row} menu={row.cbl.froms} />
        else if (row.cbl.froms === "2")
            return <RecItem2 updateLove={updateLove} tp={tp} key={row.cb2.bid} row={row} menu={row.cbl.froms} />
        else if (row.cbl.froms === "3")
            return <RecItem3 updateLove={updateLove} tp={tp} key={row.cb2.bid} row={row} menu={row.cbl.froms} />
        else if (row.cbl.froms === "8")
            return <RecItem4 updateLove={updateLove} tp={tp} key={row.cb2.bid} row={row} menu={row.cbl.froms} />
        else return <RecItem updateLove={updateLove} tp={tp} key={row.cb2.bid} row={row} menu={row.cbl.froms} />
    }

    return (
        <Box style={{ flexDirection: 'column', height: '100%', overflowY: 'hidden' }} sx={{}}>
            {openSetting ? <AddLoveDialog refresh={fetchData} open={openSetting} close={setOpenSetting}></AddLoveDialog> : null}
            <Button startIcon={<AodIcon></AodIcon>} fullWidth color="inherit" variant="contained" disableElevation onClick={e => setOpenSetting(true)}>新增自選</Button>
            <Divider />

            {loading && <Box sx={{ position: 'absolute', top: Height.header + Height.subheader - 2, width: '100%' }}><LinearProgress color="info" /></Box>}

            {!loading && (localStorage.getItem("screen") == null || localStorage.getItem("screen") === "0") && <Grid container spacing={0} sx={{ p: 0.5, width: '100%', bgcolor: 'background.paper', position: 'absolute', top: Height.header + Height.subheader, bottom: props.ismobile || fullScreen ? 100 : 55, overflowY: 'scroll' }}>
                {result != null && result.map(row => {
                    return <Grid key={row.cb2.bid + row.cbl.froms} item xs={12} sm={6} md={4} lg={3}>
                        {getItem(row.cbl.tp, row)}
                        {/* <RecItem updateLove={updateLove} tp={tp} key={row.cb2.bid} row={row} menu={menu} /> */}
                    </Grid>
                })}
                {result != null && result.length === 0 &&
                    <Typography variant="body1" sx={{ width: '100%', textAlign: 'center', alignSelf: 'center', m: 1 }}>很抱歉，無法找到符合條件的股票。</Typography>
                }
            </Grid>}


            {!loading && (localStorage.getItem("screen") != null && localStorage.getItem("screen") === "1") && result != null &&
                <Box sx={{ width: '100%', bgcolor: 'background.paper', position: 'absolute', top: Height.header + Height.subheader, bottom: props.ismobile || fullScreen ? 100 : 55, overflowY: "hidden" }}>
                    <CBLoveTables result={result} updateLove={updateLove}/>
                </Box>
            }


            {<Box style={{ flexShrink: 0 }} sx={{ position: 'absolute', bottom: props.ismobile || fullScreen ? 60 : 0, width: '100%', mt: 0.5, mb: 0.5, display: 'flex', justifyContent: "center" }}>
                <Pagination size="small" siblingCount={1} boundaryCount={1} count={total} variant="outlined" shape="rounded" onChange={handlePageChange} />
            </Box>}
        </Box>
    );
}
