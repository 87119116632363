import React, { useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useTheme } from '@mui/material/styles';
import { Height } from '../util/ScreenHeight'
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import MoreVert from '@mui/icons-material/MoreVert';
import Button from '@mui/material/Button';
import RecSetting from './RecSetting';




const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -10,
        top: 5,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
    },
}));

const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiOutlinedInput-root': {
            borderRadius: 0
        },
    }

    //  '& .MuiDataGrid-colCell, .MuiDataGrid-cell': {
    //     borderRight: `1px solid ${theme.palette.type === 'light' ? '#f0f0f0' : '#303030'
    //         }`,
    // },
}));


export default function RecSelector(props) {
    const [age, setAge] = React.useState("");
    const [order, setOrder] = React.useState("");
    const [tags, setTags] = React.useState([])
    const [openSetting, setOpenSetting] = React.useState(false);

    const theme = useTheme();
    const classes = useStyles();

    const handleChange = (event) => {
        setAge(event.target.value);
        if (props.setMenu != null) {
            props.setMenu(event.target.value)
        }
    };


    const fectchTags = () => {
        let u = "/ocb/v1/stock3/cb/tag"

        fetch(u, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result != null) {
                        setTags(result)
                        if (result.length > 0) {
                            setAge(result[0].id)
                            if (props.setMenu != null) props.setMenu(result[0].id)
                        }
                    }
                }
            );
    }

    useEffect(() => {
        fectchTags()
    }, []);

    useEffect(() => {
        if(props.order === "desc" && props.orderBy === "1")
            setOrder(0)
        else if(props.order === "asc" && props.orderBy === "1")
            setOrder(1)        
        else if(props.order === "desc" && props.orderBy === "2")
            setOrder(2)
        else if(props.order === "asc" && props.orderBy === "2")
            setOrder(3)        
        else if(props.order === "desc" && props.orderBy === "3")
            setOrder(4)
        else if(props.order === "asc" && props.orderBy === "3")
            setOrder(5) 
        else if(props.order === "desc" && props.orderBy === "4")
            setOrder(6)
        else if(props.order === "asc" && props.orderBy === "4")
            setOrder(7) 
        else if(props.order === "desc" && props.orderBy === "5")
            setOrder(8)
        else if(props.order === "asc" && props.orderBy === "5")
            setOrder(9) 
        else if(props.order === "desc" && props.orderBy === "6")
            setOrder(10)
        else if(props.order === "asc" && props.orderBy === "6")
            setOrder(11) 
        else if(props.order === "desc" && props.orderBy === "7")
            setOrder(12)
        else if(props.order === "asc" && props.orderBy === "7")
            setOrder(13) 
        else if(props.order === "desc" && props.orderBy === "8")
            setOrder(14)
        else if(props.order === "asc" && props.orderBy === "8")
            setOrder(15)         
        else if(props.order === "desc" && props.orderBy === "9")
            setOrder(16)
        else if(props.order === "asc" && props.orderBy === "9")
            setOrder(17) 

    }, [props.order, props.orderBy]);

    const handleOrderChange = (event) => {
        if (props.updateOrder != null) {
            if (event.target.value === 0) {
                props.updateOrder("desc", "1")
            } else if (event.target.value === 1) {
                props.updateOrder("asc", "1")
            } else if (event.target.value === 2) {
                props.updateOrder("desc", "2" )
            } else if (event.target.value === 3) {
                props.updateOrder("asc", "2" )
            } else if (event.target.value === 4) {
                props.updateOrder("desc","3")
            } else if (event.target.value === 5) {
                props.updateOrder("asc", "3" )
            } else if (event.target.value === 6) {
                props.updateOrder("desc", "4" )
            } else if (event.target.value === 7) {
                props.updateOrder("asc", "4" )
            } else if (event.target.value === 8) {
                props.updateOrder("desc", "5" )
            } else if (event.target.value === 9) {
                props.updateOrder("asc", "5" )
            } else if (event.target.value === 10) {
                props.updateOrder("desc", "6" )
            } else if (event.target.value === 11) {
                props.updateOrder("asc", "6")
            } else if (event.target.value === 12) {
                props.updateOrder("desc", "7" )
            } else if (event.target.value === 13) {
                props.updateOrder("asc", "7" )
            } else if (event.target.value === 14) {
                props.updateOrder("desc", "8" )
            } else if (event.target.value === 15) {
                props.updateOrder("asc", "8" )
            } else if (event.target.value === 16) {
                props.updateOrder("desc", "9" )
            } else if (event.target.value === 17) {
                props.updateOrder("asc", "9" )
            }
        }
    };

    return (
        <Box sx={{ width: '100%', textAlign: 'left', display: 'inline-flex', p: 0, bgcolor: theme.palette.mode === "light" ? "secondary.light" : null }} >
            {openSetting ? <RecSetting refresh={props.refresh} open={openSetting} close={setOpenSetting}></RecSetting> : null}


            <TextField
                size="small"
                className={classes.root}
                // label="策略"
                sx={{ flexGrow: 2, mr: 0, height: Height.subheader, maxHeight: Height.subheader }}
                select
                value={age}
                onChange={e => handleChange(e)}
                hiddenLabel
                variant="filled"

            >
                {tags.map(tag => {
                    return <MenuItem key={tag.id} value={tag.id}>
                        {tag.name}

                    </MenuItem>
                })}

            </TextField>

            <TextField
                size="small"
                className={classes.root}
                sx={{ flexGrow: 1, mr: 0, height: Height.subheader, maxHeight: Height.subheader }}
                select
                value={order}
                onChange={e => handleOrderChange(e)}
                hiddenLabel
                variant="filled"
            >
                <MenuItem value={0}>折溢價［大到小］</MenuItem>
                <MenuItem value={1}>折溢價［小到大］</MenuItem>
                <MenuItem value={2}>CB收盤價［大到小］</MenuItem>
                <MenuItem value={3}>CB收盤價［小到大］</MenuItem>
                <MenuItem value={4}>轉換價值［大到小］</MenuItem>
                <MenuItem value={5}>轉換價值［小到大］</MenuItem>
                <MenuItem value={6}>轉換率［大到小］</MenuItem>
                <MenuItem value={7}>轉換率［小到大］</MenuItem>
                <MenuItem value={8}>股票價格［大到小］</MenuItem>
                <MenuItem value={9}>股票價格［小到大］</MenuItem>
                <MenuItem value={10}>轉換價［大到小］</MenuItem>
                <MenuItem value={11}>轉換價［小到大］</MenuItem>
                <MenuItem value={12}>剩餘年限［大到小］</MenuItem>
                <MenuItem value={13}>剩餘年限［小到大］</MenuItem>
                <MenuItem value={14}>百元報價［大到小］</MenuItem>
                <MenuItem value={15}>百元報價［小到大］</MenuItem>
                <MenuItem value={16}>買回日［大到小］</MenuItem>
                <MenuItem value={17}>買回日［小到大］</MenuItem>
            </TextField>

            {/* <FormControl hiddenLabel variant="filled" sx={{flexGrow: 1, whiteSpace:"nowrap", height: Height.subheader, maxHeight: Height.subheader}}>
                <Select
                    variant="filled"
                    id="demo-simple-select"
                    value={age}
                    onChange={handleChange}
                >
                    <MenuItem value={10}>折溢價［大到小］</MenuItem>
                    <MenuItem value={10}>折溢價［小到大］</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                </Select>
            </FormControl> */}

            <Button color='error' variant='contained' sx={{ maxHeight: Height.subheader, whiteSpace: 'nowrap', borderRadius: '0px' }} endIcon={<MoreVert />} size="small" disableElevation onClick={e => setOpenSetting(true)}>設定</Button>
        </Box>
    );
}
